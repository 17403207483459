import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Tabs } from 'antd';
import './reports.scss';
import CurrentTab from './currentTab/currentTab';
import NextToBeat from './nextToBeat/nextToBeat';
import { withRouter } from 'react-router';



class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '0',
    };
  }

  componentDidMount() {
    this.props.menuHandler('66');
    const { currentTab } = this.props;
    if(this.props.currentTab != undefined){
      this.changeTab(currentTab);
    }
  }

  changeTab = (activeKey) => {
    this.setState({
      ...this.state,
      activeTab: activeKey,
    });
  };

  render() {
    const { activeTab } = this.state;
    const { authToken } = this.props;
    return (
      <div className='reports-style-f'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <div className='align-center'>
            <Tabs activeKey={activeTab} onChange={this.changeTab}>
              <Tabs.TabPane tab='Ficha vigente' key={0}>
                <CurrentTab authToken={authToken} />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Ficha próxima a vencer' key={1}>
                <NextToBeat authToken={authToken} />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state,props) => {
  const tab = props.match.params.currentTab; //Buffer.from(props.match.params.activeTab,'base64').toString('utf8');
  return {
    authToken: state.auth.auth.access_token,
    currentTab:tab
  };
};

export default withRouter(connect(mapStateToProps, {})(Reports));


