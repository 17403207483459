import React,{useState,useEffect} from 'react';
import { Button, Col, Form, Space, Row, Switch, Upload, Table, Spin, notification, Typography, Divider } from 'antd';
import Text from 'antd/lib/typography/Text';
import { DeleteOutlined, UploadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { grabarCertificadoSagrilaft } from '../../../../../../services/admin/personServices';
import {useSelector} from "react-redux";
import { tipoIds } from '../../../../../../utilities/constants';
import { PersonaAdicionalForm } from '../../../../../../components/fichaCliente/juridica/finalBeneficiaries/personasAdicionalesForm';
import { useParams } from "react-router-dom";


const { Title } = Typography;
const mensajecargado = " ya has cargado este archivo anteriormente.";


export const FinalBeneficiaries = ({ beneficiarios, onChange, snIndicaBF, handleChangeSnIndicaBF,accionistas,person,handleFileUploaded,company,fileBFuploaded,cargaCertsag,readMode}) => {

    const [modalBF, setModalBF] = useState(false);
    const [posiblesBen, setPosiblesBen] = useState([]);
    const [fileCertificadoSagrilaft, setFileCertificadoSagrilaft] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cargaCertSagrilaft, setCargaCertSagrilaft] = useState(false);


    const params = useParams();

    const token = useSelector(state => state.auth.auth.access_token);

    const handleSwitchChange = (value) => {
      handleChangeSnIndicaBF(value);
    }

    const deleteBeneficiarie = (record) => {
      if (readMode === true){
        return;
      }
      let accTemp = beneficiarios.filter((value) => value.key !== record.key);
      onChange(accTemp);
    }

    const handleOnOpenModal = () => {
        setModalBF(true);
    }

    const handleOnCloseModal = () => {
        setModalBF(false);
    }

    const validateSizeExtfile =  (files) => {
      let totalSize = 0;
      let res = true;
      const validFileFormats = ['zip','rar','pdf','jpeg','png','7zip','doc','docx'];
      files && files.forEach(file =>{
        totalSize += file.size;
        if(!validFileFormats.includes(file.name.split('.').pop())){
          notification.warn({
            title: 'Alerta',
            message: "El documento no tiene el formato valido('zip','rar','pdf','jpeg','png','7zip','doc','docx')",
            duration: 4,
          });
          res = false;
        }
      })
      if(totalSize > 10000000){
        notification.warn({
          title: 'Alerta',
          message: "Maximo de tamaño excedido (10MB)",
          duration: 4,
        });
        res = false;
      } 
      return res;
    }

    const handleOnChangeUploadCertificado = (info) => {
      if(info.file.status === 'removed'){
        setFileCertificadoSagrilaft([]);
      }else{
        const fileList = info.fileList.length > 1 ? info.fileList : [info.file];
        if(validateSizeExtfile(fileList)){
          setFileCertificadoSagrilaft(fileList);
        }
      }
    };

    const handleOnFinishSaveCSBF = async () => {
      
      if(fileCertificadoSagrilaft.length === 0){
        notification.warn({
          title: 'Alerta',
          message: 'Por favor cargue el archivo antes de guardarlo',
          duration: 4,
        });
        return;
      }
  
      setLoading(true);
  
      try {
        const formData = new FormData();
        if(fileCertificadoSagrilaft.length > 1){
          fileCertificadoSagrilaft.forEach(file=>{
            formData.append('CSBF', file.originFileObj);
          });
        } else if(fileCertificadoSagrilaft.length === 1){
          formData.append('CSBF', fileCertificadoSagrilaft[0]);
        }
  
        const response = await grabarCertificadoSagrilaft(token, formData, company,params.cdTypeCompany);
            notification.success({
              message: 'Guardado',
              description: 'Documento cargado con éxito',
              duration: 4,
        });
  
        handleFileUploaded(true);
        setFileCertificadoSagrilaft([]);
      } catch (error) {
        notification.error({
          title: 'Error',
          message: 'Error guardando documento ' + error,
          duration: 4,
        });   
      }finally {
        setLoading(false);
      }
      
    };

    const getkey = (data) =>{
      
      let accTemp = [];
      
      if(data && data.length > 0){
        data.forEach((item) => {
          if (!item.key || item.key === '') {
            let uuid = uuidv4();
            item.key =  uuid;
            accTemp.push(item);
          }
        })
      }

      beneficiarios.forEach((ben) => {
        if(ben.key && ben.key !== ''){
          accTemp.push(ben);
        }
      });

      onChange(accTemp);

    }

    const handleOnFinish = (formValue) => {

      let finalBen = [];

      if(formValue.benAdicionales && formValue.benAdicionales.length > 0 ){

        formValue.benAdicionales.forEach((ben) => {
          let benTemp = posiblesBen.find((value) => value.id === ben);
          if(benTemp){
            let benSelected = {
              "nombre":benTemp.nombre,
              "id":benTemp.id,
              "key":undefined,
              "cdTipoPersona" :"BF",
              "cdTipoId":benTemp.tipo_id,
              "dsTipoId":tipoIds.find(value => value.key === benTemp.tipo_id).description
            }
            finalBen.push(benSelected)
          }

        })

      }

      if(formValue.cdTipoId && formValue.id && formValue.nombre && formValue.dsTipoId){
        let benSelected = {
          "nombre":formValue.nombre.toUpperCase(),
          "id":formValue.id,
          "key":undefined,
          "cdTipoPersona" :"BF",
          "cdTipoId":formValue.cdTipoId,
          "dsTipoId":formValue.dsTipoId
        }
        finalBen.push(benSelected);
      }

      getkey(finalBen);
      setModalBF(false);
    }

    useEffect(() => {
      let posiblesBen = [];
      if(person && person.cdTipoIdLeg && person.prApellidoLeg 
          && person.prNombreLeg && person.segApellidoLeg && person.idLeg){
            let benfinal = {
              "nombre": `${person.prNombreLeg} ${person.prApellidoLeg} ${person.segApellidoLeg}`,
              "tipo_id":person.cdTipoIdLeg,
              "id":person.idLeg
            }
        posiblesBen.push(benfinal);
      }

      if(accionistas && accionistas.length > 0){

        accionistas.forEach(accionista =>{
          let benfinal = {
            "nombre": accionista.nombre.toUpperCase(),
            "tipo_id":accionista.cdTipoId,
            "id":accionista.id
          }
          posiblesBen.push(benfinal);
        })
      }

      setPosiblesBen(posiblesBen);
      setCargaCertSagrilaft(cargaCertsag);
      
    }, [])
  
    const columnas = [
        {
          title: 'TIPO DE DOCUMENTO',
          dataIndex: 'dsTipoId',
        },
        {
          title: 'IDENTIFICACIÓN',
          dataIndex: 'id',
        },
        {
          title: 'NOMBRE/RAZON SOCIAL',
          dataIndex: 'nombre',
        },
        {
          title: 'ELIMINAR',
          dataIndex: '',
          render: (record) => (
            <DeleteOutlined
              className='info'
              onClick={() => deleteBeneficiarie(record)}
            />
          ),
          showSorterTooltip: false,
        },
      ];

  return (
    <div className='content-container'>
        <div className='form-container card-background-gray card-shadow card-radius'>
            <p><b>Indícanos la información de la(s) persona(s) que posee(n) o controlan la compañía y que en su nombre se realizan operaciones</b></p>
            <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                  <Col span={12}>
                    <Space>¿Tu empresa es libre de indicar a sus beneficiarios finales?</Space>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='cdIndicaBF'
                      valuePropName='checked'
                      noStyle
                    >
                        <Switch 
                            disabled={readMode === true ? true : false}
                            checkedChildren='Si' 
                            unCheckedChildren='No' 
                            onChange={handleSwitchChange}
                            defaultChecked
                        />
                    </Form.Item>
                  </Col>
            </Row>
            {
                snIndicaBF ? 
                
                <>
                <Row
                    gutter={[16, 16]}
                    align='middle'
                    justify='end'
                    style={{ marginBottom: '16px' }}
                >
                    <Col span={6} style={{ textAlign: 'right' }}>
                    <Button
                        className='btn-radius'
                        type='primary'
                        onClick={handleOnOpenModal}
                        disabled={readMode === true ? true : false}
                    >
                        Agregar Beneficiario
                    </Button>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
                    <Col flex='auto'>
                    <Table
                        columns={columnas}
                        dataSource={beneficiarios}
                        pagination={false}
                    ></Table>
                    </Col>
                </Row>
                <PersonaAdicionalForm
                    visible={modalBF}
                    onFinish={handleOnFinish}
                    onClose={handleOnCloseModal}
                    modalTitle={"Beneficiarios"}
                    formName={"beneficiarios_data"}
                    personType={'BF'}
                    posiblesBen={posiblesBen}
                />
                </> 
                
                : 
                <>
                { readMode !== true ?
                <Row>
                  <h4 style={{fontWeight:'bold'}}><b style={{color:'green'}}>Carga aquí la certificación firmada por tu oficial de cumplimiento o representante legal que garantice que tu empresa realizó la debida diligencia SAGRILAFT a sus beneficiarios finales</b></h4>
                  <br/>
                  <Col span={8}>
                    <Upload
                        beforeUpload={() => {
                        return false;
                        }}
                        fileList={fileCertificadoSagrilaft}
                        onChange={handleOnChangeUploadCertificado}
                        maxCount={5}
                        multiple
                    >
                      <Button icon={<UploadOutlined />}>Clic Para Cargar Archivo</Button>
                    </Upload>
                    {
                      fileBFuploaded || cargaCertSagrilaft  ? <Text type='secondary'><CheckCircleOutlined color='secondary'/><b style={{color:'gray'}} >{mensajecargado}</b></Text> : ''
                    }
                  </Col>
                <Col span={6} >
                    <Button
                      className='btn-radius'
                      type='primary'
                      onClick={handleOnFinishSaveCSBF}
                      style={{marginLeft:'5px'}}
                    >
                      Guardar Archivo
                      </Button>
                </Col>
                <Spin spinning={loading}/>
              </Row> : null}
              <Divider/>
              </>      
            }
        </div>
    </div>
  )
}
