import React, { useState, useEffect } from 'react';
import { Col, Form, Input, notification, Row, Spin, Tabs, Checkbox, Modal } from 'antd';
import { agregarPagadores, findAllPayers } from '../../../../../services/admin/requestServices';
import { useSelector } from 'react-redux';
import { ModalClients } from '../modalClients/modalclients';


export const RequestView = ({ data, dataVin }) => {
    const [activeTab, setactiveTab] = useState('1');
    const [clientes, setClientes] = useState([]);
    const [pagadores, setPagadores] = useState([]);
    const [modalStatus, setModalStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataSolicitud, setDataSolicitud] = useState({});
    const [formData, setFormdata] = useState({});
    const [form] = Form.useForm();


    const token = useSelector(state => state.auth.auth.access_token);

    const changeTab = (value) => {
        setactiveTab(value);
    }

    const handleModal = () => {
        setModalStatus(true);
    }

    const closeModal = () => {
        setModalStatus(false);
    }

    const onFinish = (value) => {
        setactiveTab(value);
    }

    const getclientes = (clientesVD, pagadores) => {
        if (clientesVD) {
            let clientesVDarray = JSON.parse(clientesVD);
            if (Array.isArray(clientesVDarray)) {
                setClientes(clientesVDarray);
            }
        }

        if (pagadores && Array.isArray(pagadores)) {
            setClientes((prevClientes) => [...prevClientes, ...pagadores]);
        }

    }

    const setformitems = (data) => {
        let dataform = {
            ...data,
            cdCentralesRiesgo: data.cdCentralesRiesgo === 'S' ? true : false,
            cdDatosPersonales: data.cdDatosPersonales === 'S' ? true : false,
            cdTermYcond: data.cdTermYcond === 'S' ? true : false,
        }
        form.setFieldsValue(dataform);
    }

    const getClientes = async () => {
        setLoading(true);
        try {
            await getclientes(dataVin?.clientesVd, dataVin?.pagadores);
        } catch {
            notification.error({
                message: 'Error',
                description: "Error Buscando clientes",
                duration: 5,
            });
        } finally {
            setLoading(false);
        }
    }

    const getPayers = async () => {
        try {
            const res = await findAllPayers(token);
            setPagadores(res?.data);
        } catch {
            notification.error({
                message: 'Error',
                description: "Error Buscando pagadores",
                duration: 5,
            });
        }
    }

    const guardarPagadores = async (pags) => {
        let pagadoresArray = [];

        for (const element of pags) {
            let founded = clientes.find(cliente => cliente?.dsNit === element?.nit);
            if (founded) {
                notification.warning({
                    message: 'Alerta',
                    description: "Uno de los clientes ingresados ya está en la lista que ingresó el cliente.",
                    duration: 5,
                });
                return;
            }

            if (element.nit && element.rs) {
                pagadoresArray.push({
                    dsNit: element.nit,
                    dsRazonSocial: element.rs,
                    scPagadorVd: element.scpag ? element.scpag : ''
                })
            }
        }

        try {
            setModalStatus(false);
            setLoading(true);
            await agregarPagadores(token, dataSolicitud.scVinDigital, formData.cdTipoPersona, pagadoresArray);
            notification.success({
                message: 'Guardado',
                description: "Pagadores guardados con éxito",
                duration: 5,
            });
            setClientes((prevClientes) => [...prevClientes, ...pagadoresArray]);
        } catch {
            notification.error({
                message: 'Error',
                description: "Error guardando pagadores",
                duration: 5,
            });
        } finally {
            setLoading(false);
        }

    }

    useEffect(() => {
        setDataSolicitud(data);
        setFormdata(dataVin);
        setformitems({...dataVin, montoASolicitar: data.montoSolicitar});
        getClientes();
        getPayers();
    }, [])

    return (
        <div>
            <Spin tip='Cargando...' spinning={loading}>
                <Row gutter={16}>
                    <Col flex='auto'>
                        <Form layout='vertical' form={form} onFinish={onFinish}>
                            <Tabs activeKey={activeTab} onChange={changeTab}>
                                <Tabs.TabPane
                                    key={'1'}
                                    tab='Información de contacto'
                                >
                                    <br />
                                    <Row gutter={16} justify='center'>
                                        <Col span={10}>
                                            <Form.Item
                                                name='nombres'
                                                label='Nombres'
                                            >
                                                <Input readOnly={true} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                name='apellidos'
                                                label='Apellidos'
                                            >
                                                <Input readOnly={true} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16} justify='center'>
                                        <Col span={10}>
                                            <Form.Item
                                                name='cdTipoId'
                                                label='Tipo de Documento'
                                            >
                                                <Input readOnly={true} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                name='idUser'
                                                label='Número de Documento'
                                            >
                                                <Input readOnly={true} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16} justify='center'>
                                        <Col span={10}>
                                            <Form.Item
                                                name='email'
                                                label='Correo electrónico'
                                            >
                                                <Input readOnly={true} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                name='tel'
                                                label='Celular'
                                            >
                                                <Input readOnly={true} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16} justify='center'>
                                        <Col span={10}>
                                            <Form.Item
                                                name='rol'
                                                label='Rol'
                                            >
                                                <Input readOnly={true} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                name='cmnk'
                                                label='¿ Como nos conociste ?'
                                            >
                                                <Input readOnly={true} />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    {dataSolicitud?.cdTipoSolicitud === 'FA' ?

                                        <Row gutter={16} justify='center'>
                                            <Col span={12}>
                                                <Form.Item
                                                    name='montoASolicitar'
                                                    label='¿ Cuanto suman tus facturas al mes ?'
                                                >
                                                    <Input readOnly={true} />
                                                </Form.Item>
                                            </Col>
                                        </Row> : null}


                                    <Col span={24}>
                                        <Row gutter={16} justify='center'>
                                            <div className='check'>
                                                <Form.Item
                                                    name='cdCentralesRiesgo'
                                                    valuePropName='checked'
                                                >
                                                    <Checkbox className='tax-info-check' disabled={true}>
                                                        Autorizo
                                                        <a
                                                            href='https://resources-affirmatum.s3.amazonaws.com/CentralesRiesgo.pdf'
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                        >
                                                            {' '}
                                                            Consulta y reporte en centrales de riesgo
                                                        </a>
                                                    </Checkbox>
                                                </Form.Item>
                                                <Form.Item
                                                    name='cdDatosPersonales'
                                                    valuePropName='checked'
                                                >
                                                    <Checkbox className='tax-info-check' disabled={true}>
                                                        Autorizo
                                                        <a
                                                            href='https://resources-affirmatum.s3.us-east-1.amazonaws.com/GE-PO-01-Politica-de-tratamiento-de-datos-personales-Exponencial-Confirming-S.A.S.-V3.pdf'
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                        >
                                                            {' '}
                                                            Tratamiento de datos personales
                                                        </a>
                                                    </Checkbox>
                                                </Form.Item>
                                                <Form.Item name='cdTermYcond' valuePropName='checked'>
                                                    <Checkbox className='tax-info-check' disabled={true}>
                                                        Acepto los
                                                        <a
                                                            href='https://www.exponencialconfirming.com/condiciones-de-uso-del-sistema-affirmatum/'
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                        >
                                                            {' '}
                                                            Términos y condiciones
                                                        </a>
                                                    </Checkbox>
                                                </Form.Item>
                                            </div>
                                        </Row>
                                    </Col>

                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    key={'2'}
                                    tab='Clientes'
                                >
                                    <br />
                                    <Row gutter={16} justify='end'>
                                        <div className='check'>
                                            <a style={{ paddingRight: '20px' }} onClick={handleModal}>+ Añadir Cliente</a>
                                        </div>
                                    </Row>
                                    {
                                        clientes.map((cliente, index) => {
                                            return (
                                                <Row key={index} gutter={16} justify='center'>
                                                    <Col span={10}>
                                                        <Form.Item
                                                            key={index}
                                                            name={`dsNit_${index}`}
                                                            label='NIT'
                                                            initialValue={cliente.dsNit}
                                                        >
                                                            <Input readOnly={true} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={10}>
                                                        <Form.Item
                                                            key={index}
                                                            name={`dsRazonSocial_${index}`}
                                                            label='Razón social'
                                                            initialValue={cliente.dsRazonSocial}
                                                        >
                                                            <Input readOnly={true} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>)
                                        })
                                    }

                                </Tabs.TabPane>
                            </Tabs>
                        </Form>
                    </Col>
                </Row>
                <Modal
                    visible={modalStatus}
                    title="Añadir Clientes"
                    footer={null}
                    onCancel={closeModal}
                >
                    <ModalClients
                        visible={modalStatus}
                        pagadores={pagadores}
                        handleGuardarPag={guardarPagadores}
                    />
                </Modal>
            </Spin>
        </div>
    )
}
