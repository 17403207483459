export const mensajesEstadoVinculacion = [
  {
    key: 'AP',
    description: 'Aprobada',
    message:
      'Por favor revise nuevamente cada uno de los pasos y termine toda la información solicitada.',
  },
  {
    key: 'E',
    description: 'En estudio',
    message: 'Su solicitud se encuentra en estudio.',
  },
  {
    key: 'RE',
    description: 'No Aprobada',
    message:
      'Después de verificar la información suministrada, en este momento no es viable aprobar su solicitud.',
  },
  { key: 'I', description: 'Incompleta' },
  {
    key: 'CE',
    description: 'Registro completo y enviado',
    message:
      'Una asesora se comunicara con usted para continuar con el proceso de firma electrónica de los documentos.',
  },
  {
    key: 'P',
    description: 'En curso',
    message:
      'Por favor siga los pasos del menú y complete toda la información solicitada.',
  },
  { key: 'C', description: 'Completa' },
  {
    key: 'DI',
    description: 'Documentación incompleta',
    message:
      'Por favor revise y corrija la documentación pendiente y termine el registro nuevamente.',
  },
];
