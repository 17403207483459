import React from 'react';
import { Col, Form, Input, InputNumber, Row } from 'antd';
import {
  digitsMessagge,
  digitsPattern,
  requiredValueMessage,
} from '../../../../utilities/admin_validation_messages';
import {
  currencyInputFormat,
  currencyInputParser,
} from '../../../../utilities/textTools';
import { length } from '../../../../utilities/validate/textValidate';
import * as numberValidate from '../../../../utilities/validate/numberValidate';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const { TextArea } = Input;

const rules = {
  nmTotalActivos: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: digitsPattern(25, 8),
      message: digitsMessagge(25, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  nmTotalPasivos: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: digitsPattern(25, 8),
      message: digitsMessagge(25, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  nmTotalPatrimonio: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: digitsPattern(25, 8),
      message: digitsMessagge(25, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  nmIngresos: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: digitsPattern(25, 8),
      message: digitsMessagge(25, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  nmOtrosIngresos: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: digitsPattern(25, 8),
      message: digitsMessagge(25, 8),
    },
  ],
  nmEgresos: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: digitsPattern(25, 8),
      message: digitsMessagge(25, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  otrosIngresos: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 1, 4000),
    },
  ],
};

const rules2 = {
  nmTotalActivos: [
    {
      pattern: digitsPattern(25, 8),
      message: digitsMessagge(25, 8),
    }
  ],
  nmTotalPasivos: [
    {
      pattern: digitsPattern(25, 8),
      message: digitsMessagge(25, 8),
    }
  ],
  nmTotalPatrimonio: [
    {
      pattern: digitsPattern(25, 8),
      message: digitsMessagge(25, 8),
    }
  ],
  nmIngresos: [
    {
      pattern: digitsPattern(25, 8),
      message: digitsMessagge(25, 8),
    }
  ],
  nmOtrosIngresos: [
    {
      pattern: digitsPattern(25, 8),
      message: digitsMessagge(25, 8),
    },
  ],
  nmEgresos: [
    {
      pattern: digitsPattern(25, 8),
      message: digitsMessagge(25, 8),
    }
  ],
};

class FinancialNatural extends React.Component {
  render() {
    const { companyType } = this.props;
    return (
      <div className='content-container'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='nmTotalActivos'
                label='Total activos'
                rules={companyType === 'R' ? rules['nmTotalActivos'] : rules2['nmTotalActivos']}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  formatter={(value) => currencyInputFormat(value)}
                  parser={(value) => currencyInputParser(value)}
                  stringMode={true}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='nmTotalPasivos'
                label='Total pasivos'
                rules={companyType === 'R' ? rules['nmTotalPasivos'] : rules2['nmTotalPasivos']}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  formatter={(value) => currencyInputFormat(value)}
                  parser={(value) => currencyInputParser(value)}
                  stringMode={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='nmTotalPatrimonio'
                label='Total patrimonio'
                rules={companyType === 'R' ? rules['nmTotalPatrimonio'] : rules2['nmTotalPatrimonio']}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  formatter={(value) => currencyInputFormat(value)}
                  parser={(value) => currencyInputParser(value)}
                  stringMode={true}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='nmIngresos'
                label='Total ingresos (mensuales)'
                rules={companyType === 'R' ? rules['nmIngresos'] : rules2['nmIngresos']}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  formatter={(value) => currencyInputFormat(value)}
                  parser={(value) => currencyInputParser(value)}
                  stringMode={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='nmOtrosIngresos'
                label='Otros ingresos (mensuales)'
                rules={companyType === 'R' ? rules['nmOtrosIngresos'] : rules2['nmOtrosIngresos']}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  formatter={(value) => currencyInputFormat(value)}
                  parser={(value) => currencyInputParser(value)}
                  stringMode={true}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='nmEgresos'
                label='Total egresos (mensuales)'
                rules={companyType === 'R' ? rules['nmEgresos'] : rules2['nmEgresos']}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  formatter={(value) => currencyInputFormat(value)}
                  parser={(value) => currencyInputParser(value)}
                  stringMode={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col flex='auto'>
              <Form.Item
                name='otrosIngresos'
                label='Detalle de otros ingresos no operacionales u originados en actividades diferentes a la principal'
                rules={companyType === 'R' ? rules['otrosIngresos'] : rules2['otrosIngresos']}
              >
                <TextArea rows={5} minLength={1} maxLength={4000} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const cdTypeCompany = props.match.params.cdTypeCompany;
  return {
    companyType : cdTypeCompany
  };
};

export default withRouter(connect(mapStateToProps, {})(FinancialNatural));

