import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_VINCULACION;

export const listaPaises = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-paises?${queryString.stringify(filtros)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    })
  );
};

export const listaMunicipios = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-municipios?${queryString.stringify(filtros)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    })
  );
};

export const listaDepartamentos = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-departamentos?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    })
  );
};

export const consultaTiposActividades = (authToken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-actividades-economicas?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  );
};
