import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const exportarArchivoTransunion = (authtoken, idProveedor) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/creditos/transunion/${idProveedor}/exportar/xlsx`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    })
  );
};

export const exportarArchivoListaControl = (authtoken, idProveedor) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/creditos/listaDeControl/${idProveedor}/exportar/xlsx`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    })
  );
};

export const exportarArchivoRues = (authtoken, idProveedor) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/creditos/fuentesExternas/${idProveedor}/exportar/xlsx`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    })
  );
};

export const consultarNits = (authtoken, files, selectedOption) => {
  const queryParam = { tipoConsulta: selectedOption };
  return middleWare(
    axios({
      url: `${baseUrl}/api/creditos/fuentesExternas/cargar-archivo?${queryString.stringify(queryParam)}`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authtoken}`,
      },
      data: files,
    })
  );
};


export const downloadTemplate = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/creditos/fuentesExternas/plantilla`,
      responseType: 'blob',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    })
  );
};

export const tipoConsulta = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/creditos/fuentesExternas/tipoConsulta`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    })
  );
}