import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Spin, Switch } from 'antd';
import {
  currencyInputFormat,
  currencyInputParser,
} from '../../../../utilities/textTools';
import { BUSQUEDA_TITLE, DATE_FORMAT, HELP_MESSAGE_MANUAL_BILL } from '../../../../utilities/messages';
import * as numberValidate from '../../../../utilities/validate/numberValidate';
import moment from 'moment';
import { requiredValueMessage } from '../../../../utilities/admin_validation_messages';
import { length } from '../../../../utilities/validate/textValidate';
import TextArea from 'antd/lib/input/TextArea';

const initialValue = {
  feEmision: moment(),
  feVencimiento: moment(),
  scCliente: undefined,
  dsConcepto: undefined,
  vrFactura: undefined,
  snIvaFactura: true,
  dsObservacion: undefined
};

const InvoiceManualFiltreForm = ({
  handleSubmit,
  companiaSelect,
  handleOnSearchCompania,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});



  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.setFieldsValue({ ...initialValue });
  }, [form]);

  const handleFormFinish = async (values) => {
    await handleSubmit(values);
    form.resetFields();
  };


  return (
    <div className='header-container'>
      <p className='title'>AGREGAR FACTURA MANUAL</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={handleFormFinish}
        initialValues={initialValue}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='feEmision'
              label='FECHA EMISIÓN'
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione una fecha de emisión',
                },
              ]}
            >
              <DatePicker name='feEmision' format={DATE_FORMAT} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='feVencimiento'
              label='FECHA VENCIMIENTO'
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione una fecha de vencimiento'
                },
              ]}
            >
              <DatePicker name='feVencimiento' format={DATE_FORMAT} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='scCliente'
              label='CLIENTE'
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione un cliente'
                },
              ]}>
              <Select
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchCompania(value)}
                notFoundContent={
                  companiaSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {companiaSelect.options.map((d) => (
                  <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='vrFactura'
              label='VALOR FACTURA'
              rules={[{
                validator: (_, value) => numberValidate.range(value, 0, 999999999999)
              },
              {
                required: true,
                message: 'Por favor agrega un valor a la factura'
              }
              ]}>
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                formatter={currencyInputFormat}
                parser={currencyInputParser}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          {/* Switch entre valor con iva o no */}
          <Col>
            <Form.Item
              name="snIvaFactura"
              label='FACTURA CON IVA'
            >
              <Switch
                defaultChecked
                checkedChildren="SI"
                unCheckedChildren="NO"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex='auto'>
            <Form.Item
              name='dsConcepto'
              label='Escribe el mensaje del concepto'
              rules={[
                { required: true, message: requiredValueMessage() },
                { validator: (_, value) => length(value, 1, 4000), },
              ]}
            >
              <TextArea rows={5} minLength={1} maxLength={4000} style={{ resize: 'none' }} />
            </Form.Item>
          </Col>
          <Col flex='auto'>
            <Form.Item
              name='dsObservacion'
              label='Escribe el mensaje de la observación'
              rules={[
                { validator: (_, value) => length(value, 1, 4000), }
              ]}
            >
              <TextArea
                rows={5}
                minLength={1}
                maxLength={4000}
                style={{ resize: 'none' }}
                placeholder={HELP_MESSAGE_MANUAL_BILL}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify='left'>
          <Col span={8}>
            <Form.Item>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
              >
                GENERAR FACTURA MANUAL
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default InvoiceManualFiltreForm;
