import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import '../../reportsSagrilaft.scss';
import {
  Card,
  Spin,
  Space,
  Row,
  Col,
  Divider,
  Button,
  notification,
  Table,
} from 'antd';
import { reporteSarlaftDetails } from '../../../../../../services/admin/reports/sagrilaft';

const initialState = {
  loading: false,
  resultado: [],
  total: 0,
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  inputValue: '',
};

class ReportSarlaftDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'IDENTIFICACIÓN',
        dataIndex: 'dsId',
        showSorterTooltip: false,
      },
      {
        title: 'NOMBRE',
        dataIndex: 'dsNombre',
        showSorterTooltip: false,
      },
      {
        title: 'LISTAS SARLAFT',
        dataIndex: 'listasSarlaft',
        showSorterTooltip: false,
      },
      {
        title: 'PERFIL DE RIESGO',
        dataIndex: 'perfilRiesgo',
        showSorterTooltip: false,
      },
    ];
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { authToken } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await reporteSarlaftDetails(
        authToken,
        this.props.scCompania
      );

      this.setState({
        ...this.state,
        resultado: response.data,
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Se presento un error inesperado',
        duration: 5,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleBackButton = () => {
    this.props.history.goBack();
  };

  render() {
    const { loading, total, resultado } = this.state;
    return (
      <div className='reports-style-f'>
        <Card className='card-shadow'>
          <div className='align-center'>
            <div className='reports-container'>
              <Spin tip='Cargando...' spinning={loading}>
                <Row gutter={16}>
                  <Col>
                    <Space align='baseline' className='sub-title' />
                  </Col>
                </Row>
                <Divider />
                <Table
                  columns={this.columnas}
                  total={total}
                  dataSource={resultado}
                  pagination={false}
                />
                <Button
                  type='primary'
                  className='form-btn btn-radius'
                  style={{
                    marginTop: '2rem',
                    marginLeft: '2rem',
                    display: 'block',
                    width : '20%'
                  }}
                  onClick={this.handleBackButton}
                >
                  Volver
                </Button>
              </Spin>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const scCompania = atob(props.match.params.scCompania);
  return {
    authToken: state.auth.auth.access_token,
    scCompania: scCompania,
  };
};

export default withRouter(connect(mapStateToProps)(ReportSarlaftDetails));
