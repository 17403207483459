import {
  Button,
  Checkbox,
  Col,
  Form,
  Row,
  Radio,
} from "antd";
import {
  WhatsAppOutlined,
  PhoneOutlined,
  MailOutlined,
} from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import React, { useReducer } from "react";
import Text from "antd/lib/typography/Text";
import { capitalize } from "../utilities/textTools";
import avatar from "../assets/img/help_center1.png";
import store from "../store";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

const initialFormValue = {
  cdEnviarOfertas: true,
  cdEmail: true,
  cdTelefono: true,
  cdWhatsapp: true,
  numeroCelular: "",
};

const OperationLeyModal = ({ onFinish }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "checkOptionLey":
        return { ...state, [action.field]: action.value };
      case "checkAcceptLey":
        return{...state,[action.field]: action.value};
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialFormValue);

  const handleOnChange = (checked, name) => {
    dispatch({ type: "checkOptionLey", field: name, value: checked });
  };

  const handleOnChangeGroup = (value, name) => {
    dispatch({ type: "checkAcceptLey", field: name, value: value });
  };

  const handleOnFinish = () => {
    if (onFinish) {
      onFinish(state);
    }
  };

  return (
    <Form initialValues={initialFormValue} onFinish={handleOnFinish}>
      <Row>
        <Col span={12}>
          <Row justify="center" align="middle">
            <Col span={20}>
              <Title level={2}>
                {`¡Hola ${capitalize(
                  store.getState().auth.auth.name.split(" ")[0].toLowerCase()
                ).trim()}!`}
              </Title>
            </Col>
          </Row>
          <Row justify="center" align="middle" gutter={[40, 16]}>
            <Col span={20} style={{ marginBottom: "2rem" }}>
              <img src={avatar} alt="avatar_assited" width="100%"></img>
            </Col>
          </Row>

          <Row gutter={[22, 12]} justify="center" align="middle">
            <Col span={20}>
              <p style={{ textAlign: "center", fontSize: "15px" }}>
                Recibe notificaciones diarias de ofertas para adelantar la
                liquidez de tu empresa.
              </p>
            </Col>
            <Col span={20}>
              <p style={{ textAlign: "center", fontSize: "15px" }}>
                <b>¡Recuerda!</b> Las ofertas llegarán según tus preferencias:
                sólo el primer día hábil de la semana o diariamente.
              </p>
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Row gutter={[18, 12]} justify="center" align="middle">
            <Col span={20}>
              <p style={{ textAlign: "center", fontSize: "15px" }}>
                ¿Quieres recibir diariamente la información de ofertas
                disponibles?.
              </p>
            </Col>
          </Row>
          <Row justify="center" align="middle">
            <Form.Item name="cdEnviarOfertas">
              <Radio.Group
                buttonStyle="solid"
                align="middle"
                align-items="center"
                defaultValue={true}
                onChange={(e) =>
                  handleOnChangeGroup(e.target.value, "cdEnviarOfertas")
                }
              >
                <Radio.Button value={true} className="radio-btn">
                  SI
                </Radio.Button>
                <Radio.Button value={false} className="radio-btn">
                  NO
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Row>

          <Row justify="center" align="middle" gutter={16}>
            <Col span={20}>
              <p style={{ textAlign: "center", fontSize: "15px" }}>
                Selecciona los medios de contacto de tu preferencia para
                publicidad y cobranza:
              </p>
            </Col>
          </Row>
          <Row justify="center" align="middle">
            <Col span={20} className="dash-how-much">
              <div className="check" style={{ paddingTop: "2rem" }}>
                <Form.Item name="cdEmail" valuePropName="checked">
                  <Checkbox
                    className="tax-info-check"
                    onChange={(e) =>
                      handleOnChange(e.target.checked, "cdEmail")
                    }
                  >
                    Correo electrónico <MailOutlined />
                  </Checkbox>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row justify="center" align="middle">
            <Col span={20} className="dash-how-much">
              <div className="check">
                <Form.Item name="cdTelefono" valuePropName="checked">
                  <Checkbox
                    className="tax-info-check"
                    onChange={(e) =>
                      handleOnChange(e.target.checked, "cdTelefono")
                    }
                  >
                    <Text level={3}>
                      Llamada <PhoneOutlined />
                    </Text>
                  </Checkbox>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row justify="center" align="middle">
            <Col span={20} className="dash-how-much">
              <div className="check">
                <Form.Item name="cdWhatsapp" valuePropName="checked">
                  <Checkbox
                    className="tax-info-check"
                    onChange={(e) =>
                      handleOnChange(e.target.checked, "cdWhatsapp")
                    }
                  >
                    <Text level={3}>
                      Whatsapp <WhatsAppOutlined />
                    </Text>
                  </Checkbox>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row justify="center" align="middle">
            <Col span={20} className="dash-how-much">
              <div>
                {state.cdWhatsapp && (
                  <Row gutter={16}>
                    <Col flex="auto">
                      <Form.Item
                        name="numeroCelular"
                        label="Número de celular: "
                        rules={[{ required: true, message: "Por favor, ingresa un número válido" },
                                { max: 15, message: "El número no puede tener más de 15 dígitos" },
                        ]}
                      >
                        <PhoneInput
                          international
                          defaultCountry="CO" // País predeterminado
                          value={state.numeroCelular}
                          onChange={(value) => handleOnChange(value, "numeroCelular")}
                          placeholder="Ingresa tu número celular"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <br/>
      <Row gutter={[12, 12]} justify="center" align="bottom">
        <Col span={8} align="center">
          <Form.Item>
            <Button
              className="form-btn btn-radius"
              type="primary"
              htmlType="submit"
            >
              Enviar Información
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default OperationLeyModal;
