import { Button, Card, Col, Divider, Modal, Row, Space, Spin, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import { tipoSolicitud } from '../../../utilities/tipoSolicitud';
import { FormRequest } from './FormRequest/formRequest';
import { Documentos } from './DocsFormRequest/documentos';
import { validarProveedorSolicitudes } from '../../../services/proveedor/requestServices';
import { estadoSolicitudMsj } from '../../../utilities/estadoSolicitudMsj';


const fichaDesactualizadaMsj = "Tu ficha cliente se encuentra en este momento desactualizada. Te invitamos a realizar la actualización por la opción del menú “ficha cliente” para continuar con tu solicitud.";
const solRechazada = "Por ahora no puedes hacer una nueva solicitud, para más detalles comunícate con tu comercial asignado.";
const solIni = "En este momento tienes una solicitud en curso, verifica en el menú “Mis solicitudes” para ver el estado de tu solicitud.";
const solDI = "Por favor ingresa al detalle de tus solicitudes y corrija la documentación pendiente.";


export const NewRequest = (props) => {
    const [loading, setloading] = useState(false);
    const [tipoSol, setTipoSol] = useState("");
    const [desPro, setDesPro] = useState("");
    const [solState, setSolState] = useState("");
    const [provState, setProvState] = useState(false);
    const [scSolicitud, setScSolicitud] = useState("");
    const token = useSelector(state => state.auth.auth.access_token);
    const scPersona = useSelector(state => state.auth.auth.scPersona);
    const scProveedor = useSelector(state => state.billing.company);
    const params = useParams();

    const solproveedor = {
      cdOrigenSol : 'S'
    }

    const validarProveedor = async (tipoSol) =>{
      setloading(true);
      try {
        const res = await validarProveedorSolicitudes(token,scProveedor,tipoSol);
        setSolState(res.data.cdEstado);
      } catch (error) {
        notification.error({
          message: 'Error',
          description: "Error validando estado " + error,
          duration: 4,
        })
      } finally {
        setloading(false);
      }
    }

    const changeStatesol = (state) =>{
      setSolState(state);
    }

    const changeStateScSol = (state) =>{
      setScSolicitud(state);
    }

    const setMenuLabel = (tipoSolicitud) =>{
      switch (tipoSolicitud) {
        case 'FA':
          props.menuHandler('8');
        break;

        case 'RC':
          props.menuHandler('9');
        break;

        case 'CCR':
          props.menuHandler('10');
        break;

        case 'CSR':
          props.menuHandler('11');
        break;
      }
    }

    useEffect(() => {
        setTipoSol(params.tipoSolicitud);
        setMenuLabel(params.tipoSolicitud);
        const found = tipoSolicitud.find((element) => element.key === params.tipoSolicitud);
        if (found != undefined){
          setDesPro(found.description);
        }
        if(scProveedor && params.tipoSolicitud){
          setProvState(true);
          validarProveedor(params.tipoSolicitud);
        }
    }, [params.tipoSolicitud,scProveedor])
    
  return (
    <Spin tip='Cargando...' spinning={loading}>
        <div className='users-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <div className='table-container'>
              <h2 align="center">Nueva solicitud {tipoSol !== 'CSR' ? 'de cupo'  : desPro}</h2>
              <Divider />
                {provState && (solState === 'SI' ? 
                        <h3><b>{solIni}</b></h3> : 
                    solState === 'FCD' ?       
                        <h3><b>{fichaDesactualizadaMsj}</b></h3> : 
                    solState === 'A' ?
                        <h3><b>{solRechazada}</b></h3> :
                    solState === 'DI' ?
                    <h3><b>{solDI}</b></h3> :
                    solState === 'R' ?
                        <h3><b>{solRechazada}</b></h3> :
                    solState === 'PD' ?
                        <Documentos tipoSolicitud={tipoSol} 
                                    setState={changeStatesol}
                                    scSolicitud = {scSolicitud}
                                    estadoSolicitud = {solState} 
                                    /> :
                        (<Row gutter={16}>
                            <FormRequest 
                              tipoSolicitud={tipoSol}
                              setState={changeStatesol}
                              setscSolicitud={changeStateScSol}
                              readMode={false}
                              recordData={solproveedor}
                             /> 
                        </Row>))
                }
            </div>
          </Card>
        </div>
      </Spin>
  )
}
