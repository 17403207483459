import { Button, Card, Col, notification, Spin, Tabs } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  editarProveedor,
  getProveedor,
  listaGruposEmpresarialesProveedores,
  listaPropietarios,
  validarSarlaftProveedor,
} from '../../../../../services/admin/suppliersService';
import history from '../../../../../services/history';
import '../../common/client.scss';
import Documents from '../../common/documents/documents';
import Users from '../../common/users/users';
import BankAccount from '../bankAccount/bankAccount';
import BasicDataForm, {
  initialSupplierValuesForm,
} from '../BasicDataForm/BasicDataForm';
import SupplierAgreements from '../supplierAgreements/supplierAgreements';
import TaxInformation from '../taxInformation/taxInformation';

const SUPPLIER_TITLE = 'Proveedor';

const initialState = {
  activeTab: '1',
  loading: false,
  propietarioSelect: {
    options: [],
    fetching: false,
  },
  grupoSelect: [],
  data: { ...initialSupplierValuesForm },
};

class SupplierEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  handleOnPropietarioSearch = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { propietarioSelect } = this.state;

      this.setState({
        ...this.state,
        propietarioSelect: {
          ...propietarioSelect,
          fetching: true,
        },
      });

      try {
        const response = await listaPropietarios(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          propietarioSelect: {
            ...propietarioSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          propietarioSelect: {
            ...propietarioSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  getProveedorData = async () => {
    const { scPersona, authToken } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await getProveedor(authToken, scPersona);

      const grupoResponse = await listaGruposEmpresarialesProveedores(
        authToken,
        { scProveedor: scPersona }
      );

      const propietarioResponse = await listaPropietarios(authToken, {
        nombre: response.data.nombrePropietario,
      });

      const scGrupoAsociado = grupoResponse.data
        .filter((value) => value.seleccionado)
        .map((value) => value.codigo);

      this.setState({
        ...this.state,
        loading: false,
        grupoSelect: grupoResponse.data,
        scGrupoAsociado: scGrupoAsociado,
        data: { ...response.data, scGrupoAsociado: scGrupoAsociado },
        propietarioSelect: {
          ...this.state.propietarioSelect,
          options: propietarioResponse.data,
          fetching: false,
        },
      });
    } catch (error) {
      this.redirectToSuppliers();
    }
  };

  redirectToSuppliers = () => {
    history.push(`${process.env.PUBLIC_URL}/suppliers`);
  };

  validarSarlaft = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await validarSarlaftProveedor(this.props.authToken);
      notification.success({
        message: SUPPLIER_TITLE,
        description: response.message,
        duration: 6,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleOnFinish = async (formValue) => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await editarProveedor(this.props.authToken, formValue);

      notification.success({
        message: SUPPLIER_TITLE,
        description: response.message,
        duration: 6,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  redirectToEditSuppliers = (scPersona) => {
    history.push(`${process.env.PUBLIC_URL}/suppliers/edit/${btoa(scPersona)}`);
  };

  redirectToPerson = () => {
    const { scPersona } = this.props;
    const {
      data: { cdTipoPersona },
    } = this.state;
    history.push(
      `${process.env.PUBLIC_URL}/person/${cdTipoPersona}/${btoa(scPersona)}/R`
    );
  };

  handleTabOnChange = (e) => {
    this.setState({
      ...this.state,
      activeTab: e,
    });
  };

  componentDidMount() {
    this.props.menuHandler('20');
    this.getProveedorData();
  }

  componentDidUpdate(props, state) {
    if (props.scPersona !== this.props.scPersona) {
      this.getProveedorData();
    }
  }

  render() {
    const { loading, propietarioSelect, grupoSelect, data, activeTab } =
      this.state;

    const { authToken, scPersona } = this.props;

    return (
      <div className='suppliers-style'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <Tabs activeKey={activeTab} onChange={this.handleTabOnChange}>
            <Tabs.TabPane key={'1'} tab='Datos básicos'>
              <Spin tip='Cargando...' spinning={loading}>
                <BasicDataForm
                  visibleSearchButton={false}
                  data={data}
                  rules={this.rules}
                  grupoSelect={grupoSelect}
                  propietarioSelect={propietarioSelect}
                  onPropietarioSearch={this.handleOnPropietarioSearch}
                  onFinish={this.handleOnFinish}
                >
                  <>
                    <Col span={6}>
                      <Button
                        style={{ width: '90%' }}
                        className='btn btn-blue'
                        type='primary'
                        onClick={this.redirectToSuppliers}
                      >
                        Lista de proveedores
                      </Button>
                    </Col>
                    <Col span={6}>
                      <Button
                        style={{ width: '90%' }}
                        className='btn btn-blue'
                        type='primary'
                        onClick={this.redirectToPerson}
                      >
                        Ficha cliente
                      </Button>
                    </Col>
                    <Col span={6}>
                      <Button
                        style={{ width: '90%' }}
                        className='btn btn-blue'
                        type='primary'
                        onClick={this.validarSarlaft}
                      >
                        Validar sarlaft
                      </Button>
                    </Col>
                  </>
                </BasicDataForm>
              </Spin>
            </Tabs.TabPane>
            <Tabs.TabPane key={'2'} tab='Documentos'>
              <Documents
                authToken={authToken}
                scPersona={scPersona}
                tipoAsociado='R'
              >
                <Col span={6}>
                  <Button
                    style={{ width: '90%' }}
                    className='btn btn-blue'
                    type='primary'
                    onClick={this.redirectToSuppliers}
                  >
                    Lista de proveedores
                  </Button>
                </Col>
              </Documents>
            </Tabs.TabPane>
            <Tabs.TabPane key={'3'} tab='Cuentas bancarias'>
              <BankAccount
                authToken={authToken}
                scPersona={scPersona}
                tipoAsociado='R'
              >
                <Col span={6}>
                  <Button
                    style={{ width: '90%' }}
                    className='btn btn-blue'
                    type='primary'
                    onClick={this.redirectToSuppliers}
                  >
                    Lista de proveedores
                  </Button>
                </Col>
              </BankAccount>
            </Tabs.TabPane>
            <Tabs.TabPane key={'4'} tab='Usuarios'>
              <Users
                authToken={authToken}
                scPersona={scPersona}
                tipoAsociado='R'
              >
                <Col span={6}>
                  <Button
                    style={{ width: '90%' }}
                    className='btn btn-blue'
                    type='primary'
                    onClick={this.redirectToSuppliers}
                  >
                    Lista de proveedores
                  </Button>
                </Col>
              </Users>
            </Tabs.TabPane>
            <Tabs.TabPane key={'5'} tab='Información tributaria'>
              <TaxInformation
                authToken={authToken}
                scPersona={scPersona}
                tipoAsociado='R'
              >
                <Col span={6}>
                  <Button
                    style={{ width: '90%' }}
                    className='btn btn-blue'
                    type='primary'
                    onClick={this.redirectToSuppliers}
                  >
                    Lista de proveedores
                  </Button>
                </Col>
              </TaxInformation>
            </Tabs.TabPane>
            <Tabs.TabPane key={'6'} tab='Convenios'>
              <SupplierAgreements
                authToken={authToken}
                scPersona={scPersona}
                tipoAsociado='R'
              >
                <Col span={6}>
                  <Button
                    style={{ width: '90%' }}
                    className='btn btn-blue'
                    type='primary'
                    onClick={this.redirectToSuppliers}
                  >
                    Lista de proveedores
                  </Button>
                </Col>
              </SupplierAgreements>
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const scPersona = atob(props.match.params.scPersona);
  return {
    scPersona: scPersona,
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default withRouter(connect(mapStateToProps, {})(SupplierEditForm));
