import React from 'react';
import './dashboard.scss';
import {
  Row,
  Col,
  Card,
  message,
  Table,
  Button,
  Typography,
  Input,
  Modal,
  InputNumber,
  notification,
  Image,
} from 'antd';
import { ClockCircleOutlined, BarChartOutlined } from '@ant-design/icons';

import LineChart from '../../../components/linechart';
import avatar from '../../../assets/img/Dash.png';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import store from '../../../store/index';
import { capitalize } from '../../../utilities/textTools';
import {
  facturasProveedorPorCompania,
  flujoDeCajaEsperado,
} from '../../../services/proveedor/reportServices';
import { setNeededAmount } from '../../../store/actions/proveedor/billingActions';
import history from '../../../services/history';
import moment, { duration } from 'moment';
import { loadReportSummaryAction } from '../../../store/actions/proveedor/reportsActions';

import Tutorial from '../../../components/tutorial';
import {
  DASHBOARD_MODAL_INIT,
  DASHBOARD_MODAL_INIT_WITH_VALUE,
  CHARGEALL_VALUE_BIGGER_ERROR,
  DASHBOARD_GRAPH_INIT,
} from '../../../utilities/messages';
import OperationLeyModal from '../../../components/operationLeyModal';
import { buscarOperacionesLey, grabarDatosOperacionesLey, grabarSnTutorial } from '../../../services/proveedor/personServices';
import LineaWhatsAppModal from '../../../components/modalLineaWhatsApp';

const { Title } = Typography;

function getRandomArray(numItems) {
  let names = [
    'ENERO',
    'FEBRERO',
    'MARZO',
    'ABRIL',
    'MAYO',
    'JUNIO',
    'JULIO',
    'AGOSTO',
    'SEPTIEMBRE',
    'OCTUBRE',
    'NOVIEMBRE',
    'DICIEMBRE',
  ];
  let data = [];
  for (var i = 0; i < numItems; i++) {
    data.push({
      label: names[i],
      value: Math.round(10 + 80 * Math.random()),
    });
  }
  return data;
}

function getRandomArray2(numItems) {
  let names = [
    'NUTRESA',
    'BANCOLOMBIA',
    'FAMILIA',
    'FAMILIA ECUADOR',
    'GASEOSAS LUX',
    'OTROS',
  ];
  let data = [];
  for (var i = 0; i < numItems; i++) {
    data.push({
      label: names[i],
      value: Math.round(10 + 80 * Math.random()),
    });
  }
  return data;
}

function getData() {
  let data = [];

  data.push({
    title: 'Millones',
    data: getRandomArray(6),
  });

  data.push({
    title: 'Empresas',
    data: getRandomArray2(4),
  });

  return data;
}

function handleMenuClick(e) {
  message.info('Click on menu item.');
}

const columns = [
  {
    title: 'NOMBRE',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'FACTURAS',
    dataIndex: 'invoices',
    key: 'invoices',
  },
  {
    title: 'NOTAS',
    dataIndex: 'notes',
    key: 'notes',
  },
  {
    title: 'TOTAL',
    dataIndex: 'total',
    key: 'total',
  },
];

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: getData(),
      modalVisible: true,
      modalRadian: true,
      modalCobranza:true,
      modalTelefonia: true,
      showModal: false,
      dataSource: [],
      espectedFlow: {
        data: [],
        min: 0,
        max: 0,
      },
      montoPersonalizado: 0,
      snAceptoMedios:'',
      operacionesLey:false,
      snTutorial:''
    };

    //Radian pop up
    this.timeInterval = null;
    let nextTarget = this.figureOutNextTargetDate();
    let targetDate = new Date(
      nextTarget.year,
      nextTarget.month,
      nextTarget.day
    );
    this.targetDate = targetDate.getTime();
  }

  modalClose = () => {
    const { authtoken } = this.props.authentication;
    const scProveedor = store.getState().billing.company;
    grabarSnTutorial(authtoken, scProveedor).then((response) => {
      this.setState({
        ...this.state,
        snAceptoMedios: response.data.snAceptoMedios,
      });
      notification.success({
        message: 'Enviado',
        description: 'Datos Guardados',
      });
    });
    this.setState({
      ...this.state,
      showModal: false,
    });
  };

  modalShowTutorial = () => {
    let localModalSetting = localStorage.getItem('welcome-tutorial-modal');
    if (!localModalSetting) {
      this.setState({
        ...this.state,
        showModal: true,
      });
    }
  };

  componentDidMount() {
    this.props.menuHandler('1');
    this.modalShowTutorial();
    const scProveedor = store.getState().billing.company;
    if (scProveedor) {
      this.loadGraph(scProveedor);
      this.loadTable(scProveedor);
      const authtoken = store.getState().auth.auth.access_token;
      this.props.loadReportSummaryAction(authtoken, scProveedor);
    }
  }

  //Solo para radian pop up
  componentWillUnmount() {
    clearInterval(this.timeInterval);
  }

  loadGraph(scProveedor) {
    const token = store.getState().auth.auth.access_token;

    flujoDeCajaEsperado(token, scProveedor).then((response) => {
      if (response.data.length > 0) {
        var flow = [];

        const groups = response.data.reduce((acc, date) => {
          var momentDate = moment(date.feEstimadaPago);
          const yearWeek = `${momentDate.format('MMMM')} ${momentDate
            .startOf('isoWeek')
            .format('DD')}-${momentDate.endOf('isoWeek').format('DD')}`;
          if (!acc[yearWeek]) {
            acc[yearWeek] = [];
          }
          acc[yearWeek].push(date);
          return acc;
        }, []);

        let groupsArray = Object.values(groups);
        let groupsNamesArray = Object.keys(groups);

        for (let index = 0; index < groupsArray.length; index++) {
          flow.push({
            value: groupsArray[index].reduce(
              (acc, item) => (acc += item.totalVrFacturas),
              0
            ),
            label: groupsNamesArray[index],
          });
        }
        this.setState({
          ...this.state,
          espectedFlow: {
            data: flow,
          },
        });
      } else {
        this.setState({
          ...this.state,
          espectedFlow: {
            data: [],
            min: 0,
            max: 0,
          },
        });
      }
    });
  }

  loadTable(company) {
    const { authtoken } = this.props.authentication;
    facturasProveedorPorCompania(authtoken, company).then((response) => {
      let dataSource = [];
      if (Array.isArray(response.data)) {
        dataSource = response.data.map((item, index) => {
          return {
            key: index + 1,
            name: item.nombrePagador,
            invoices: item.totalNmFacturas,
            notes: item.totalNmNotas,
            total:
              '$ ' +
              (item.totalVrfacturas - item.totalVrNotas).toLocaleString(),
          };
        });
      }

      this.setState({
        ...this.state,
        dataSource: dataSource,
      });
    });
  }

  setmodalVisible(modalVisible) {
    this.setState({ modalVisible });
  }

  setmodalRadian(modalRadian) {
    this.setState({ modalRadian });
  }
  setmodalCobranza(modalCobranza) {
    this.setState({ modalCobranza });
  }
  setmodalTelefonia(modalTelefonia) {
    this.setState({ modalTelefonia });
  }

  componentDidUpdate(props, state) {
    if (this.props.authentication.company != props.authentication.company) {
      const { authtoken, company } = this.props.authentication;
      this.loadTable(company);
      this.loadGraph(company);
      this.props.loadReportSummaryAction(authtoken, company);
      if(!state.operacionesLey){
        this.setState({
          ...this.state,
        operacionesLey:true
      });
      this.handleOnModalCobranza();
      }
    }
  }

  redirect(route, isAll = false) {
    if (route === 'chargeall' && (this.state.montoPersonalizado > 0 || isAll)) {
      if (
        this.state.montoPersonalizado <
        this.props.billingSummary.totalVrfacturas -
          this.props.billingSummary.totalVrNotas
      ) {
        this.props.setNeededAmount(this.state.montoPersonalizado);
        history.push(`${process.env.PUBLIC_URL}/${route}`);
      } else {
        notification.warning({
          message: 'Atención',
          description: CHARGEALL_VALUE_BIGGER_ERROR,
        });
      }
    } else if (route === 'chargeall' && this.state.montoPersonalizado <= 0) {
      notification.warning({
        message: 'Atención',
        description: 'Debes indicar cuánto dinero necesitas',
      });
    } else {
      history.push(`${process.env.PUBLIC_URL}/${route}`);
    }
  }

  montoPersonalizado = (e) => {
    this.setState({
      ...this.state,
      montoPersonalizado: e,
    });
  };

  //Funcion temporal para el contador pop up
  figureOutNextTargetDate() {
    let current = new Date();
    let year = current.getFullYear();
    let month = 6;
    let day = 13;

    return {
      year,
      month,
      day,
    };
  }

  getTimeTill(targetTime, callback) {
    let current = new Date();
    let currentTime = current.getTime();
    let millis = targetTime - currentTime;
    let f = Math.floor;
    let seconds = f(millis / 1000);
    let minutes = f(seconds / 60);
    let hours = f(minutes / 60);
    let daysRemaining = f(hours / 24);
    let hoursRemaning = hours % 24;
    let minutesRemaning = minutes % 60;
    let secondsRemaning = seconds % 60;

    let timeRemaing = {
      days: daysRemaining,
      hours: hoursRemaning,
      minutes: minutesRemaning,
      seconds: secondsRemaning,
    };
    callback(timeRemaing);
  }

  handleOnFinishModalCobranza = (formValue) => {
    const { authtoken } = this.props.authentication;
    const scProveedor = store.getState().billing.company;
    
    const dataMedio = Object.fromEntries(
      Object.entries(formValue).filter(([key]) => key !== 'cdEnviarOfertas').map(([key, value]) => [key, value ? 'S' : 'N'])
      );

    const NoSeleccionado = Object.values(dataMedio).every(value => value === 'N');
    const Seleccionado = Object.values(dataMedio).some(value => value === 'S');

    const dataAccept = Object.fromEntries(
      Object.entries(formValue).filter(([key]) => key === 'cdEnviarOfertas').map(([key, value]) => [key, value ? 'S' : 'N'])
      );

    if (formValue.numeroCelular.trim() !== '') {
      dataMedio.numeroCelular = formValue.numeroCelular.trim();
    }
  
    const data={
      "cdEnviarOfertas": dataAccept.cdEnviarOfertas,
      "cdEmail": dataMedio.cdEmail,
      "cdTelefono": dataMedio.cdTelefono,
      "cdWhatsapp": dataMedio.cdWhatsapp,
      "numeroCelular": dataMedio.numeroCelular,
    }


    if (NoSeleccionado && !Seleccionado) {
      notification.warning({
            message: 'Seleccione un medio',
            description: 'Por favor seleccione un medio',
          });
    } else {
      grabarDatosOperacionesLey(authtoken, scProveedor, data).then((response) => {
          this.setState({
            ...this.state,
            snAceptoMedios: response.data.snAceptoMedios,
          });
          notification.success({
            message: 'Enviado',
            description: 'Datos Guardados',
          });
        });
      }
    }

  handleOnModalCobranza = () => {
    const { authtoken } = this.props.authentication;
    const scProveedor = store.getState().billing.company;
    buscarOperacionesLey(authtoken, scProveedor).then((response) => {
      this.setState({
        ...this.state,
        snAceptoMedios: response.data.snAceptoMedios,
        snTutorial:response.data.snTutorial
      });
    });
  }

  notificacionModalCobranza  = () => {
   return( 
    notification.warn({
      message: 'Envia la informacion',
      description: 'Selecciona los medios de contacto para recibir información de cobro y publicitaria.',
      duration: 4,
    })
   );
  }

  render() {
    return (
      <div className='content-margin content-margin-2 all-height'>
        <Row gutter={16} className='dash-card-height'>
          <Col span={12}>
            <Card className='dash-card-margin card-shadow card-radius dash-card-margin-top'>
              <Row className='dash-greeting' justify='center'>
                <p>
                  <span className='dash-greeting-highlight'>
                    ¡Hola{' '}
                    {capitalize(
                      store
                        .getState()
                        .auth.auth.name.split(' ')[0]
                        .toLowerCase()
                    ).trim()}
                    !
                  </span>{' '}
                  Bienvenido a Affirmatum
                </p>
              </Row>
              <Row justify='center'>
                <Col span={12} className='dash-text-center'>
                  <img src={avatar} alt=''></img>
                </Col>
                <Col span={12} className='dash-how-much'>
                  <label>¿Cuánto dinero necesitas?</label>
                  <InputNumber
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={this.montoPersonalizado}
                  />
                  <Button
                    onClick={() => this.redirect('chargeall')}
                    type='primary'
                    className='btn-radius'
                  >
                    Calcular facturas
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={4} className='dash-card-center dash-card-margin-top'>
            <div className='dash-mini-card bkg-green'>
              <p>Tienes</p>
              <p className='text-no-wrap'>{`$ ${this.props.billingSummary.totalVrfacturas.toLocaleString()}`}</p>
              <p className='text-droped'>
                en {this.props.billingSummary.totalNmFacturas} facturas
              </p>
            </div>
          </Col>
          <Col span={4} className='dash-card-center dash-card-margin-top'>
            <div className='dash-mini-card bkg-blue'>
              <p>Tienes</p>
              <p className='text-no-wrap'>{`$ ${this.props.billingSummary.totalVrNotas.toLocaleString()}`}</p>
              <p className='text-droped'>
                en {this.props.billingSummary.totalNmNotas} notas crédito
              </p>
            </div>
          </Col>
          <Col span={4} className='dash-card-center dash-card-margin-top'>
            <div className='dash-mini-card bkg-purple'>
              <p className='dashNewInvoices'>
                {this.props.billingSummary.totalFacturasNuevas}
              </p>
              <p className='text-droped'>Facturas nuevas</p>
            </div>
          </Col>
        </Row>
        <Row className='multiCardsRow topMargin' gutter={16}>
          <Col span={12}>
            <Card className='card-shadow card-radius dash-card-margin-top'>
              <Row>
                <h1 className='card-title'>Tu flujo de caja esperado</h1>
              </Row>
              <LineChart
                data={this.state.espectedFlow.data}
                min={this.state.espectedFlow.min}
                max={this.state.espectedFlow.max}
                color='#09D96D'
                fill='false'
                title={'Valores por semana'}
              />
              <Row>
                <p style={{ marginBottom: '0' }}>{DASHBOARD_GRAPH_INIT}</p>
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card className='card-shadow card-radius dash-card-margin-top'>
              <h1 className='card-title'>Compañías</h1>
              <Table
                dataSource={this.state.dataSource}
                columns={columns}
                size='small'
                pagination={{ pageSize: 4 }}
              />
            </Card>
          </Col>
        </Row>
        <Row className='topMargin dash-buttons' gutter={16}>
          <Col span={12}>
            <Button
              type='primary'
              onClick={() => this.redirect('tracking')}
              block
              className='dash-bottom-button1 dash-card-margin-top'
            >
              <ClockCircleOutlined className='dash-button-icon' />
              Tracking
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type='primary'
              onClick={() => this.redirect('reports/center')}
              block
              className='dash-bottom-button2 dash-card-margin-top'
            >
              <BarChartOutlined className='dash-button-icon' />
              Informes
            </Button>
          </Col>
        </Row>
        <Modal
          className='dash-modal'
          centered
          visible={this.state.modalVisible}
          onOk={() => this.setmodalVisible(false)}
          onCancel={() => this.setmodalVisible(false)}
          footer={null}
          width={768}
        >
          <Row>
            <Col span={12} className='dash-modal-gray-col'>
              <Row
                align='bottom'
                justify='center'
                className='dash-modal-height1'
              >
                <Title level={3} className='dash-pop-title-color'>
                  ¿Cuánto dinero necesitas hoy?
                </Title>
              </Row>
              <Row
                justify='center'
                align='bottom'
                className='dash-modal-height2'
              >
                <Col span={12}>
                  <InputNumber
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={this.montoPersonalizado}
                  />
                </Col>
              </Row>
              <Row align='bottom' justify='center'>
                <p>{DASHBOARD_MODAL_INIT_WITH_VALUE}</p>
              </Row>
              <Row align='bottom' justify='center'>
                <Button
                  type='primary'
                  onClick={() => this.redirect('chargeall')}
                  className='btn-radius dash-margin-b'
                >
                  Calcular facturas
                </Button>
              </Row>
            </Col>
            <Col span={12} className=''>
              <Row gutter={16} className='dash-card-height dash-pop-padding'>
                <Col span={12} className='dash-card-center'>
                  <div className='dash-mini-card bkg-green'>
                    <p>Tienes</p>
                    <p className='text-no-wrap'>{`$ ${this.props.billingSummary.totalVrfacturas.toLocaleString()}`}</p>
                    <p className='text-droped'>
                      en {this.props.billingSummary.totalNmFacturas} facturas
                    </p>
                  </div>
                </Col>
                <Col span={12} className='dash-card-center'>
                  <div className='dash-mini-card bkg-blue'>
                    <p>Tienes</p>
                    <p className='text-no-wrap'>{`$ ${this.props.billingSummary.totalVrNotas.toLocaleString()}`}</p>
                    <p className='text-droped'>
                      en {this.props.billingSummary.totalNmNotas} notas crédito
                    </p>
                  </div>
                </Col>
              </Row>
              <Row justify='center'>
                <Title level={4}>Para un total de:</Title>
              </Row>
              <Row
                justify='center'
                className='dash-text-center dash-pop-p-center'
              >
                <Title className='for-total' level={2}>
                  ${' '}
                  {(
                    this.props.billingSummary.totalVrfacturas -
                    this.props.billingSummary.totalVrNotas
                  ).toLocaleString()}
                </Title>
                <p>{DASHBOARD_MODAL_INIT}</p>
              </Row>
              <Row align='bottom' justify='center'>
                <Button
                  type='primary'
                  onClick={() => this.redirect('chargeall', true)}
                  className='btn-radius'
                >
                  Cobrar Todo
                </Button>
              </Row>
            </Col>
          </Row>
        </Modal>
        <LineaWhatsAppModal showModalLineaWhatsApp={true}/>
        {this.state.snAceptoMedios === 'N' && (
          <Modal
          visible={this.state.modalCobranza}
          onCancel={() => this.notificacionModalCobranza(false)}
          footer={null}
          width={768}
        >
          <Row>
                  <OperationLeyModal onFinish={this.handleOnFinishModalCobranza}></OperationLeyModal>
          </Row>
        </Modal>
        )}
        {this.state.snTutorial === 'N' && (
        <Tutorial showModal={this.state.showModal} onClose={this.modalClose} />
        )}
      </div>
    );
  }
}
Dashboard.propTypes = {
  authentication: PropTypes.object.isRequired,
  billingSummary: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authentication: {
    authtoken: state.auth.auth.access_token,
    company: state.billing.company,
  },
  billingSummary: state.reports.billingSummary,
});

export default connect(mapStateToProps, {
  setNeededAmount,
  loadReportSummaryAction,
})(Dashboard);
