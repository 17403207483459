import React, { useEffect, useRef, useState } from 'react';
import { length, totalLength } from '../../../../utilities/validate/textValidate';
import { useDispatch, useSelector } from "react-redux";
import { Spin, Form, Col, Row, Input, Select, DatePicker, Button, Checkbox, notification, InputNumber } from 'antd';
import {
  emailPattern,
  requiredValueMessage,
  formatValueMessage,
  maxValueMessage
} from '../../../../utilities/admin_validation_messages';
import './formRequest.scss';
import {
  currencyInputFormat,
  currencyInputParser,
} from '../../../../utilities/textTools';
import * as numberValidate from '../../../../utilities/validate/numberValidate';
import { buscarPagadores, guardarSolicitud } from '../../../../services/proveedor/requestServices';
import PoliticaTratamientoDatosModal from '../../../vinculacion/modalPoliticaDatosPersonales';


const SELECT_PLACE_HOLDER = 'Seleccionar...';

const rules = {
  montoSolicitud: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 1, 25),
    }
  ],
  ventasAño: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 1, 25),
    },
  ],
  volumenFactura: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 1, 25),
    },
  ],
  checkboxes: [{ required: true, message: requiredValueMessage() }],
  scClientes: [{ required: true, message: requiredValueMessage() }]
};

export const FormRequest = (props) => {

  const [form] = Form.useForm();
  const [producto, setProducto] = useState('');
  const [loading, setLoading] = useState(false);
  const [pagadorSelect, setPagadoresSelect] = useState([]);
  const [pagadoresSelectFiltered, setPagadoresSelectFiltered] = useState([]);
  const token = useSelector(state => state.auth.auth.access_token);
  const scProveedor = useSelector(state => state.billing.company);
  const formRef = useRef(null);
  const [readMode, setReadMode] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);


  const filterPagadoresConfirming = (data) => {

    let pagadoresConfirming = [];
    for (let i = 0; i < data.length; i++) {
      let arrayTypes = data[i].type?.split("¬");
      if (arrayTypes !== undefined && arrayTypes !== null) {
        arrayTypes.map((tipo) => {
          if (tipo === 'C') {
            pagadoresConfirming.push(data[i]);
          }
        })
      }
    }
    setPagadoresSelectFiltered(pagadoresConfirming);

  }

  const filterPagadoresFactoring = (data) => {

    let pagadoresFactoring = [];
    for (let i = 0; i < data.length; i++) {
      let arrayTypes = data[i].type?.split("¬");
      if (arrayTypes !== undefined && arrayTypes !== null) {
        arrayTypes.map((tipo) => {
          if (tipo === 'F') {
            pagadoresFactoring.push(data[i]);
          }
        })
      }
    }
    setPagadoresSelectFiltered(pagadoresFactoring);

  }

  const getclientes = async () => {
    try {
      const res = await buscarPagadores(token);
      if (props.tipoSolicitud === 'CSR') {
        filterPagadoresConfirming(res.data);
      } else {
        filterPagadoresFactoring(res.data);
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: "Error listando pagadores: " + error,
        duration: 4,
      })
    }
  }

  const handleSubmit = async (formValue) => {


    if (props.tipoSolicitud !== 'CSR') {
      const checkboxes = ['cdCentralesRiesgo', 'cdDatosPersonales', 'cdTermYcond']
      const isChecked = checkboxes.every((checkbox) => formValue[checkbox]);

      if (!isChecked) {
        notification.warning({
          message: 'Alerta',
          description: 'Para continuar es necesario aceptar todos los términos y condiciones.',
          duration: 4,
        });
        return;
      }
    }

    if (parseInt(formValue.nmVentasAnuales) <= parseInt(formValue.nmVolumenFactura)) {
      notification.warning({
        message: 'Alerta',
        description: 'El valor de ventas anuales debe ser mayor al promedio de facturación mensual',
        duration: 4,
      });
      return;
    }

    if (formValue.cdCentralesRiesgo === true) formValue.cdCentralesRiesgo = 'S';
    if (formValue.cdDatosPersonales === true) formValue.cdDatosPersonales = 'S';
    if (formValue.cdTermYcond === true) formValue.cdTermYcond = 'S';

    formValue["cdTipoSolicitud"] = props.tipoSolicitud;

    try {
      setLoading(true);
      const res = await guardarSolicitud(token, scProveedor, formValue);
      props.setscSolicitud(res.scSolicitud);
      notification.success({
        message: "Solicitud guardada con éxito",
        duration: 4,
      });
      props.setState('PD');
    } catch (error) {
      notification.error({
        message: 'Error',
        description: "Error guardando solicitud: " + error,
        duration: 4,
      })
    } finally {
      setLoading(false);
    }

  }

  const setFormValue = (data) => {
   formRef.current.setFieldsValue({
      ...data,
      cdCentralesRiesgo: data.cdCentralesRiesgo === 'S' ? true : false,
      cdDatosPersonales: data.cdDatosPersonales === 'S' ? true : false,
      cdTermYcond: data.cdTermYcond === 'S' ? true : false
    });
  };

  useEffect(() => {
    if (props.readMode) {
      setReadMode(true);
    } else {
      getclientes();
    }
    if (props.recordData) {
      setFormValue(props.recordData);
    }

  }, [props.recordData]);

  return (
    <div className='header-container'>
      <Spin tip='Cargando...' spinning={loading}>
        <Form
          name='content_form'
          layout='vertical'
          className='filters-container'
          form={form}
          onFinish={handleSubmit}
          ref={formRef}

        >
          {(props.tipoSolicitud === 'FA' || props.tipoSolicitud === 'CCR' || props.tipoSolicitud === 'RC') && (props?.recordData?.cdOrigenSol === 'S' && props.readMode !== undefined ) ?
            <>
              <Row gutter={16}>
                <Col flex={'auto'}>
                  <Form.Item
                    name='nmVolumenFactura'
                    label='¿Cuál es el valor promedio de tu facturación mensual?'
                    rules={rules['volumenFactura'] || ''}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      min={0}
                      formatter={(value) => currencyInputFormat(value)}
                      parser={(value) => currencyInputParser(value)}
                      stringMode={true}
                      placeholder="Volumen promedio de facturación mensual"
                      disabled={readMode}
                    />
                  </Form.Item>
                </Col>
                <Col flex={'auto'}>
                  <Form.Item
                    name='nmVentasAnuales'
                    label='¿Cuál fue el valor de tus ventas en el último año?'
                    rules={rules['ventasAño'] || ''}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      min={0}
                      formatter={(value) => currencyInputFormat(value)}
                      parser={(value) => currencyInputParser(value)}
                      stringMode={true}
                      placeholder="Valor de ventas año actual"
                      disabled={readMode}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col flex={'auto'}>
                  <Form.Item
                    name='montoSolicitar'
                    label='¿Cuál es el monto a solicitar?'
                    rules={rules['montoSolicitud'] || ''}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      min={0}
                      formatter={(value) => currencyInputFormat(value)}
                      parser={(value) => currencyInputParser(value)}
                      stringMode={true}
                      placeholder="Monto a solicitar"
                      disabled={readMode}
                    />
                  </Form.Item>
                </Col>
              </Row>

            </> : null}

          {(props?.recordData?.cdOrigenSol === 'S' && props.readMode !== undefined) ?
            <Row gutter={16}>
              <Col flex={'auto'}>
                <Form.Item
                  name='scClientes'
                  label='Selecciona tus clientes (Selecciona: Otros, si tu cliente no está en la lista)'
                  rules={rules['scClientes']}
                >
                  <Select
                    placeholder={SELECT_PLACE_HOLDER}
                    mode='multiple'
                    allowClear
                    style={{ width: '100%' }}
                    disabled={readMode}
                    filterOption={(input, option) => {
                      return (
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {pagadoresSelectFiltered &&
                      pagadoresSelectFiltered.map((grupo) => (
                        <Select.Option key={grupo.codigo} value={grupo.codigo}>
                          {grupo.descripcion}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row> : null
          }


          {(props.tipoSolicitud === 'FA' || props.tipoSolicitud === 'CCR' || props.tipoSolicitud === 'RC') && (props?.recordData?.cdOrigenSol === 'S' && props.readMode !== undefined) ?
            <div className="checkboxes">
              <Col span={20} >
                <Row gutter={16}>
                  <div className='check'>
                    <Form.Item
                      name='cdCentralesRiesgo'
                      valuePropName='checked'
                      rules={rules['checkboxes'] || ''}
                    >
                      <Checkbox className='tax-info-check' disabled={readMode}>
                        Autorizo
                        <a
                          href='https://resources-affirmatum.s3.amazonaws.com/CentralesRiesgo.pdf'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {' '}
                          Consulta y reporte en centrales de riesgo
                        </a>
                      </Checkbox>
                    </Form.Item>
                  </div>
                </Row>

                <Row gutter={16}>
                  <div className='check'>
                    <Form.Item
                      name='cdDatosPersonales'
                      valuePropName='checked'
                      rules={rules['checkboxes'] || ''}
                    >
                      <Checkbox className='tax-info-check' disabled={readMode}>
                        Autorizo
                        <a href="#" onClick={() => { setModalOpen(true) }}>
                          {' '}
                          Tratamiento de datos personales
                        </a>
                      </Checkbox>
                    </Form.Item>
                    <PoliticaTratamientoDatosModal open={modalOpen} onClose={() => setModalOpen(false)} />
                  </div>
                </Row>

                <Row gutter={16}>
                  <div className='check'>
                    <Form.Item
                      name='cdTermYcond'
                      valuePropName='checked'
                      rules={rules['checkboxes'] || ''}
                    >
                      <Checkbox className='tax-info-check' disabled={readMode}>
                        Acepto los
                        <a
                          href='https://www.exponencialconfirming.com/condiciones-de-uso-del-sistema-affirmatum/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {' '}
                          Términos y condiciones
                        </a>
                      </Checkbox>
                    </Form.Item>
                  </div>
                </Row>
              </Col>
            </div> : null}
          {props.readMode !== true ?
            <Row justify='center'>
              <Col span={8}>
                <Form.Item name='submit'>
                  <Button
                    htmlType='submit'
                    className='btn btn-green'
                    type='primary'
                    disabled={readMode}
                  >
                    Continuar
                  </Button>
                </Form.Item>
              </Col>
            </Row> : props.readMode === true && props?.recordData?.cdOrigenSol === 'VDS' ?
              <>
                <h3>Su solicitud de <b>{props?.recordData?.dsTipoSolicitud}</b> se encuentra en estado : <b>{props?.recordData?.dsEstado} </b> </h3>
              </>
              : null}
        </Form>
      </Spin>
    </div>
  );
};


