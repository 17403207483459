import React, { useState, useEffect } from "react";
import NpsModal from "../npsModal/npsModal";
import { obtenerPreguntaNps } from "../../../services/nps/npsService";
import { useSelector } from "react-redux";
import { notification } from "antd";

const NpsQuestion = ({
  scCompania,
  tipoPregunta,
  visible,
  onClose,
  idSolicitud
}) => {
  const [npsData, setNpsData] = useState([]);
  const authToken = useSelector((state) => state.auth.auth.access_token);
  const buscarNpsPregunta = async () => {
    try {
      const response = await obtenerPreguntaNps(authToken, {
        scCompania,
        titulo: tipoPregunta
      });
      if (response) {
        setNpsData(response.data);
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: error,
        duration: 5
      });
    }
  };

  useEffect(() => {
    buscarNpsPregunta();
  }, []);

  return (
    <>
      {visible && npsData.length > 0 && (
        <NpsModal
          visible={visible}
          onClose={onClose}
          preguntas={npsData}
          scCompania={scCompania}
          authToken={authToken}
          idSolicitud={idSolicitud}
        />
      )}
    </>
  );
};

export default NpsQuestion;
