import { Card, Col, Modal, notification, Row, Spin, Tabs } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import AffitokenValidator from '../../../components/affitokenValidator/affitokenValidator';
import masterServices from '../../../services/proveedor/masterServices';
import * as personServices from '../../../services/proveedor/personServices';
import { consultaTiposActividades } from '../../../services/proveedor/personServices';
import '../../../styles/standard.scss';
import {
  DATE_POST_FORMAT,
  PERSON_SUBTITLE_INIT,
  PERSON_TITLE_INIT,
} from '../../../utilities/messages';
import GeneralEntityData from './juridica/generalEntityData/generalEntityData';
import Legal from './juridica/legal/legal';
import PersonEntity from './juridica/personEntity/personEntity';
import Shareholders from './juridica/shareholders/shareholders';
import CommercialReferences from './natural/commercialReferences/commercialReferences';
import FinancialNatural from './natural/financial/financialNatural';
import ForeignCurrencyOperations from './natural/foreignCurrencyOperations/foreignCurrencyOperations';
import GeneralNatural from './natural/generalNatural/generalNatural';
import PersonalReferences from './natural/personalReferences/personalReferences';
import PersonNatural from './natural/personNatural/personNatural';
import TributaryNatural from './natural/tributary/tributaryNatural';
import Tutor from './natural/tutor/tutor';
import './person.scss';
import moment from 'moment';
import FinalBeneficiaries from './juridica/finalBeneficiaries/finalBeneficiaries';
import DirectorsBoard from './juridica/directorsBoard/directorsboard';

const initialState = {
  activeTab: '1',
  ciudadesEleboraSelect: {
    fetching: false,
    options: [],
  },
  actividadSelect: {
    fetching: false,
    options: [],
  },
  paises: [],
  departamentosEntidad: { options: [], fetching: false },
  municipiosEntidad: { options: [], fetching: false },
  departamentosRecidenciaLeg: { options: [], fetching: false },
  municipiosRecidenciaLeg: { options: [], fetching: false },
  departamentosResidencia: { options: [], fetching: false },
  municipiosResidencia: { options: [], fetching: false },
  departamentosTutorNac: { options: [], fetching: false },
  municipiosTutorNac: { options: [], fetching: false },
  departamentosTutorRes: { options: [], fetching: false },
  municipiosTutorRes: { options: [], fetching: false },
  departamentosExt: { options: [], fetching: false },
  municipiosExt: { options: [], fetching: false },
  loading: true,
  visibleModal: false,
  person: {},
  formValue: undefined,
};

class Person extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
  }

  handleOnFinish = async (nextTab, value) => {
    const { formValue } = this.state;

    this.setState({
      ...this.state,
      activeTab: nextTab === '-1' ? this.state.activeTab : nextTab,
      visibleModal: nextTab === '-1',
      formValue: { ...formValue, ...value },
    });
  };

  handleOnCiudadesElaboraSearch = async (nombre) => {
    if (nombre === '' || nombre.length >= 3) {
      const { ciudadesEleboraSelect } = this.state;
      const { authtoken } = this.props;

      this.setState({
        ...this.state,
        ciudadesEleboraSelect: {
          options: [],
          fetching: true,
        },
      });

      try {
        const response = await masterServices.consultarMunicipios(authtoken, {
          nombre: nombre,
          conDepartamento: true,
          cdPais: '57',
          limit: 30,
        });
        this.setState({
          ...this.state,
          ciudadesEleboraSelect: {
            ...ciudadesEleboraSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          ciudadesEleboraSelect: {
            ...ciudadesEleboraSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnChangePaisEntidad = async (value) => {
    this.setState({
      ...this.state,
      departamentosEntidad: { options: [], fetching: true },
      municipiosEntidad: { options: [], fetching: false },
    });

    try {
      const departamentos = await this.getDepartamento(value);
      this.setState({
        ...this.state,
        departamentosEntidad: { options: departamentos, fetching: false },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        departamentosEntidad: { options: [], fetching: false },
      });
    }
  };

  handleOnChangePaisResidencia = async (value) => {
    this.setState({
      ...this.state,
      departamentosResidencia: { options: [], fetching: true },
      municipiosResidencia: { options: [], fetching: false },
    });

    try {
      const departamentos = await this.getDepartamento(value);
      this.setState({
        ...this.state,
        departamentosResidencia: { options: departamentos, fetching: false },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        departamentosResidencia: { options: [], fetching: false },
      });
    }
  };

  handleOnChangePaisExt = async (value) => {
    this.setState({
      ...this.state,
      departamentosExt: { options: [], fetching: true },
      municipiosExt: { options: [], fetching: false },
    });

    try {
      const departamentos = await this.getDepartamento(value);
      this.setState({
        ...this.state,
        departamentosExt: { options: departamentos, fetching: false },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        departamentosExt: { options: [], fetching: false },
      });
    }
  };

  handleOnChangePaisTutorNac = async (value) => {
    this.setState({
      ...this.state,
      departamentosTutorNac: { options: [], fetching: true },
      municipiosTutorNac: { options: [], fetching: false },
    });

    try {
      const departamentos = await this.getDepartamento(value);
      this.setState({
        ...this.state,
        departamentosTutorNac: { options: departamentos, fetching: false },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        departamentosTutorNac: { options: [], fetching: false },
      });
    }
  };

  handleOnChangePaisTutorRes = async (value) => {
    this.setState({
      ...this.state,
      departamentosTutorRes: { options: [], fetching: true },
      municipiosTutorRes: { options: [], fetching: false },
    });

    try {
      const departamentos = await this.getDepartamento(value);
      this.setState({
        ...this.state,
        departamentosTutorRes: { options: departamentos, fetching: false },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        departamentosTutorRes: { options: [], fetching: false },
      });
    }
  };

  handleOnChangePaisResidenciaLeg = async (value) => {
    this.setState({
      ...this.state,
      departamentosRecidenciaLeg: { options: [], fetching: true },
      municipiosRecidenciaLeg: { options: [], fetching: false },
    });

    try {
      const departamentos = await this.getDepartamento(value);
      this.setState({
        ...this.state,
        departamentosRecidenciaLeg: { options: departamentos, fetching: false },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        departamentosRecidenciaLeg: { options: [], fetching: false },
      });
    }
  };

  getDepartamento = async (cdPais) => {
    let { paises } = this.state;
    const { authtoken } = this.props;

    const pais = paises.find((pais) => pais.codigo === cdPais);

    let departamentos = !pais.departamentos ? [] : pais.departamentos;

    if (departamentos.length === 0) {
      const response = await masterServices.consultarDepartamentos(authtoken, {
        cdPais: cdPais,
      });
      departamentos = response.data;

      paises = paises.map((pais) => {
        return pais.codigo === cdPais ? { ...pais, departamentos } : pais;
      });
    }

    this.setState({
      ...this.state,
      paises,
    });

    return departamentos;
  };

  handleOnChangeDepartamentoEntidad = async (value, cdPais) => {
    this.setState({
      ...this.state,
      municipiosEntidad: { options: [], fetching: true },
    });

    try {
      const municipios = await this.getMunicipio(value, cdPais);

      this.setState({
        ...this.state,
        municipiosEntidad: { options: municipios, fetching: false },
      });
    } catch {
      this.setState({
        ...this.state,
        municipiosEntidad: { options: [], fetching: false },
      });
    }
  };

  handleOnChangeDepartamentoExt = async (value, cdPais) => {
    this.setState({
      ...this.state,
      municipiosExt: { options: [], fetching: true },
    });

    try {
      const municipios = await this.getMunicipio(value, cdPais);

      this.setState({
        ...this.state,
        municipiosExt: { options: municipios, fetching: false },
      });
    } catch {
      this.setState({
        ...this.state,
        municipiosExt: { options: [], fetching: false },
      });
    }
  };

  handleOnChangeDepartamentoResidenciaLeg = async (value, cdPais) => {
    this.setState({
      ...this.state,
      municipiosRecidenciaLeg: { options: [], fetching: true },
    });

    try {
      const municipios = await this.getMunicipio(value, cdPais);

      this.setState({
        ...this.state,
        municipiosRecidenciaLeg: { options: municipios, fetching: false },
      });
    } catch {
      this.setState({
        ...this.state,
        municipiosRecidenciaLeg: { options: [], fetching: false },
      });
    }
  };

  handleOnChangeDepartamentoResidencia = async (value, cdPais) => {
    this.setState({
      ...this.state,
      municipiosResidencia: { options: [], fetching: true },
    });

    try {
      const municipios = await this.getMunicipio(value, cdPais);

      this.setState({
        ...this.state,
        municipiosResidencia: { options: municipios, fetching: false },
      });
    } catch {
      this.setState({
        ...this.state,
        municipiosResidencia: { options: [], fetching: false },
      });
    }
  };

  handleOnChangeDepartamentoNac = async (value, cdPais) => {
    this.setState({
      ...this.state,
      municipiosTutorNac: { options: [], fetching: true },
    });

    try {
      const municipios = await this.getMunicipio(value, cdPais);

      this.setState({
        ...this.state,
        municipiosTutorNac: { options: municipios, fetching: false },
      });
    } catch {
      this.setState({
        ...this.state,
        municipiosTutorNac: { options: [], fetching: false },
      });
    }
  };

  handleOnChangeDepTutorRes = async (value, cdPais) => {
    this.setState({
      ...this.state,
      municipiosTutorRes: { options: [], fetching: true },
    });

    try {
      const municipios = await this.getMunicipio(value, cdPais);

      this.setState({
        ...this.state,
        municipiosTutorRes: { options: municipios, fetching: false },
      });
    } catch {
      this.setState({
        ...this.state,
        municipiosTutorRes: { options: [], fetching: false },
      });
    }
  };

  getMunicipio = async (cdDepartamento, cdPais) => {
    let { paises } = this.state;
    const { authtoken } = this.props;

    let deptos = paises.find((pais) => pais.codigo === cdPais).departamentos;

    const departamento = deptos.find(
      (departamento) => departamento.codigo === cdDepartamento
    );

    let municipios = !departamento.municipios ? [] : departamento.municipios;

    if (municipios.length === 0) {
      const response = await masterServices.consultarMunicipios(authtoken, {
        cdPais: cdPais,
        cdDepartamento: cdDepartamento,
      });
      municipios = response.data;
      deptos = deptos.map((departamento) =>
        departamento.codigo === cdDepartamento
          ? { ...departamento, municipios }
          : departamento
      );
      paises = paises.map((pais) => {
        return pais.codigo === cdPais
          ? { ...pais, departamentos: deptos }
          : pais;
      });
    }

    this.setState({
      ...this.state,
      paises,
    });

    return municipios;
  };

  handleOnActividadSearch = async (nombre) => {
    if (nombre === '' || nombre.length >= 3) {
      const { actividadSelect } = this.state;
      const { authtoken } = this.props;

      this.setState({
        ...this.state,
        actividadSelect: {
          options: [],
          fetching: true,
        },
      });

      try {
        const response = await consultaTiposActividades(authtoken, {
          descripcion: nombre,
        });
        this.setState({
          ...this.state,
          actividadSelect: {
            ...actividadSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          actividadSelect: {
            ...actividadSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  init = async () => {
    const { personType, authtoken, company } = this.props;
    const {
      paises,
      actividadSelect: { options: actividades },
      ciudadesEleboraSelect: { options: ciudades },
    } = this.state;
    // this.props.getPersonInfo(authtoken, company, personType);

    this.setState({
      ...this.state,
      loading: true,
      person: initialState.person,
    });

    let paisTemp = [...paises];
    let actividadTemp = [...actividades];
    let ciudadesTemp = [...ciudades];
    let person = initialState.person;
    try {
      if (ciudades.length === 0) {
        const ciudadResponse = await masterServices.consultarMunicipios(
          authtoken,
          {
            conDepartamento: true,
            cdPais: '57',
            limit: 30,
          }
        );
        ciudadesTemp = [...ciudadResponse.data];
      }

      if (paises.length === 0) {
        const paisesResponse = await masterServices.consultarPaises(authtoken);
        paisTemp = [...paisesResponse.data];
      }

      if (actividades.length === 0) {
        const actividadResponse = await consultaTiposActividades(authtoken);
        actividadTemp = [...actividadResponse.data];
      }

      if (personType === 'J') {
        const personResponse = await personServices.buscarFichaJuridica(
          authtoken,
          company
        );
        person = { ...personResponse };
      } else if (personType === 'N') {
        const personResponse = await personServices.buscarFichaNatural(
          authtoken,
          company
        );
        person = { ...personResponse };
      }

      this.setState({
        ...this.state,
        activeTab: initialState.activeTab,
        ciudadesEleboraSelect: {
          fetching: false,
          options: ciudadesTemp,
        },
        paises: paisTemp,
        actividadSelect: {
          options: actividadTemp,
          fetching: false,
        },
        person: person,
        loading: false,
      });
    } catch {
      this.setState({ ...this.state, loading: false });
    }
  };

  changeTab = (activeKey) => {
    if (Number(activeKey) < Number(this.state.activeTab))
      this.setState({
        ...this.state,
        activeTab: activeKey,
      });
    else {
      notification.warn({
        title: 'Alerta',
        message: 'Debes avanzar usando el boton "Siguiente"',
        duration: 4,
      });
    }
  };

  handleOk = async (e) => {
    const { authtoken, personType, affitoken, company } = this.props;
    const { person, formValue } = this.state;

    if (!affitoken.affitokenEntered || affitoken.affitokenEntered === '') {
      notification.error({
        message: 'Affitoken',
        description: 'Por favor ingrese el affitoken',
        duration: 5,
      });
      return;
    }

    const data = {
      ...person,
      ...formValue,
      affitoken: affitoken.affitokenEntered,
    };

    if (data.feElaboracion) {
      data.feElaboracion = moment(data.feElaboracion, "DD/MM/YYYY").format(DATE_POST_FORMAT);
    }

    this.setState({
      loading: true,
      visibleModal: false,
    });

    try {
      if (personType === 'J') {
        await personServices.actualizarFichaJuridica(authtoken, company, data);
      } else if (personType === 'N') {
        await personServices.actualizarFichaNatural(authtoken, company, data);
      }

      notification.success({
        title: 'Enviado',
        message: 'Actualización de datos exitosa',
        duration: 4,
      });

      this.setState({
        loading: false,
      });
    } catch {
      this.setState({
        loading: false,
      });
    }
  };

  handleCancel = (e) => {
    this.setState({
      visibleModal: false,
    });
  };

  componentDidMount() {
    this.props.menuHandler('15');
    this.init();
  }

  componentDidUpdate(props, state) {
    if (
      props.company !== this.props.company ||
      props.personType !== this.props.personType
    ) {
      this.init();
    }
  }

  render() {
    const {
      activeTab,
      ciudadesEleboraSelect,
      loading,
      paises,
      departamentosEntidad,
      municipiosEntidad,
      departamentosRecidenciaLeg,
      municipiosRecidenciaLeg,
      actividadSelect,
      visibleModal,
      departamentosResidencia,
      municipiosResidencia,
      departamentosTutorNac,
      municipiosTutorNac,
      departamentosTutorRes,
      municipiosTutorRes,
      departamentosExt,
      municipiosExt,
      person,
      formValue
    } = this.state;
    const { personType } = this.props;
    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='content-margin data-person-style'>
          <Card className='card-shadow card-radius default-border welcome-message '>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className='title'>{PERSON_TITLE_INIT}</div>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className='subtitle'>{PERSON_SUBTITLE_INIT}</div>
              </Col>
            </Row>
          </Card>
          <div className='card-background-light card-shadow radius'>
            <div className='align-center'>
              <Tabs activeKey={activeTab} onChange={this.changeTab}>
                {personType === 'J' && (
                  <>
                    <Tabs.TabPane key={1} tab='Datos Generales Entidad'>
                      <GeneralEntityData
                        person={person}
                        paises={paises}
                        departamentos={departamentosEntidad}
                        municipios={municipiosEntidad}
                        onChangePais={this.handleOnChangePaisEntidad}
                        onChangeDepartamento={
                          this.handleOnChangeDepartamentoEntidad
                        }
                        onFinish={this.handleOnFinish}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane key={2} tab='Representante legal'>
                      <Legal
                        person={person}
                        paises={paises}
                        departamentos={departamentosRecidenciaLeg}
                        municipios={municipiosRecidenciaLeg}
                        onChangePais={this.handleOnChangePaisResidenciaLeg}
                        onChangeDepartamento={
                          this.handleOnChangeDepartamentoResidenciaLeg
                        }
                        onFinish={this.handleOnFinish}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      key={3}
                      tab='Tipo de entidad y Datos Tributarios'
                    >
                      <PersonEntity
                        person={person}
                        actividadSelect={actividadSelect}
                        onActividadSearch={this.handleOnActividadSearch}
                        onFinish={this.handleOnFinish}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane key={4} tab='Accionistas'>
                      <Shareholders
                        person={person}
                        onFinish={this.handleOnFinish}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane key={5} tab='Beneficiarios finales'>
                      <FinalBeneficiaries
                        person={person}
                        onFinish={this.handleOnFinish}
                        formValue={formValue}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane key={6} tab='Junta directiva'>
                      <DirectorsBoard
                        person={person}
                        onFinish={this.handleOnFinish}
                      />
                    </Tabs.TabPane>             
                  </>
                )}
                {personType === 'N' && (
                  <>
                    <Tabs.TabPane key={1} tab='Datos personales'>
                      <PersonNatural
                        person={person}
                        onCiudadesSearch={this.handleOnCiudadesElaboraSearch}
                        ciudadesSelect={ciudadesEleboraSelect}
                        onFinish={this.handleOnFinish}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane key={2} tab='Datos generales'>
                      <GeneralNatural
                        person={person}
                        paises={paises}
                        departamentosResidencia={departamentosResidencia}
                        municipiosResidencia={municipiosResidencia}
                        departamentosEmpresa={departamentosEntidad}
                        municipiosEmpresa={municipiosEntidad}
                        onChangePaisResidencia={
                          this.handleOnChangePaisResidencia
                        }
                        onChangeDepartamentoResidencia={
                          this.handleOnChangeDepartamentoResidencia
                        }
                        onChangePaisLaboral={this.handleOnChangePaisEntidad}
                        onChangeDepartamentoLaboral={
                          this.handleOnChangeDepartamentoEntidad
                        }
                        onFinish={this.handleOnFinish}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      key={3}
                      tab='En caso de tratarse de un menor de edad'
                    >
                      <Tutor
                        person={person}
                        paises={paises}
                        departamentosNac={departamentosTutorNac}
                        municipiosNac={municipiosTutorNac}
                        departamentosRes={departamentosTutorRes}
                        municipiosRes={municipiosTutorRes}
                        onChangePaisNacimiento={this.handleOnChangePaisTutorNac}
                        onChangeDepartamentoNac={
                          this.handleOnChangeDepartamentoNac
                        }
                        onChangePaisResidencia={this.handleOnChangePaisTutorRes}
                        onChangeDepartamentoResidencia={
                          this.handleOnChangeDepTutorRes
                        }
                        onFinish={this.handleOnFinish}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane key={4} tab='Referencias comerciales'>
                      <CommercialReferences
                        person={person}
                        onFinish={this.handleOnFinish}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane key={5} tab='Referencias personales'>
                      <PersonalReferences
                        person={person}
                        onFinish={this.handleOnFinish}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane key={6} tab='Información tributaria'>
                      <TributaryNatural
                        person={person}
                        onFinish={this.handleOnFinish}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane key={7} tab='Información financiera'>
                      <FinancialNatural
                        person={person}
                        onFinish={this.handleOnFinish}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      key={8}
                      tab='Operaciones en moneda extranjera'
                    >
                      <ForeignCurrencyOperations
                        person={person}
                        paises={paises}
                        departamentos={departamentosExt}
                        municipios={municipiosExt}
                        onChangePais={this.handleOnChangePaisExt}
                        onChangeDepartamento={
                          this.handleOnChangeDepartamentoExt
                        }
                        onFinish={this.handleOnFinish}
                      />
                    </Tabs.TabPane>
                  </>
                )}
              </Tabs>
            </div>
          </div>
          <Modal
            className='affitoken-modal'
            title='Affitoken'
            visible={visibleModal}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <AffitokenValidator />
          </Modal>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authtoken: state.auth.auth.access_token,
    company: state.billing.company,
    personType: state.billing.personType,
    affitoken: state.affitoken,
  };
};

export default connect(mapStateToProps, {})(Person);
