import { ConfigProvider } from 'antd';
import 'antd/dist/antd.css';
import esES from 'antd/es/locale/es_ES';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import './App.scss';
import LoginLayout from './layouts/loginlayout';
import MainLayout from './layouts/mainlayout';
import history from './services/history';

import jwt from 'jsonwebtoken';
import moment from 'moment';
import ErrorBoundary from './components/ErrorBoundary';
import RedirectPage from './pages/login/RedirectPage';
import userService from './services/userService';
import LoginRoute from './utilities/loginRoute';
import ProtectedRoute from './utilities/protectedRoute';

class App extends React.Component {
  componentDidMount(): void {
    let token = JSON.parse(localStorage.getItem('auth-token'));
    if (token) {
      const auth = jwt.decode(token.access_token);
      const stateExpiration = auth.exp * 1000;
      let current = moment();
      let expiration = moment(stateExpiration);
      let diff = expiration.diff(current, 'millisecond');
      if (diff <= 0) {
        userService.revokeToken(auth);
      }
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <ConfigProvider locale={esES}>
          <Router history={history} basename={'/affirmatum2'}>
            <Switch>
              <LoginRoute
                path={`${process.env.PUBLIC_URL}/login`}
                component={LoginLayout}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/redirect`}
                component={RedirectPage}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/authorized`}
                component={RedirectPage}
              />
              <ProtectedRoute
                path={`${process.env.PUBLIC_URL}/`}
                component={MainLayout}
              />
              <Route path={`${process.env.PUBLIC_URL}/*`}>
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
              </Route>
            </Switch>
          </Router>
        </ConfigProvider>
      </ErrorBoundary>
    );
  }
}

export default App;
