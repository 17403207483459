import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import './reportsSagrilaft.scss';
import ReportSarlaft from './reportsSarlaft/ReportSarlaft/ReportSarlaft';
import UnusualOperations from './unusualOperations/unusualOperations/unusualOperations';

class ReportsSagrilaft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '0',
    };
  }

  changeTab = (activeKey) => {
    this.setState({
      ...this.state,
      activeTab: activeKey,
    });
  };

  render() {
    const { activeTab } = this.state;

    return (
      <div className='reports-style-facturas-descontadas'>
        <div className='align-center'>
          <Tabs activeKey={activeTab} onChange={this.changeTab}>
            <Tabs.TabPane key={0} tab='SARLAFTCOL'>
              <ReportSarlaft />
            </Tabs.TabPane>
            <Tabs.TabPane key={1} tab='OPERACIONES INUSUALES'>
              <UnusualOperations />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(ReportsSagrilaft);
