import React, { Component } from 'react';
import { Button, Col, Row, Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import PersonalReferenceModal, {
  initialValuesPersonaRef,
} from '../../../personalReferenceModal/personalReferenceModal';

const initialState = {
  visibleModal: false,
  data: initialValuesPersonaRef,
};

class PersonalReferences extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'NOMBRE Y APELLIDO',
        dataIndex: 'nombre',
      },
      {
        title: 'NRO. IDENTIFICACION',
        dataIndex: 'id',
      },
      {
        title: 'TELÉFONO',
        dataIndex: 'telefono',
      },
      {
        title: 'PARENTESCO',
        dataIndex: 'parentesco',
      },
      {
        title: 'EDITAR',
        dataIndex: '',
        render: (record) => (
          <EditOutlined
            className='info'
            onClick={() => this.handleOnOpenModal(record)}
          />
        ),
        showSorterTooltip: false,
      },
      {
        title: 'ELIMINAR',
        dataIndex: '',
        render: (record) => (
          <DeleteOutlined
            className='info'
            onClick={() => this.deleteShareholder(record)}
          />
        ),
        showSorterTooltip: false,
      },
    ];
  }

  deleteShareholder = (record) => {
    const { referenciasPersonales, onChange } = this.props;

    let temps = referenciasPersonales.filter(
      (value) => value.key !== record.key
    );

    onChange(temps);
  };

  handleOnOpenModal = (record) => {
    this.setState({ ...this.state, visibleModal: true, data: record });
  };

  handleOnFinishModal = (formValue) => {
    const { referenciasPersonales, onChange } = this.props;

    let temps = [];

    if (formValue.key && formValue.key !== '') {
      temps = referenciasPersonales.map((value) =>
        value.key === formValue.key ? formValue : value
      );
    } else {
      let uuid = uuidv4();
      temps = [{ ...formValue, key: uuid }, ...referenciasPersonales];
    }

    this.setState({
      ...this.state,
      visibleModal: false,
      data: initialValuesPersonaRef,
    });

    onChange(temps);
  };

  handleOnCloseModal = (record) => {
    this.setState({
      ...this.state,
      visibleModal: false,
      data: record,
    });
  };

  render() {
    const { visibleModal, data } = this.state;
    const { referenciasPersonales } = this.props;
    return (
      <div className='content-container'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          <Row
            gutter={[16, 16]}
            align='middle'
            justify='end'
            style={{ marginBottom: '16px' }}
          >
            <Col span={6} style={{ textAlign: 'right' }}>
              <Button
                className='btn-radius'
                type='primary'
                onClick={() => this.handleOnOpenModal(initialValuesPersonaRef)}
              >
                Agregar referencia personal
              </Button>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
            <Col flex='auto'>
              <Table
                columns={this.columnas}
                dataSource={referenciasPersonales}
                pagination={false}
              ></Table>
            </Col>
          </Row>

          <PersonalReferenceModal
            visible={visibleModal}
            data={data}
            onFinish={this.handleOnFinishModal}
            onClose={this.handleOnCloseModal}
          />
        </div>
      </div>
    );
  }
}

export default PersonalReferences;
