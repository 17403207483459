import { Button, Col, Form, notification, Row, Spin, Tabs } from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  actualizarFichaJuridica,
  actualizarFichaJuridicaPagFond,
  actualizarFichaNatural,
  buscarFichaJuridica,
  buscarFichaNatural,
  consultaTiposActividades,
} from '../../../../services/admin/personServices';
import {
  listaDepartamentos,
  listaMunicipios,
  listaPaises,
} from '../../../../services/admin/suppliersService';
import history from '../../../../services/history';
import { DATE_FORMAT, DATE_POST_FORMAT } from '../../../../utilities/messages';
import GeneralEntityData from '../../../../components/fichaCliente/juridica/generalEntityData/generalEntityData';
import Legal from '../../../../components/fichaCliente/juridica/legal/legal';
import PersonEntity from '../../../../components/fichaCliente/juridica/personEntity/personEntity';
import Shareholders from '../../../../components/fichaCliente/juridica/shareholders/shareholders';
import PersonalData from '../../../../components/fichaCliente/natural/personalData/personalData';
import GeneralPersonNaturalData from '../../../../components/fichaCliente/natural/generalPersonNaturalData/generalPersonNaturalData';
import Tutor from '../../../../components/fichaCliente/natural/tutor/tutor';
import CommercialReferences from '../../../../components/fichaCliente/natural/commercialReferences/commercialReferences';
import PersonalReferences from '../../../../components/fichaCliente/natural/personalReferences/personalReferences';
import TributaryNatural from '../../../../components/fichaCliente/natural/tributaryNatural/tributaryNatural';
import FinancialNatural from '../../../../components/fichaCliente/natural/financialNatural/financialNatural';
import ForeignCurrencyOperations from '../../../../components/fichaCliente/natural/foreignCurrencyOperations/foreignCurrencyOperations';
import './person.scss';
import { FinalBeneficiaries } from './juridica/finalBeneficiaries/finalBeneficiaries';
import { DirectorsBoard } from '../../../../components/fichaCliente/juridica/directorsBoard/directorsBoard';

const initialState = {
  activeTab: '1',
  ciudadesEleboraSelect: {
    fetching: false,
    options: [],
  },
  ciudadesNacLeg: {
    fetching: false,
    options: [],
  },
  ciudadesNac: {
    fetching: false,
    options: [],
  },
  actividadSelect: {
    fetching: false,
    options: [],
  },
  paises: [],
  departamentosEntidad: { options: [], fetching: false },
  municipiosEntidad: { options: [], fetching: false },
  departamentosRecidenciaLeg: { options: [], fetching: false },
  municipiosRecidenciaLeg: { options: [], fetching: false },
  departamentosResidencia: { options: [], fetching: false },
  municipiosResidencia: { options: [], fetching: false },
  departamentosTutorNac: { options: [], fetching: false },
  municipiosTutorNac: { options: [], fetching: false },
  departamentosTutorRes: { options: [], fetching: false },
  municipiosTutorRes: { options: [], fetching: false },
  departamentosExt: { options: [], fetching: false },
  municipiosExt: { options: [], fetching: false },
  loading: true,
  person: undefined,
  cdReteIva: false,
  cdReteIca: false,
  cdIndicaJD: true,
  cdIndicaBF: true,
  cdEntidadVigila: '',
  accionistas: [],
  beneficiariosFinales: [],
  miembrosJuntaDirectiva: [],
  snMenorEdad: false,
  referenciasComerciales: [],
  referenciasPersonales: [],
  cdSnOpMonExt: false,
  fileBFuploaded: false,
  cdCargaCertSag : false
};

class Person extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };

    this.formRef = React.createRef();
  }

  handleOnFinish = async (formValue) => {

    const {
      person,
      accionistas,
      referenciasComerciales,
      referenciasPersonales,
      miembrosJuntaDirectiva,
      beneficiariosFinales,
      fileBFuploaded,
      cdCargaCertSag,
      cdIndicaBF,
      cdIndicaJD
    } = this.state;
    const { authtoken, personType, company, companyType } = this.props;

    if (formValue.cdIndicaJD === undefined){
      if(cdIndicaJD != undefined){
        formValue.cdIndicaJD = cdIndicaJD;
      }else{
        formValue.cdIndicaJD = true;
      }
    } 

    if (formValue.cdIndicaBF === undefined){
      if(cdIndicaBF != undefined){
        formValue.cdIndicaBF = cdIndicaBF;
      }else{
        formValue.cdIndicaBF = true;
      }
    } 

    if ( personType === 'J' && companyType === 'R' && (formValue.cdIndicaJD === 'S' || formValue.cdIndicaJD === true) && miembrosJuntaDirectiva.length < 2){
      notification.error({
        message: 'Error',
        description: "Debe agregar como mínimo dos miembros de junta directiva para poder continuar con el proceso",
        duration: 5,
      });

      return;

    }

    if ( personType === 'J' && companyType === 'R' && (formValue.cdIndicaBF === 'S' || formValue.cdIndicaBF === true) && beneficiariosFinales.length === 0){
      notification.error({
        message: 'Error',
        description: "Debe agregar como mínimo un beneficiario final para poder continuar con el proceso",
        duration: 5,
      });

      return;

    }

    if( personType === 'J' && companyType === 'R' && (formValue.cdIndicaBF === 'N' || formValue.cdIndicaBF === false) && (fileBFuploaded === false && cdCargaCertSag === false) ){
      notification.error({
        message: 'Error',
        description: "Debe cargar y guardar el cértificado sagrilaft de los beneficiarios finales",
        duration: 5,
      });

      return;
    }

    const feElaboracion = formValue.feElaboracion
      ? formValue.feElaboracion.format(DATE_POST_FORMAT)
      : person.feElaboracion
      ? moment(person.feElaboracion, DATE_FORMAT).format(DATE_POST_FORMAT)
      : undefined;

    const feExpedicionLeg = formValue.feExpedicionLeg
      ? formValue.feExpedicionLeg.format(DATE_POST_FORMAT)
      : person.feExpedicionLeg
      ? moment(person.feExpedicionLeg, DATE_FORMAT).format(DATE_POST_FORMAT)
      : undefined;

    const feNacimientoLeg = formValue.feNacimientoLeg
      ? formValue.feNacimientoLeg.format(DATE_POST_FORMAT)
      : person.feNacimientoLeg
      ? moment(person.feNacimientoLeg, DATE_FORMAT).format(DATE_POST_FORMAT)
      : undefined;

    const feConsAct = formValue.feConsAct
      ? formValue.feConsAct.format(DATE_POST_FORMAT)
      : person.feConsAct
      ? moment(person.feConsAct, DATE_FORMAT).format(DATE_POST_FORMAT)
      : undefined;

    const feVigenciaAct = formValue.feVigenciaAct
      ? formValue.feVigenciaAct.format(DATE_POST_FORMAT)
      : person.feVigenciaAct
      ? moment(person.feVigenciaAct, DATE_FORMAT).format(DATE_POST_FORMAT)
      : undefined;

    /* ficha natural */
    const feExpedicion = formValue.feExpedicion
      ? formValue.feExpedicion.format(DATE_POST_FORMAT)
      : person.feExpedicion
      ? moment(person.feExpedicion, DATE_FORMAT).format(DATE_POST_FORMAT)
      : undefined;

    const feNacimiento = formValue.feNacimiento
      ? formValue.feNacimiento.format(DATE_POST_FORMAT)
      : person.feNacimiento
      ? moment(person.feNacimiento, DATE_FORMAT).format(DATE_POST_FORMAT)
      : undefined;

    const feExpTutor = formValue.feExpTutor
      ? formValue.feExpTutor.format(DATE_POST_FORMAT)
      : person.feExpTutor
      ? moment(person.feExpTutor, DATE_FORMAT).format(DATE_POST_FORMAT)
      : undefined;

    const feNacimientoTutor = formValue.feNacimientoTutor
      ? formValue.feNacimientoTutor.format(DATE_POST_FORMAT)
      : person.feNacimientoTutor
      ? moment(person.feNacimientoTutor, DATE_FORMAT).format(DATE_POST_FORMAT)
      : undefined;

    this.setState({
      loading: true,
    });

    const value = {
      ...person,
      ...formValue,
      feElaboracion,
      feExpedicion,
      feExpedicionLeg,
      feNacimientoLeg,
      feConsAct,
      feVigenciaAct,
      cdSnOpMontExt: formValue.cdSnOpMontExt ? 'S' : 'N',
      cdSnAutorretenedor: formValue.cdSnAutorretenedor ? 'S' : 'N',
      cdSnSarlaft: formValue.cdSnSarlaft ? 'S' : 'N',
      cdSnSancionado: formValue.cdSnSancionado ? 'S' : 'N',
      cdSnGmf: formValue.cdSnGmf ? 'S' : 'N',
      cdSnGranContribuyente: formValue.cdSnGranContribuyente ? 'S' : 'N',
      cdReteIva: formValue.cdReteIva ? 'S' : 'N',
      cdReteFuente: formValue.cdReteFuente ? 'S' : 'N',
      cdReteIca: formValue.cdReteIca ? 'S' : 'N',
      accionistas,
      miembrosJuntaDirectiva,
      beneficiariosFinales,
      feNacimiento,
      cdSnIndependiente: formValue.cdSnIndependiente ? 'S' : 'N',
      cdSnInfoEmail: formValue.cdSnInfoEmail ? 'S' : 'N',
      snMenorEdad: formValue.snMenorEdad ? 'S' : 'N',
      feExpTutor,
      feNacimientoTutor,
      referenciasComerciales,
      referenciasPersonales,
      cdSnOpMonExt: formValue.cdSnOpMonExt ? 'S' : 'N',
      cdIndicaBF: formValue.cdIndicaBF ? 'S' : 'N',
      cdIndicaJD: formValue.cdIndicaJD ? 'S' : 'N',
    };

    try {
      if (personType === 'J') { 
        if(companyType === 'R'){
          await actualizarFichaJuridica(authtoken, company, value);
        }else {
          let cdTipoCliente = '';
          switch (companyType) {
            case 'P':
                cdTipoCliente = 'PAG';
              break;
            case 'F':
              cdTipoCliente = 'FON';
            break;
          }
          await actualizarFichaJuridicaPagFond(authtoken, company, value, cdTipoCliente);
        }    
      } else if (personType === 'N') {
        await actualizarFichaNatural(authtoken, company, value);
      }

      notification.success({
        title: 'Enviado',
        message: 'Actualización de datos exitosa',
        duration: 4,
      });

      this.setState({
        loading: false,
      });
    } catch {
      this.setState({
        loading: false,
      });
    }
  };

  handleOnChangeCdSnOpMonExt = (value) => {
    this.setState({ ...this.state, cdSnOpMonExt: value });
  };

  handleOnChangeAccionista = (value) => {
    this.setState({ ...this.state, accionistas: value });
  };

  handleOnChangeJD = (value) => {
    this.setState({ ...this.state, miembrosJuntaDirectiva: value });
  };

  handleOnChangeBF = (value) => {
    this.setState({ ...this.state, beneficiariosFinales: value });
  };

  handleOnChangeCommRef = (value) => {
    this.setState({ ...this.state, referenciasComerciales: value });
  };

  handleOnChangePersonalRef = (value) => {
    this.setState({ ...this.state, referenciasPersonales: value });
  };

  handelOnChangeCdEntidadEstatal = (value) => {
    this.setState({ ...this.state, cdEntidadEstatal: value });
  };

  handleOnChangeCdEntididadDescentralizada = (value) => {
    this.setState({ ...this.state, cdEntidadDescentralizada: value });
  };

  handleOnChangeIndicaJD = (value) => {
    this.setState({ ...this.state, cdIndicaJD: value });
  };

  handleOnChangeIndicaBF = (value) => {
    this.setState({ ...this.state, cdIndicaBF: value });
  };

  handlefileBFUploaded = (value) => {
    this.setState({ ...this.state, fileBFuploaded: value });
  };

  handleOnChangeCdReteIva = (value) => {
    this.setState({ ...this.state, cdReteIva: value });
  };

  handleOnChangeCdReteIca = (value) => {
    this.setState({ ...this.state, cdReteIca: value });
  };

  handleOnChangeMenorEdad = (value) => {
    this.setState({ ...this.state, snMenorEdad: value });
  };

  handleOnCiudadesElaboraSearch = async (nombre) => {
    if (nombre === '' || nombre.length >= 3) {
      const { ciudadesEleboraSelect } = this.state;
      const { authtoken } = this.props;

      this.setState({
        ...this.state,
        ciudadesEleboraSelect: {
          options: [],
          fetching: true,
        },
      });

      try {
        const response = await listaMunicipios(authtoken, {
          nombre: nombre,
          conDepartamento: true,
          cdPais: '57',
          limit: 30,
        });
        this.setState({
          ...this.state,
          ciudadesEleboraSelect: {
            ...ciudadesEleboraSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          ciudadesEleboraSelect: {
            ...ciudadesEleboraSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnCiudadesNacLegSearch = async (nombre = '') => {
    if (nombre === '' || nombre.length >= 3) {
      const { ciudadesNacLeg } = this.state;
      const { authtoken } = this.props;

      this.setState({
        ...this.state,
        ciudadesNacLeg: {
          options: [],
          fetching: true,
        },
      });

      const cdPais = this.formRef.current.getFieldValue('cdPaisNacLeg');
      if (!cdPais) {
        return;
      }

      try {
        const response = await listaMunicipios(authtoken, {
          nombre: nombre,
          conDepartamento: true,
          cdPais: cdPais,
          limit: 30,
        });
        this.setState({
          ...this.state,
          ciudadesNacLeg: {
            ...ciudadesNacLeg,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          ciudadesNacLeg: {
            ...ciudadesNacLeg,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnCiudadesNacSearch = async (nombre = '') => {
    if (nombre === '' || nombre.length >= 3) {
      const { ciudadesNac } = this.state;
      const { authtoken } = this.props;

      this.setState({
        ...this.state,
        ciudadesNac: {
          options: [],
          fetching: true,
        },
      });

      const cdPais = this.formRef.current.getFieldValue('cdPaisNac');
      if (!cdPais) {
        return;
      }

      try {
        const response = await listaMunicipios(authtoken, {
          nombre: nombre,
          conDepartamento: true,
          cdPais: cdPais,
          limit: 30,
        });
        this.setState({
          ...this.state,
          ciudadesNac: {
            ...ciudadesNac,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          ciudadesNac: {
            ...ciudadesNac,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnChangePaisEntidad = async (value) => {
    this.setState({
      ...this.state,
      departamentosEntidad: { options: [], fetching: true },
      municipiosEntidad: { options: [], fetching: false },
    });

    this.formRef.current.setFieldsValue({
      cdDepEntidad: undefined,
      cdMunEntidad: undefined,
    });

    try {
      const departamentos = await this.getDepartamento(value);
      this.setState({
        ...this.state,
        departamentosEntidad: { options: departamentos, fetching: false },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        departamentosEntidad: { options: [], fetching: false },
      });
    }
  };

  handleOnChangePaisLaboral = async (value) => {
    this.setState({
      ...this.state,
      departamentosEntidad: { options: [], fetching: true },
      municipiosEntidad: { options: [], fetching: false },
    });

    this.formRef.current.setFieldsValue({
      cdDepEmpresa: undefined,
      cdMunEmpresa: undefined,
    });

    try {
      const departamentos = await this.getDepartamento(value);
      this.setState({
        ...this.state,
        departamentosEntidad: { options: departamentos, fetching: false },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        departamentosEntidad: { options: [], fetching: false },
      });
    }
  };

  handleOnChangePaisResidencia = async (value) => {
    this.setState({
      ...this.state,
      departamentosResidencia: { options: [], fetching: true },
      municipiosResidencia: { options: [], fetching: false },
    });

    this.formRef.current.setFieldsValue({
      cdDepResidencia: undefined,
      cdMunResidencia: undefined,
    });

    try {
      const departamentos = await this.getDepartamento(value);
      this.setState({
        ...this.state,
        departamentosResidencia: { options: departamentos, fetching: false },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        departamentosResidencia: { options: [], fetching: false },
      });
    }
  };

  handleOnChangePaisExt = async (value) => {
    this.setState({
      ...this.state,
      departamentosExt: { options: [], fetching: true },
      municipiosExt: { options: [], fetching: false },
    });

    this.formRef.current.setFieldsValue({
      cdDepExt: undefined,
      cdMunExt: undefined,
    });

    try {
      const departamentos = await this.getDepartamento(value);
      this.setState({
        ...this.state,
        departamentosExt: { options: departamentos, fetching: false },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        departamentosExt: { options: [], fetching: false },
      });
    }
  };

  handleOnChangePaisTutorNac = async (value) => {
    this.setState({
      ...this.state,
      departamentosTutorNac: { options: [], fetching: true },
      municipiosTutorNac: { options: [], fetching: false },
    });

    this.formRef.current.setFieldsValue({
      cdDepNacTutor: undefined,
      cdMunNacTutor: undefined,
    });

    try {
      const departamentos = await this.getDepartamento(value);
      this.setState({
        ...this.state,
        departamentosTutorNac: { options: departamentos, fetching: false },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        departamentosTutorNac: { options: [], fetching: false },
      });
    }
  };

  handleOnChangePaisTutorRes = async (value) => {
    this.setState({
      ...this.state,
      departamentosTutorRes: { options: [], fetching: true },
      municipiosTutorRes: { options: [], fetching: false },
    });

    this.formRef.current.setFieldsValue({
      cdDepResidenciaTutor: undefined,
      cdMunResidenciaTutor: undefined,
    });

    try {
      const departamentos = await this.getDepartamento(value);
      this.setState({
        ...this.state,
        departamentosTutorRes: { options: departamentos, fetching: false },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        departamentosTutorRes: { options: [], fetching: false },
      });
    }
  };

  handleOnChangePaisNacLeg = async (value) => {
    this.setState({
      ...this.state,
      ciudadesNacLeg: { options: [], fetching: false },
    });

    this.formRef.current.setFieldsValue({
      cdMunNacLeg: undefined,
    });

    this.handleOnCiudadesNacLegSearch();
  };

  handleOnChangePaisNac = async (value) => {
    this.setState({
      ...this.state,
      ciudadesNacLeg: { options: [], fetching: false },
    });

    this.formRef.current.setFieldsValue({
      cdMunNac: undefined,
    });

    this.handleOnCiudadesNacSearch();
  };

  handleOnChangePaisResidenciaLeg = async (value) => {
    this.setState({
      ...this.state,
      departamentosRecidenciaLeg: { options: [], fetching: true },
      municipiosRecidenciaLeg: { options: [], fetching: false },
    });

    this.formRef.current.setFieldsValue({
      cdDepResidenciaLeg: undefined,
      cdMunResidenciaLeg: undefined,
    });

    try {
      const departamentos = await this.getDepartamento(value);
      this.setState({
        ...this.state,
        departamentosRecidenciaLeg: { options: departamentos, fetching: false },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        departamentosRecidenciaLeg: { options: [], fetching: false },
      });
    }
  };

  getDepartamento = async (cdPais) => {
    let { paises } = this.state;
    const { authtoken } = this.props;

    const pais = paises.find((pais) => pais.codigo === cdPais);
    let departamentos = pais && pais.departamentos ? pais.departamentos : [];
    if (departamentos.length === 0) {
      const response = await listaDepartamentos(authtoken, {
        cdPais: cdPais,
      });
      departamentos = response.data;

      paises = paises.map((pais) => {
        return pais.codigo === cdPais ? { ...pais, departamentos } : pais;
      });
    }

    this.setState({
      ...this.state,
      paises,
    });

    return departamentos;
  };

  handleOnChangeDepartamentoEntidad = async (value) => {
    this.setState({
      ...this.state,
      municipiosEntidad: { options: [], fetching: true },
    });

    const cdPais = this.formRef.current.getFieldValue('cdPaisEntidad');

    this.formRef.current.setFieldsValue({
      cdMunEntidad: undefined,
    });

    try {
      const municipios = await this.getMunicipio(value, cdPais);

      this.setState({
        ...this.state,
        municipiosEntidad: { options: municipios, fetching: false },
      });
    } catch {
      this.setState({
        ...this.state,
        municipiosEntidad: { options: [], fetching: false },
      });
    }
  };

  handleOnChangeDepartamentoLaboral = async (value) => {
    this.setState({
      ...this.state,
      municipiosEntidad: { options: [], fetching: true },
    });

    const cdPais = this.formRef.current.getFieldValue('cdPaisEmpresa');

    this.formRef.current.setFieldsValue({
      cdMunEmpresa: undefined,
    });

    try {
      const municipios = await this.getMunicipio(value, cdPais);

      this.setState({
        ...this.state,
        municipiosEntidad: { options: municipios, fetching: false },
      });
    } catch {
      this.setState({
        ...this.state,
        municipiosEntidad: { options: [], fetching: false },
      });
    }
  };

  handleOnChangeDepartamentoExt = async (value) => {
    this.setState({
      ...this.state,
      municipiosExt: { options: [], fetching: true },
    });

    this.formRef.current.setFieldsValue({
      cdMunExt: undefined,
    });

    const cdPais = this.formRef.current.getFieldValue('cdPaisExt');
    if (!cdPais) {
      return;
    }

    try {
      const municipios = await this.getMunicipio(value, cdPais);

      this.setState({
        ...this.state,
        municipiosExt: { options: municipios, fetching: false },
      });
    } catch {
      this.setState({
        ...this.state,
        municipiosExt: { options: [], fetching: false },
      });
    }
  };

  handleOnChangeDepartamentoResidenciaLeg = async (value) => {
    this.setState({
      ...this.state,
      municipiosRecidenciaLeg: { options: [], fetching: true },
    });

    const cdPais = this.formRef.current.getFieldValue('cdPaisResidenciaLeg');

    this.formRef.current.setFieldsValue({
      cdMunResidenciaLeg: undefined,
    });

    try {
      const municipios = await this.getMunicipio(value, cdPais);

      this.setState({
        ...this.state,
        municipiosRecidenciaLeg: { options: municipios, fetching: false },
      });
    } catch {
      this.setState({
        ...this.state,
        municipiosRecidenciaLeg: { options: [], fetching: false },
      });
    }
  };

  handleOnChangeDepartamentoResidencia = async (value) => {
    this.setState({
      ...this.state,
      municipiosResidencia: { options: [], fetching: true },
    });

    this.formRef.current.setFieldsValue({
      cdMunResidencia: undefined,
    });

    const cdPais = this.formRef.current.getFieldValue('cdPaisResidencia');
    if (!cdPais) {
      return;
    }

    try {
      const municipios = await this.getMunicipio(value, cdPais);

      this.setState({
        ...this.state,
        municipiosResidencia: { options: municipios, fetching: false },
      });
    } catch {
      this.setState({
        ...this.state,
        municipiosResidencia: { options: [], fetching: false },
      });
    }
  };

  handleOnChangeDepartamentoNac = async (value) => {
    this.setState({
      ...this.state,
      municipiosTutorNac: { options: [], fetching: true },
    });

    this.formRef.current.setFieldsValue({
      cdMunNacTutor: undefined,
    });

    const cdPais = this.formRef.current.getFieldValue('cdPaisNacTutor');
    if (!cdPais) {
      return;
    }

    try {
      const municipios = await this.getMunicipio(value, cdPais);

      this.setState({
        ...this.state,
        municipiosTutorNac: { options: municipios, fetching: false },
      });
    } catch {
      this.setState({
        ...this.state,
        municipiosTutorNac: { options: [], fetching: false },
      });
    }
  };

  handleOnChangeDepTutorRes = async (value) => {
    this.setState({
      ...this.state,
      municipiosTutorRes: { options: [], fetching: true },
    });

    this.formRef.current.setFieldsValue({
      cdMunResidenciaTutor: undefined,
    });

    const cdPais = this.formRef.current.getFieldValue('cdPaisResidenciaTutor');
    if (!cdPais) {
      return;
    }

    try {
      const municipios = await this.getMunicipio(value, cdPais);

      this.setState({
        ...this.state,
        municipiosTutorRes: { options: municipios, fetching: false },
      });
    } catch {
      this.setState({
        ...this.state,
        municipiosTutorRes: { options: [], fetching: false },
      });
    }
  };

  getMunicipio = async (cdDepartamento, cdPais) => {
    let { paises } = this.state;
    const { authtoken } = this.props;

    const pais = paises.find((pais) => pais.codigo === cdPais);

    let deptos = pais ? pais.departamentos : [];

    const departamento = deptos.find(
      (departamento) => departamento.codigo === cdDepartamento
    );

    let municipios =
      departamento && departamento.municipios ? departamento.municipios : [];

    if (municipios.length === 0) {
      const response = await listaMunicipios(authtoken, {
        cdPais: cdPais,
        cdDepartamento: cdDepartamento,
      });
      municipios = response.data;
      deptos = deptos.map((departamento) =>
        departamento.codigo === cdDepartamento
          ? { ...departamento, municipios }
          : departamento
      );
      paises = paises.map((pais) => {
        return pais.codigo === cdPais
          ? { ...pais, departamentos: deptos }
          : pais;
      });
    }

    this.setState({
      ...this.state,
      paises,
    });

    return municipios;
  };

  handleOnActividadSearch = async (nombre) => {
    if (nombre === '' || nombre.length >= 3) {
      const { actividadSelect } = this.state;
      const { authtoken } = this.props;

      this.setState({
        ...this.state,
        actividadSelect: {
          options: [],
          fetching: true,
        },
      });

      try {
        const response = await consultaTiposActividades(authtoken, {
          descripcion: nombre,
        });
        this.setState({
          ...this.state,
          actividadSelect: {
            ...actividadSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          actividadSelect: {
            ...actividadSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  init = () => {
    const { personType } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });

    if (personType === 'J') {
      this.initJuridicaForm();
    } else if (personType === 'N') {
      this.initNaturalForm();
      /*    */
    }
  };

  initJuridicaForm = async () => {
    const { authtoken, company } = this.props;
    const {
      paises,
      actividadSelect: { options: actividades },
    } = this.state;

    let paisTemp = [...paises];
    let actividadTemp = [...actividades];
    let ciudadesElabTemp = [];
    let ciudadesNacLegTemp = [];
    let person = {};
    try {
      const personResponse = await buscarFichaJuridica(authtoken, company);
      person = { ...personResponse };

      if (paises.length === 0) {
        const paisesResponse = await listaPaises(authtoken);
        paisTemp = [...paisesResponse.data];
        this.setState({ ...this.state, paises: paisTemp });
      }

      // tab de generalres - ciudad elabora  - juridica
      let ciudadResponse = {};
      if(person.dsMunElaboracion){
        ciudadResponse = await listaMunicipios(authtoken, {
          conDepartamento: true,
          nombre: person.dsMunElaboracion ? person.dsMunElaboracion : '',
          limit: 30,
        });
      }else{
        ciudadResponse = await listaMunicipios(authtoken, {
          cdPais: '57',
          cdDepartamento: '5',
        });
      }
      ciudadesElabTemp = [...ciudadResponse.data];

      // tab de  direccion - juridica
      const departamentosEntidad = await this.getDepartamento(
        person.cdPaisEntidad ? person.cdPaisEntidad : '57',
      );

      const municipiosEntidad = await this.getMunicipio(
        person.cdDepEntidad,
        person.cdPaisEntidad ? person.cdPaisEntidad : '57'
      );

      // tab de representante legal - juridica
      let ciudadNacLegResponse = {};
      if(person.dsMunNacLeg){
        ciudadNacLegResponse = await listaMunicipios(authtoken, {
          conDepartamento: true,
          nombre: person.dsMunNacLeg ? person.dsMunNacLeg : '',
          limit: 30,
        });
      }else{
        ciudadNacLegResponse = await listaMunicipios(authtoken, {
          cdPais: '57',
          cdDepartamento: '5',
        });
      }
      
      ciudadesNacLegTemp = [...ciudadNacLegResponse.data];

      const departamentosRecidenciaLeg = await this.getDepartamento(
        person.cdPaisResidenciaLeg ? person.cdPaisResidenciaLeg : '57'
      );

      const municipiosRecidenciaLeg = await this.getMunicipio(
        person.cdDepResidenciaLeg,
        person.cdPaisResidenciaLeg ? person.cdPaisResidenciaLeg : '57'
      );

      const actividadResponse = await consultaTiposActividades(authtoken, {
        descripcion: person.dsActividad,
      });
      actividadTemp = [...actividadResponse.data];

      this.setState({
        ...this.state,
        activeTab: initialState.activeTab,
        ciudadesEleboraSelect: {
          fetching: false,
          options: ciudadesElabTemp,
        },
        ciudadesNacLeg: {
          fetching: false,
          options: ciudadesNacLegTemp,
        },
        actividadSelect: {
          options: actividadTemp,
          fetching: false,
        },
        loading: false,
        departamentosEntidad: {
          options: departamentosEntidad,
          fetching: false,
        },
        municipiosEntidad: {
          fetching: false,
          options: municipiosEntidad,
        },
        departamentosRecidenciaLeg: {
          fetching: false,
          options: departamentosRecidenciaLeg,
        },
        municipiosRecidenciaLeg: {
          fetching: false,
          options: municipiosRecidenciaLeg,
        },
        cdReteIva: person.cdReteIva === 'S',
        cdReteIca: person.cdReteIca === 'S',
        cdEntidadVigila: person.cdEntidadVigila,
        cdIndicaBF: person.cdIndicaBF === 'S' ? true : person.cdIndicaBF === undefined ? true : false,
        cdIndicaJD: person.cdIndicaJD === 'S' ? true : person.cdIndicaBF === undefined ? true : false,
        cdCargaCertSag: person.cdCargaCertSarlaft === 'S' ? true  : false,
        person: person, 
        accionistas: person.accionistas
          ? person.accionistas.map((accionista) => ({
              ...accionista,
              key: accionista.scAccionista,
            }))
          : [],
        miembrosJuntaDirectiva: person.miembrosJuntaDirectiva
        ? person.miembrosJuntaDirectiva.map((miembrosJuntaDirectiva) => ({
          ...miembrosJuntaDirectiva,
          key: miembrosJuntaDirectiva.scPersona,
        }))
        : [],
        beneficiariosFinales: person.beneficiariosFinales
        ? person.beneficiariosFinales.map((beneficiariosFinales) => ({
          ...beneficiariosFinales,
          key: beneficiariosFinales.scPersona,
        }))
        : [],
      });

      this.setFormValue(person);
    } catch (error) {
      this.setState({ ...this.state, loading: false });
    }
  };

  initNaturalForm = async () => {
    const { authtoken, company } = this.props;
    const { paises } = this.state;

    let paisTemp = [...paises];
    let ciudadesElabTemp = [];
    let ciudadesNacTemp = [];
    let person = {};
    try {
      const personResponse = await buscarFichaNatural(authtoken, company);
      person = { ...personResponse };

      if (paises.length === 0) {
        const paisesResponse = await listaPaises(authtoken);
        paisTemp = [...paisesResponse.data];
        this.setState({ ...this.state, paises: paisTemp });
      }

      // tab de generalres - ciudad elabora  - Natural
      const ciudadResponse = await listaMunicipios(authtoken, {
        conDepartamento: true,
        nombre: person.dsMunElaboracion ? person.dsMunElaboracion : '',
        limit: 30,
      });
      ciudadesElabTemp = [...ciudadResponse.data];

      // tab de datos generales - Natural
      const ciudadNacResponse = await listaMunicipios(authtoken, {
        conDepartamento: true,
        nombre: person.dsMunNac ? person.dsMunNac : '',
        cdPais: person.cdPaisNac | '57',
        limit: 30,
      });
      ciudadesNacTemp = [...ciudadNacResponse.data];

      const departamentosResidencia = await this.getDepartamento(
        person.cdPaisResidencia
      );

      const municipiosResidencia = await this.getMunicipio(
        person.cdDepResidencia,
        person.cdPaisResidencia
      );

      const departamentosEntidad = await this.getDepartamento(
        person.cdPaisEmpresa
      );

      const municipiosEntidad = await this.getMunicipio(
        person.cdDepEmpresa,
        person.cdPaisEmpresa
      );

      const departamentosTutorNac = person.cdPaisNacTutor
        ? await this.getDepartamento(person.cdPaisNacTutor)
        : [];

      const municipiosTutorNac =
        person.cdDepNacTutor && person.cdPaisNacTutor
          ? await this.getMunicipio(person.cdDepNacTutor, person.cdPaisNacTutor)
          : [];

      const departamentosTutorRes = person.cdPaisResidenciaTutor
        ? await this.getDepartamento(person.cdPaisResidenciaTutor)
        : [];

      const municipiosTutorRes =
        person.cdDepResidenciaTutor && person.cdPaisResidenciaTutor
          ? await this.getMunicipio(
              person.cdDepResidenciaTutor,
              person.cdPaisResidenciaTutor
            )
          : [];

      const departamentosExt = person.cdPaisExt
        ? await this.getDepartamento(person.cdPaisExt)
        : [];

      const municipiosExt =
        person.cdDepExt && person.cdPaisExt
          ? await this.getMunicipio(person.cdDepExt, person.cdPaisExt)
          : [];

      this.setState({
        ...this.state,
        activeTab: initialState.activeTab,
        ciudadesEleboraSelect: {
          fetching: false,
          options: ciudadesElabTemp,
        },
        ciudadesNac: {
          fetching: false,
          options: ciudadesNacTemp,
        },
        departamentosResidencia: {
          fetching: false,
          options: departamentosResidencia,
        },
        departamentosEntidad: {
          fetching: false,
          options: departamentosEntidad,
        },
        departamentosTutorNac: {
          fetching: false,
          options: departamentosTutorNac,
        },
        departamentosTutorRes: {
          fetching: false,
          options: departamentosTutorRes,
        },
        departamentosExt: {
          fetching: false,
          options: departamentosExt,
        },
        municipiosResidencia: {
          fetching: false,
          options: municipiosResidencia,
        },
        municipiosEntidad: {
          fetching: false,
          options: municipiosEntidad,
        },
        municipiosTutorNac: {
          fetching: false,
          options: municipiosTutorNac,
        },
        municipiosTutorRes: {
          fetching: false,
          options: municipiosTutorRes,
        },
        municipiosExt: {
          fetching: false,
          options: municipiosExt,
        },
        loading: false,
        person: person,
        snMenorEdad: person.snMenorEdad === 'S',
        referenciasComerciales: person.referenciasComerciales
          ? person.referenciasComerciales.map((ref) => ({
              ...ref,
              key: ref.scReferencia,
            }))
          : [],
        referenciasPersonales: person.referenciasPersonales
          ? person.referenciasPersonales.map((ref) => ({
              ...ref,
              key: ref.scReferencia,
            }))
          : [],
        cdSnGranContribuyente: person.cdSnGranContribuyente === 'S',
        cdReteIva: person.cdReteIva === 'S',
        cdReteFuente: person.cdReteFuente === 'S',
        cdReteIca: person.cdReteIca === 'S',
        cdSnOpMonExt: person.cdSnOpMonExt === 'S',
      });
      this.setFormValue(person);
    } catch (error) {
      this.setState({ ...this.state, loading: false });
    }
  };

  setFormValue = (person) => {
    this.formRef.current.setFieldsValue({
      ...person,
      /* Juridica */
      feElaboracion: person.feElaboracion
        ? moment(person.feElaboracion, DATE_FORMAT)
        : moment(),
      feExpedicionLeg: person.feExpedicionLeg
        ? moment(person.feExpedicionLeg, DATE_FORMAT)
        : '',
      feNacimientoLeg: person.feNacimientoLeg
        ? moment(person.feNacimientoLeg, DATE_FORMAT)
        : '',
      feConsAct: person.feConsAct ? moment(person.feConsAct, DATE_FORMAT) : '',
      feVigenciaAct: person.feVigenciaAct
        ? moment(person.feVigenciaAct, DATE_FORMAT)
        : '',
      cdSnOpMontExt: person.cdSnOpMontExt === 'S',
      cdSnAutorretenedor: person.cdSnAutorretenedor === 'S',
      cdSnSarlaft: person.cdSnSarlaft === 'S',
      cdSnSancionado: person.cdSnSancionado === 'S',
      cdSnGmf: person.cdSnGmf === 'S',
      cdSnGranContribuyente: person.cdSnGranContribuyente === 'S',
      cdReteIva: person.cdReteIva === 'S',
      cdReteFuente: person.cdReteFuente === 'S',
      cdReteIca: person.cdReteIca === 'S',
      /* Natural */
      feExpedicion: person.feExpedicion
        ? moment(person.feExpedicion, DATE_FORMAT)
        : '',
      feNacimiento: person.feNacimiento
        ? moment(person.feNacimiento, DATE_FORMAT)
        : '',
      cdSnIndependiente: person.cdSnIndependiente === 'S',
      cdSnInfoEmail: person.cdSnInfoEmail === 'S',
      feExpTutor: person.feExpTutor
        ? moment(person.feExpTutor, DATE_FORMAT)
        : '',
      feNacimientoTutor: person.feNacimientoTutor
        ? moment(person.feNacimientoTutor, DATE_FORMAT)
        : '',
      snMenorEdad: person.snMenorEdad === 'S',
      cdSnOpMonExt: person.cdSnOpMonExt === 'S',
      cdIndicaBF: person.cdIndicaBF === 'S' ? true : person.cdIndicaBF === undefined ? true : false,
      cdIndicaJD: person.cdIndicaJD === 'S' ? true : person.cdIndicaBF === undefined ? true : false,
    });
  };

  changeTab = (activeKey) => {
    this.setState({
      ...this.state,
      activeTab: activeKey,
    });
  };

  componentDidMount() {
    const { companyType } = this.props;
    if(companyType === 'P'){
      this.props.menuHandler('22');
    }else if (companyType === 'R') {
      this.props.menuHandler('20');
    }else if (companyType === 'F'){
      this.props.menuHandler('21');
    }
    this.init();
  }

  render() {
    const {
      activeTab,
      ciudadesEleboraSelect,
      loading,
      paises,
      departamentosEntidad,
      municipiosEntidad,
      departamentosRecidenciaLeg,
      municipiosRecidenciaLeg,
      actividadSelect,
      departamentosResidencia,
      municipiosResidencia,
      departamentosTutorNac,
      municipiosTutorNac,
      departamentosTutorRes,
      municipiosTutorRes,
      departamentosExt,
      municipiosExt,
      ciudadesNacLeg,
      cdReteIva,
      cdReteIca,
      accionistas,
      ciudadesNac,
      snMenorEdad,
      referenciasComerciales,
      referenciasPersonales,
      cdSnOpMonExt,
      beneficiariosFinales,
      miembrosJuntaDirectiva,
      cdIndicaBF,
      cdIndicaJD,
      person,
      fileBFuploaded,
      cdCargaCertSag
    } = this.state;
    const { personType,company } = this.props;
    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='content-margin data-person-style'>
          <div className='card-background-light card-shadow radius'>
            <div className='align-center'>
              <Form
                ref={this.formRef}
                name='ficha_form'
                layout='vertical'
                onFinish={this.handleOnFinish}
                onFinishFailed={({ values, errorFields, outOfDate }) => {
                  notification.warning({
                    message: 'Ficha Cliente',
                    description:
                      'Validación de campo fallida. por favor revisar los campos',
                    duration: 6,
                  });
                }}
              >
                <Row gutter={16}>
                  <Col flex='auto'>
                    <Tabs activeKey={activeTab} onChange={this.changeTab}>
                      {personType === 'J' && (
                        <>
                          <Tabs.TabPane key={1} tab='Datos Generales Entidad'>
                            <GeneralEntityData
                              viewFeElabora={true}
                              paises={paises}
                              departamentos={departamentosEntidad}
                              municipios={municipiosEntidad}
                              onChangePais={this.handleOnChangePaisEntidad}
                              onChangeDepartamento={
                                this.handleOnChangeDepartamentoEntidad
                              }
                            />
                          </Tabs.TabPane>
                          <Tabs.TabPane key={2} tab='Representante Legal'>
                            <Legal
                              paises={paises}
                              departamentos={departamentosRecidenciaLeg}
                              municipios={municipiosRecidenciaLeg}
                              ciudadesSelect={ciudadesNacLeg}
                              onChangePais={
                                this.handleOnChangePaisResidenciaLeg
                              }
                              onChangeDepartamento={
                                this.handleOnChangeDepartamentoResidenciaLeg
                              }
                              onCiudadesSearch={
                                this.handleOnCiudadesNacLegSearch
                              }
                              onChangePaisNac={this.handleOnChangePaisNacLeg}
                            />
                          </Tabs.TabPane>
                          <Tabs.TabPane
                            key={3}
                            tab='Tipo de entidad y Datos Tributarios'
                          >
                            <PersonEntity
                              actividadSelect={actividadSelect}
                              cdReteIva={cdReteIva}
                              cdReteIca={cdReteIca}
                              onActividadSearch={this.handleOnActividadSearch}
                              onChangeCdReteIva={this.handleOnChangeCdReteIva}
                              onChangeCdReteIca={this.handleOnChangeCdReteIca}
                            />
                          </Tabs.TabPane>
                          <Tabs.TabPane key={4} tab='Accionistas'>
                            <Shareholders
                              accionistas={accionistas}
                              onChange={this.handleOnChangeAccionista}
                            />
                          </Tabs.TabPane>
                          <Tabs.TabPane key={5} tab='Beneficiarios finales'>
                            <FinalBeneficiaries
                              beneficiarios={beneficiariosFinales}
                              onChange={this.handleOnChangeBF}
                              snIndicaBF={cdIndicaBF}
                              handleChangeSnIndicaBF={this.handleOnChangeIndicaBF}
                              accionistas={accionistas}
                              person={person}
                              handleFileUploaded={this.handlefileBFUploaded}
                              company={company}
                              fileBFuploaded={fileBFuploaded}
                              cargaCertsag={cdCargaCertSag}
                            />
                          </Tabs.TabPane>
                          <Tabs.TabPane key={6} tab='Junta Directiva'>
                            <DirectorsBoard
                              juntadirectiva={miembrosJuntaDirectiva}
                              onChange={this.handleOnChangeJD}
                              snIndicaJD={cdIndicaJD}
                              handleChangeSnIndicaJD={this.handleOnChangeIndicaJD}
                            />
                          </Tabs.TabPane>                    
                        </>
                      )}
                      {personType === 'N' && (
                        <>
                          <Tabs.TabPane key={1} tab='Datos personales'>
                            <PersonalData
                              onCiudadesSearch={
                                this.handleOnCiudadesElaboraSearch
                              }
                              ciudadesSelect={ciudadesEleboraSelect}
                            />
                          </Tabs.TabPane>
                          <Tabs.TabPane key={2} tab='Datos generales'>
                            <GeneralPersonNaturalData
                              paises={paises}
                              departamentosResidencia={departamentosResidencia}
                              municipiosResidencia={municipiosResidencia}
                              departamentosEmpresa={departamentosEntidad}
                              municipiosEmpresa={municipiosEntidad}
                              ciudadesNac={ciudadesNac}
                              onChangePaisResidencia={
                                this.handleOnChangePaisResidencia
                              }
                              onChangeDepartamentoResidencia={
                                this.handleOnChangeDepartamentoResidencia
                              }
                              onChangePaisLaboral={
                                this.handleOnChangePaisLaboral
                              }
                              onChangeDepartamentoLaboral={
                                this.handleOnChangeDepartamentoLaboral
                              }
                              onChangePaisNac={this.handleOnChangePaisNac}
                              onChangeCiudadesNac={
                                this.handleOnCiudadesNacSearch
                              }
                            />
                          </Tabs.TabPane>
                          <Tabs.TabPane
                            key={3}
                            tab='En caso de tratarse de un menor de edad'
                          >
                            <Tutor
                              snMenorEdad={snMenorEdad}
                              paises={paises}
                              departamentosNac={departamentosTutorNac}
                              municipiosNac={municipiosTutorNac}
                              departamentosRes={departamentosTutorRes}
                              municipiosRes={municipiosTutorRes}
                              onChangePaisNacimiento={
                                this.handleOnChangePaisTutorNac
                              }
                              onChangeDepartamentoNac={
                                this.handleOnChangeDepartamentoNac
                              }
                              onChangePaisResidencia={
                                this.handleOnChangePaisTutorRes
                              }
                              onChangeDepartamentoResidencia={
                                this.handleOnChangeDepTutorRes
                              }
                              onChangeMenorEdad={this.handleOnChangeMenorEdad}
                            />
                          </Tabs.TabPane>
                          <Tabs.TabPane key={4} tab='Referencias comerciales'>
                            <CommercialReferences
                              referenciasComerciales={referenciasComerciales}
                              onChange={this.handleOnChangeCommRef}
                            />
                          </Tabs.TabPane>

                          <Tabs.TabPane key={5} tab='Referencias personales'>
                            <PersonalReferences
                              referenciasPersonales={referenciasPersonales}
                              onChange={this.handleOnChangePersonalRef}
                            />
                          </Tabs.TabPane>

                          <Tabs.TabPane key={6} tab='Información tributaria'>
                            <TributaryNatural
                              cdReteIva={cdReteIva}
                              cdReteIca={cdReteIca}
                              onChangeCdReteIva={this.handleOnChangeCdReteIva}
                              onChangeCdReteIca={this.handleOnChangeCdReteIca}
                            />
                          </Tabs.TabPane>
                          <Tabs.TabPane key={7} tab='Información financiera'>
                            <FinancialNatural />
                          </Tabs.TabPane>
                          <Tabs.TabPane
                            key={8}
                            tab='Operaciones en moneda extranjera'
                          >
                            <ForeignCurrencyOperations
                              paises={paises}
                              cdSnOpMonExt={cdSnOpMonExt}
                              departamentos={departamentosExt}
                              municipios={municipiosExt}
                              onChangePais={this.handleOnChangePaisExt}
                              onChangeDepartamento={
                                this.handleOnChangeDepartamentoExt
                              }
                              onChangeCdSnOpMonExt={
                                this.handleOnChangeCdSnOpMonExt
                              }
                            />
                          </Tabs.TabPane>
                        </>
                      )}
                    </Tabs>
                  </Col>
                </Row>
                <Row align='middle' justify='center' gutter={16}>
                  <Col span={6}>
                    <Form.Item>
                      <Button
                        className='form-btn-blue btn-radius'
                        type='default'
                        htmlType='button'
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Regresar
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      <Button
                        className='form-btn btn-radius'
                        type='primary'
                        htmlType='submit'
                      >
                        Guardar Cambios
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, props) => {
  const scPersona = atob(props.match.params.scPersona);
  const cdTypePerson = props.match.params.cdTypePerson;
  const cdTypeCompany = props.match.params.cdTypeCompany;
  return {
    authtoken: state.auth.auth.access_token,
    company: scPersona,
    personType: cdTypePerson,
    companyType : cdTypeCompany
  };
};

export default withRouter(connect(mapStateToProps, {})(Person));
