import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  notification,
  Spin,
  Row,
  Col,
  Divider,
  Button,
  Form,
  Select,
  Input,
  DatePicker,
} from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import TableReport from '../../../../../../components/tableReport/tableReport';
import { sorterText } from '../../../../../../utilities/textTools';
import {
  buscarSolicitudes,
  descargarReporte,
} from '../../../../../../services/admin/requestServices';
import QueryString from 'qs';
import moment from 'moment';
import {
  BUSQUEDA_TITLE,
  DATE_FORMAT,
  DATE_POST_FORMAT,
} from '../../../../../../utilities/messages';
import { setExcel } from '../../../../../../utilities/downloadTools';

const EstadosSolicitud = [
  { key: 'A', description: 'Aprobado (Completo y enviado)', type: 'F' },
  { key: 'E', description: 'En estudio', type: 'FC' },
  { key: 'SI', description: 'Solicitud Iniciada', type: 'FC' },
  { key: 'DI', description: 'Documentación Incompleta', type: 'F' },
  { key: 'PD', description: 'Pendiente Documentación', type: 'F' },
];

export const ReportConfirming = () => {
  const [form] = Form.useForm();
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState('+feRadicado');
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultado, setResultado] = useState([]);
  const [total, setTotal] = useState(0);
  const [filterFormData, setFilterFormData] = useState(null);
  const token = useSelector((state) => state.auth.auth.access_token);

  const handleSubmit = (formFilterData) => {
    setFilterFormData(formFilterData);
  };

  const getFiltro = () => {
    const filtro = {
      limit,
      offset,
      sort,
      solRadicadas: false,
      tipoSolicitud: 'CSR',
    };

    if (!filterFormData) {
      filtro['estadoSolicitud'] = 'E';
      return filtro;
    }

    const { id_nit, scSolicitud, fechaRango, razonSocial, estadoSolicitud } =
      Object.fromEntries(
        Object.entries(filterFormData).map(([key, value]) => [
          key,
          typeof value === 'string' ? value.trim() : value,
        ])
      );

    if (fechaRango?.[0]) {
      filtro['feDesde'] = moment(fechaRango[0]).format(DATE_POST_FORMAT);
    }

    if (fechaRango?.[1]) {
      filtro['feHasta'] = moment(fechaRango[1]).format(DATE_POST_FORMAT);
    }

    if (id_nit) filtro['id_nit'] = id_nit;
    if (razonSocial) filtro['razonSocial'] = razonSocial;
    if (estadoSolicitud) filtro['estadoSolicitud'] = estadoSolicitud;
    if (scSolicitud) filtro['scSolicitud'] = scSolicitud;

    return filtro;
  };

  const getSolicitudesConfirming = async () => {
    setLoading(true);
    try {
      const res = await buscarSolicitudes(token, getFiltro());
      setResultado(res.data);
      setTotal(res.metadata.count);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Error listando solicitudes ' + error,
        duration: 4,
      });
    } finally {
      setLoading(false);
    }
  };

  const exportExcel = async () => {
    setLoading(true);
    try {
      const response = await descargarReporte(token, getFiltro());
      setExcel(response, 'SolicitudesConfirming');
    } catch (error) {
      notification.error({
        message: 'Error al descargar reporte',
        description: '' + error,
        duration: 4,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setLimit(e.limit);
    setSort(e.sort || '+feRadicado');
    setOffset(e.offset);
    setCurrentPage(e.currentPage);
  };

  useEffect(() => {
    if (initialized) {
      getSolicitudesConfirming();
    }
  }, [filterFormData, limit, offset, sort, currentPage]);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    let urlData = {};

    if (queryParameters.size > 0) {
      urlData = {
        razonSocial: queryParameters.get('rs'),
        id_nit: queryParameters.get('id_nit'),
        scSolicitud: queryParameters.get('scSolicitud'),
        estadoSolicitud: queryParameters.get('estSol') || 'E',
      };

      if (queryParameters.get('feDesde') && queryParameters.get('feHasta')) {
        urlData.fechaRango = [
          moment(queryParameters.get('feDesde'), 'YYYYMMDD'),
          moment(queryParameters.get('feHasta'), 'YYYYMMDD'),
        ];
      }

      form.setFieldsValue(urlData);
      setFilterFormData(urlData);
    } else {
      getSolicitudesConfirming();
    }

    setInitialized(true);
  }, []);

  const columnas = [
    {
      title: 'FECHA CREACIÓN',
      dataIndex: 'feCreacionSolicitud',
      sorter: (a, b) => sorterText(a.feRadicado, b.feRadicado),
      showSorterTooltip: false,
    },
    {
      title: 'CONSECUTIVO',
      render: ({ scSolicitud }) => (
        <Link to={generateRequestUrl(scSolicitud, filterFormData)}>
          <p>{scSolicitud}</p>
        </Link>
      ),
      showSorterTooltip: false,
    },
    {
      title: 'NIT/ID',
      dataIndex: 'idNit',
      sorter: (a, b) => sorterText(a.id_nit, b.id_nit),
      showSorterTooltip: false,
    },
    {
      title: 'NOMBRE/RAZÓN SOCIAL',
      dataIndex: 'nombreRazonSocial',
      sorter: (a, b) => sorterText(a.nombreRazonSocial, b.nombreRazonSocial),
      showSorterTooltip: false,
    },
    {
      title: 'ESTADO',
      dataIndex: 'dsEstado',
      sorter: (a, b) => sorterText(a.dsEstado, b.dsEstado),
      showSorterTooltip: false,
    },
    {
      title: 'FECHA RADICADO',
      dataIndex: 'feRadicado',
      sorter: (a, b) => sorterText(a.feRadicado, b.feRadicado),
      showSorterTooltip: false,
    },
  ];

  const generateRequestUrl = (scSolicitud, filterFormData) => {
    const encodedSolicitud = Buffer.from(scSolicitud).toString('base64');

    if (!filterFormData) {
      return `${process.env.PUBLIC_URL}/request/${encodedSolicitud}?tipoSol=CSR`;
    }

    const queryParams = QueryString.stringify({
      id_nit: filterFormData.id_nit || '',
      scSolicitud: filterFormData.scSolicitud || '',
      rs: filterFormData.razonSocial || '',
      estSol: filterFormData.estadoSolicitud || 'E',
      tipoSol: 'CSR',
      feDesde: filterFormData.fechaRango
        ? moment(filterFormData.fechaRango[0]).format(DATE_POST_FORMAT)
        : '',
      feHasta: filterFormData.fechaRango
        ? moment(filterFormData.fechaRango[1]).format(DATE_POST_FORMAT)
        : '',
    });

    return `${process.env.PUBLIC_URL}/request/${encodedSolicitud}?${queryParams}`;
  };

  return (
    <>
      <Spin tip='Cargando...' spinning={loading}>
        <h2 align='center'>Solicitudes</h2>
        <div className='header-container'>
          <p className='title'>{BUSQUEDA_TITLE}</p>
          <Form
            name='content_form'
            layout='vertical'
            className='filters-container'
            initialValues={{ estadoSolicitud: 'E' }}
            form={form}
            onFinish={handleSubmit}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name='fechaRango' label='Rango De Fechas'>
                  <DatePicker.RangePicker
                    style={{ width: '100%' }}
                    format={DATE_FORMAT}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name='id_nit' label='Nit/Identificación'>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name='razonSocial' label='Nombre Proveedor'>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name='scSolicitud' label='Consecutivo Solicitud'>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name='estadoSolicitud' label='Estado Solicitud'>
                  <Select allowClear>
                    {EstadosSolicitud.map(({ key, description }) => (
                      <Select.Option key={key} value={key}>
                        {description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row justify='center' style={{ marginTop: '-2.5rem' }}>
              <Col span={8}>
                <Form.Item>
                  <Button
                    htmlType='submit'
                    className='form-btn btn-radius'
                    type='primary'
                  >
                    Buscar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        {resultado.length > 0 && (
          <Row className='bill-selection' justify='end'>
            <Col className='export'>
              <span className='button'>
                <Button
                  size='medium'
                  className='btn-excel'
                  title='Exportar a excel'
                  onClick={exportExcel}
                  icon={<FileExcelOutlined />}
                />
              </span>
            </Col>
          </Row>
        )}
        <Row className='table-container'>
          <Divider />
          <Col span={24}>
            <TableReport
              currentPage={currentPage}
              limit={limit}
              total={total}
              columns={columnas}
              data={resultado}
              handleChange={handleChange}
            />
          </Col>
        </Row>
      </Spin>
    </>
  );
};
