import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import '../../reportsSagrilaft.scss';
import {
  Button,
  notification,
  Card,
  Spin,
  Row,
  Col,
  Form,
  DatePicker,
  Select,
  Divider,
} from 'antd';
import history from '../../../../../../services/history';
import { sorterText, sorterDate } from '../../../../../../utilities/textTools';
import TableReport from '../../../../../../components/tableReport/tableReport';
import {
  DATE_POST_FORMAT,
  BUSQUEDA_TITLE,
  DATE_FORMAT,
} from '../../../../../../utilities/messages';
import { reporteSarlaft } from '../../../../../../services/admin/reports/sagrilaft';
import { listarCompañias } from '../../../../../../services/admin/reportsNeuroScoreService';

const SELECT_PLACE_HOLDER = 'Seleccione...';

const initialState = {
  form: {
    fechaDesde: moment().subtract(1, 'days'),
    fechaHasta: moment(),
    scCompania: '',
  },
  loading: false,
  resultado: [],
  total: 0,
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  companiaSelect: {
    fetching: false,
    options: [],
  },
};

class ReportSarlaft extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'NIT',
        dataIndex: 'id_nit',
        sorter: (a, b) => sorterText(a.id_nit, b.id_nit),
        showSorterTooltip: false,
      },
      {
        title: 'COMPAÑIA',
        dataIndex: 'dsRazonSocial',
        sorter: (a, b) => sorterText(a.dsRazonSocial, b.dsRazonSocial),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA CONSULTA',
        dataIndex: 'feConsulta',
        sorter: (a, b) => sorterDate(a.feConsulta, b.feConsulta),
        showSorterTooltip: false,
      },
      {
        title: 'RESULTADO',
        render: (record) => (
          <Button
            type='primary'
            title='Resultado'
            onClick={() => this.showDetailsPage(record)}
          >
            DETALLES
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];
  }

  componentDidMount() {
    const savedState = localStorage.getItem('sarlaftState');

    if (savedState) {
      this.setState(JSON.parse(savedState));
    }

    this.form.setFieldsValue({
      fechaRango: [this.state.form.fechaDesde, this.state.form.fechaHasta],
      scCompania: this.state.form.scCompania ? this.state.form.scCompania : ''
    });
  }

  showDetailsPage = (record) => {
    localStorage.setItem('sarlaftState', JSON.stringify(this.state));

    history.push(
      `${process.env.PUBLIC_URL}/sarlaftcol/details/${btoa(record.scCompania)}`
    );
  };

  handleOnSearchCompania = async (nombre) => {
    const { authToken } = this.props;
    if (nombre && nombre.length >= 3) {
      const { companiaSelect } = this.state;
      this.setState({
        companiaSelect: {
          ...companiaSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarCompañias(authToken, {
          nombre: nombre,
        });
        this.setState({
          companiaSelect: {
            ...companiaSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          companiaSelect: {
            ...companiaSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleSelectCompania = (compania) => {
    this.setState({
      form: {
        ...this.state.form,
        scCompania: compania,
      },
    });
  };

  handleSubmit = (values) => {
    const { fechaRango } = values;
    this.setState(
      {
        form: {
          fechaDesde: moment(fechaRango[0]).format(DATE_POST_FORMAT),
          fechaHasta: moment(fechaRango[1]).format(DATE_POST_FORMAT),
          scCompania: this.state.form.scCompania,
        },
        currentPage: initialState.currentPage,
        offset: initialState.offset,
      },
      () => {
        this.searchData();
      }
    );
  };

  searchData = async () => {
    const { authToken } = this.props;
    this.setState({
      resultado: [],
      loading: true,
    });

    try {
      const response = await reporteSarlaft(authToken, this.getFiltro());
      this.setState({
        resultado: response.data,
        total: response.metadata.count,
      });
      this.clearState();
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Se presentó un error inesperado',
        duration: 5,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      scCompania: form.scCompania,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  clearState = () => {
    localStorage.removeItem('sarlaftState');
  };

  render() {
    const { loading, total, currentPage, limit, resultado, companiaSelect } =
      this.state;

    return (
      <div className='reports-style-f'>
        <Card className='card-shadow card-radius'>
          <div className='align-center'>
            <div className='reports-container'>
              <Spin tip='Cargando...' spinning={loading}>
                <p
                  className='title'
                  style={{
                    marginTop: '3rem',
                    textAlign: 'left',
                    fontFamily: 'Roboto',
                    fontSize: '1.2rem',
                  }}
                >
                  {BUSQUEDA_TITLE}
                </p>
                <Form
                  name='content_form'
                  layout='vertical'
                  className='filters-container'
                  onFinish={this.handleSubmit}
                  initialValues={{
                    fechaRango: [
                      moment(this.state.form.fechaDesde),
                      moment(this.state.form.fechaHasta),
                    ],
                  }}
                  ref={(form) => {
                    this.form = form;
                  }}
                >
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name='fechaRango'
                        label={
                          <span style={{ color: '#828282' }}>
                            Rango de fechas
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: 'Por favor seleccione un rango de fechas',
                          },
                        ]}
                      >
                        <DatePicker.RangePicker format={DATE_FORMAT} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <span style={{ color: '#828282' }}>Compañía</span>
                        }
                      >
                        <Row gutter={8}>
                          <Col span={12}>
                            <Select
                              placeholder={SELECT_PLACE_HOLDER}
                              showSearch
                              allowClear
                              defaultActiveFirstOption={false}
                              showArrow={false}
                              filterOption={false}
                              onSearch={this.handleOnSearchCompania}
                              onChange={this.handleSelectCompania}
                              notFoundContent={
                                companiaSelect.fetching ? (
                                  <Spin size='small' />
                                ) : null
                              }
                              style={{ width: '100%' }}
                            >
                              {companiaSelect.options.map((d) => (
                                <Select.Option key={d.codigo} value={d.codigo}>
                                  {d.descripcion}
                                </Select.Option>
                              ))}
                            </Select>
                          </Col>
                          <Col span={12}>
                            <Button
                              htmlType='submit'
                              className='form-btn btn-radius'
                              type='primary'
                              style={{ width: '60%' }}
                            >
                              Buscar
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
                <div className='table-container'>
                  <Divider />
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    columns={this.columnas}
                    total={total}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </div>
              </Spin>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(ReportSarlaft);
