import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Checkbox,
  Modal,
  Row,
  Select,
  Divider,
} from 'antd';
import {
  requiredValueMessage,
} from '../../../../utilities/admin_validation_messages';
import { tipoIds } from '../../../../utilities/constants';
import { length } from '../../../../utilities/validate/textValidate';

const SELECT_PLACE_HOLDER = 'Seleccionar Beneficiario...';
const SELECT_PLACE_HOLDER_TIPO_ID = 'Seleccionar Tipo Documento';

const rules = {
  cdTipoId: [{ required: true, message: requiredValueMessage() }],
  id: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9a-zA-Z-]*$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 3, 20),
    },
  ],
  nombre: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 2, 100),
    },
  ],
};

export const PersonaAdicionalForm = ({ visible, onClose, onFinish, modalTitle, formName, personType, posiblesBen }) => {

  const [form] = Form.useForm();
  const [ben, setBen] = useState([]);


  useEffect(() => {
    form.resetFields();
    setBen([]);
  }, [form, visible]);

  const handleChangeBen = (ben) => {
    setBen(ben);
  }

  const handleOnFinishForm = (formValue) => {
    if (formValue.cdTipoId) {
      const dsTipoId = tipoIds.find(
        (value) => value.key === formValue.cdTipoId
      ).description;

      onFinish({ ...formValue, dsTipoId });

    } else {
      onFinish(formValue);
    }

  };

  return (
    <div>
      <Modal
        title={modalTitle}
        visible={visible}
        onCancel={onClose}
        width={500}
        footer={false}
      >
        <Form
          name={formName}
          layout='vertical'
          onFinish={handleOnFinishForm}
          form={form}
          initialValues={{ cdTipoPersona: personType }}
        >

          {
            personType === 'BF' && posiblesBen.length > 0 ?
              <>
                <Row gutter={16}>
                  <Col flex={'auto'}>
                    <Form.Item
                      name='benAdicionales'
                      label={<b>Puedes seleccionar los beneficiarios precargados en la lista o ingresarlos manualmente</b>}
                    >
                      <Select
                        placeholder={SELECT_PLACE_HOLDER}
                        mode='multiple'
                        onChange={handleChangeBen}
                        allowClear
                        style={{ width: '100%' }}
                        filterOption={(input, option) => {
                          return (
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                            0
                          );
                        }}
                      >
                        {posiblesBen &&
                          posiblesBen.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.nombre} ({item.id})
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
              </>
              : null
          }

          <Form.Item
            rules={!(ben.length > 0) || personType == 'JD' ? rules['cdTipoId'] : null}
            name='cdTipoId'
            label='Tipo de documento'
          >
            <Select placeholder={SELECT_PLACE_HOLDER_TIPO_ID} allowClear>
              {tipoIds.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name='key' hidden={true}>
            <Input />
          </Form.Item>

          <Form.Item name='cdTipoPersona' hidden={true}>
            <Input />
          </Form.Item>

          <Form.Item
            rules={!(ben.length > 0) || personType == 'JD' ? rules['id'] : null}
            name='id'
            label='Número de identificación'
          >
            <Input minLength={3} maxLength={20} />
          </Form.Item>

          <Form.Item
            rules={!(ben.length > 0) || personType == 'JD' ? rules['nombre'] : null}
            name='nombre'
            label='Nombre / Razon social'
          >
            <Input maxLength={100} />
          </Form.Item>

          <Row justify='center'>
            <Col span={6}>
              <Form.Item>
                <Button
                  className='form-btn btn-radius'
                  type='primary'
                  htmlType='submit'
                >
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>


    </div>
  )
}
