import axios from 'axios';
import { middleWare } from '../middleware';

const baseUrl = process.env.REACT_APP_SERVICES_URL_VINCULACION;

export const buscarFichaJuridicaPU = (authToken, id) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/fichaJuridicaPU/buscarFichaJuridicaPU/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  );
};

export const listarFichasJuridicaPU = (authToken, data) => {
  let filters = formatoFechas(data);
  return middleWare(
    axios({
      url: `${baseUrl}/api/fichaJuridicaPU/listar?id_nit=${data.id_nit}&nombre=${data.nombre}&fechaDesde=${filters.fechaDesde}&fechaHasta=${filters.fechaHasta}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const actualizarFichaJuridicaPU = (authToken, scCompania, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/fichaJuridicaPU/actualizarFichaJuridicaPU/${scCompania}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: data,
    })
  );
};

export const actualizarEstadoFichaJuridicaPU = (
  authToken,
  scCompania,
  data
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/fichaJuridicaPU/actualizarEstadoFichaJuridicaPU/${scCompania}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: data,
    })
  );
};

export const guardarFichaJuridicaPU = (authToken, scCompania, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/fichaJuridicaPU/guardarFichaJuridicaPU/${scCompania}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: data,
    })
  );
};

export const guardarFichaJuridicaPUF4ALL = (authToken, scCompania, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/fichaJuridicaPU/guardarFichaJuridicaPUF4ALL/${scCompania}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: data,
    })
  );
};

const formatoFechas = (filtros) => {
  let empty = {};
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') || nombre.includes('Hasta')) &&
        typeof filtros[nombre] === 'object'
      ) {
        empty[nombre] = filtros[nombre].format('YYYYMMDD');
      } else if (
        filtros[nombre] &&
        nombre != 'currentPage' &&
        nombre != 'total'
      ) {
        empty[nombre] = filtros[nombre];
      }
    }
  });
  return empty;
};
