import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  notification,
  Row,
  Spin,
  Typography,
  Upload,
} from 'antd';
import { CheckCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { sizeFileValidation } from '../../../utilities/validate/fileValidate';
import { mensajesEstadoVinculacion } from '../../../components/fichaCliente/estadosVinculacion/mensajesEstadoVin';
import {
  CardEstudio,
  CardCompletoEnviado,
  CardRechazado,
} from '../../../components/fichaCliente/estadosVinculacion/cardsVinculacion';
import {
  grabarDocumentosAnexos,
  listarDocumentosAnexos,
  validarArchivos,
  actualizarArchivo,
} from '../../../services/vinculacion/documentosAnexosService';
import { actualizarEstadoFichaJuridicaPU } from '../../../services/vinculacion/fichaJuridicaPUservice';
import { actualizarEstadoFichaNaturalPU } from '../../../services/vinculacion/fichaNaturalPUservice';
import Text from 'antd/lib/typography/Text';

export const DocumentosAnexos = (props) => {
  const form = useRef(null);
  const [scCompania, setScCompania] = useState('');
  const [tipoProducto, setTipoProducto] = useState('');
  const [estadoFicha, setEstadoFicha] = useState('');
  const [estadoVinculacion, setEstadoVinculacion] = useState('');
  const [indicaBF, setIndicaBF] = useState(false);
  const [tipoPersona, setTipoPerona] = useState('');
  const [idUser, setIdUser] = useState('');
  const [listDocument, setListDocument] = useState([]);
  const [loading, setLoading] = useState(false);
  const authToken = useSelector((state) => state.auth.auth.access_token);

  const { Title } = Typography;
  const mensajeCargado = ' Este archivo fue cargado anteriormente.';
  const MAX_FILES = 5;
  const accepted_extensions = [
    '.zip',
    '.rar',
    '.xlsx',
    '.xls',
    '.pdf',
    '.jpeg',
    '.png',
    '.7z',
    '.doc',
    '.docx',
    '.pptx',
    '.ppt',
  ];

  const getMimeType = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();

    const mimeTypes = {
      zip: 'application/zip',
      rar: 'application/vnd.rar',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      xls: 'application/vnd.ms-excel',
      pdf: 'application/pdf',
      jpeg: 'image/jpeg',
      png: 'image/png',
      '7z': 'application/x-7z-compressed',
      doc: 'application/msword',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      ppt: 'application/vnd.ms-powerpoint',
    };

    return mimeTypes[extension] || 'application/octet-stream';
  };

  const handleListDocumentAnexo = async (scCompania) => {
    setLoading(true);

    try {
      const response = await listarDocumentosAnexos(authToken, scCompania);
      const docs = response.map(transformDocument);
      setListDocument(docs);
    } catch {
      notification.error({
        message: 'Error',
        description: 'Error en el servidor al buscar documentos Anexos',
        duration: 4,
      });
    } finally {
      setLoading(false);
    }
  };

  const transformDocument = (doc) => {
    const mimeType = getMimeType(doc.fileName);
    const byteArray = Uint8Array.from(atob(doc.file), (char) =>
      char.charCodeAt(0)
    );
    const blob = new Blob([byteArray], { type: mimeType });
    const url = URL.createObjectURL(blob);

    return {
      id: doc.id,
      name: doc.fileName,
      tipoDoc: doc.tipoDoc,
      estadoDoc: doc.estadoDoc,
      type: mimeType,
      url,
    };
  };

  const handleOnPreview = (file) => {
    if (
      file.url &&
      (file.type?.startsWith('image/') || file.type === 'application/pdf')
    ) {
      window.open(file.url, '_blank');
    } else {
      const a = document.createElement('a');
      a.href = file.url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handleOnRemove = async (file) => {
    setLoading(true);

    try {
      const response = await actualizarArchivo(authToken, scCompania, file.id);

      notification.success({
        message: 'Eliminación exitosa',
        description: response.message,
      });

      handleListDocumentAnexo(scCompania);
    } catch {
      notification.error({
        message: 'Error',
        description: 'Hubo un problema al intentar eliminar el archivo',
        duration: 4,
      });
      setLoading(false);
    }
  };

  const handleOnFinish = async () => {
    setLoading(true);

    try {
      if (estadoFicha !== 'Completa') {
        notification.warn({
          message: 'Alerta',
          description: 'Por favor termine de diligenciar la ficha en el paso 1',
          duration: 4,
        });
        return;
      }

      const response = await validarArchivos(authToken, scCompania);
      const docsValidados = response?.data || [];

      if (Array.isArray(docsValidados) && docsValidados.length > 0) {
        docsValidados.forEach((doc) => {
          notification.warn({
            message: 'Documentos requeridos',
            description: `Falta el documento ${doc}`,
            duration: 5,
          });
        });
        return;
      }

      const estadoVinGeneral = () => {
        if (['P', 'DI'].includes(estadoVinculacion)) return 'E';
        if (tipoProducto === 'F') {
          if (estadoVinculacion === 'AP') return 'CE';
        }
      };

      const cdEstadoVinGeneral = estadoVinGeneral();

      const data = {
        cdEstadoVinGeneral,
        idUser,
        cdTipoProducto: tipoProducto,
      };

      if (tipoPersona === 'J') {
        await actualizarEstadoFichaJuridicaPU(authToken, scCompania, data);
      } else if (tipoPersona === 'N') {
        await actualizarEstadoFichaNaturalPU(authToken, scCompania, data);
      }

      const jsonPersona = {
        ...JSON.parse(localStorage.getItem('personData')),
        estadoVinculacion: cdEstadoVinGeneral,
      };
      localStorage.setItem('personData', JSON.stringify(jsonPersona));
      setEstadoVinculacion(cdEstadoVinGeneral);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: `Error guardando ficha: ${error}`,
        duration: 4,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleBeforeUpload = async (file, fileList, tipoDoc) => {
    const fileExtension = file.name.split('.').pop().toLowerCase();

    let currentCount;

    if (estadoVinculacion !== 'DI') {
      currentCount = listDocument.filter(
        (doc) => doc.tipoDoc === tipoDoc
      ).length;
    } else if (estadoVinculacion === 'DI') {
      currentCount = listDocument.filter(
        (value) => value.tipoDoc === tipoDoc && value.estadoDoc === 'DC'
      ).length;
    }

    if (currentCount + fileList.length > MAX_FILES) {
      notification.info({
        message: 'Límite alcanzado',
        description: `No se pueden subir más de 5 archivos`,
      });
      return false;
    }

    try {
      await sizeFileValidation(fileList);
    } catch (error) {
      notification.error({
        message: 'Error de validación',
        description: error.message,
      });
      return false;
    }

    if (!accepted_extensions.includes(`.${fileExtension}`)) {
      notification.error({
        message: 'Formato no válido',
        description: `El documento ${
          file.name
        } no tiene el formato válido (${accepted_extensions.join(', ')})`,
      });
      return false;
    }

    return true;
  };

  const handleSubirDocumentos = async (options, name) => {
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append(name, options.file);

      await grabarDocumentosAnexos(authToken, formData, scCompania);

      notification.success({
        message: 'Documento guardado exitosamente',
        duration: 5,
      });

      handleListDocumentAnexo(scCompania);
    } catch {
      notification.error({
        message: 'Error',
        description: 'Hubo un error al subir el documento',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    props.menuHandler('3');

    let ls = localStorage.getItem('personData');

    if (ls != null) {
      const {
        scCompania,
        tipoProducto,
        estadoFicha,
        estadoVinculacion,
        tipoPersona,
        idUser,
        indicaBF,
      } = JSON.parse(ls);

      setScCompania(scCompania);
      setTipoProducto(tipoProducto);
      setEstadoFicha(estadoFicha);
      setEstadoVinculacion(estadoVinculacion);
      setTipoPerona(tipoPersona);
      setIdUser(idUser);
      handleListDocumentAnexo(scCompania);
      setIndicaBF(indicaBF);
    } else {
      notification.error({
        message: 'Error',
        description: 'Error listando Documentos Anexos',
        duration: 4,
      });
    }
  }, []);

  const handleShowText = (tipoDoc) => {
    return (
      <>
        {estadoVinculacion === 'DI' &&
          listDocument.some(
            (value) => value.tipoDoc === tipoDoc && value.estadoDoc === 'DCE'
          ) && (
            <Text type='secondary'>
              <CheckCircleOutlined color='secondary' />
              <b style={{ color: 'gray' }}>{mensajeCargado}</b>
            </Text>
          )}
      </>
    );
  };

  const handleDocuments = (tipoDoc) => {
    if (estadoVinculacion !== 'DI') {
      return listDocument.filter((value) => value.tipoDoc === tipoDoc);
    } else if (estadoVinculacion === 'DI') {
      return listDocument.filter(
        (value) => value.tipoDoc === tipoDoc && value.estadoDoc === 'DC'
      );
    }
  };

  const handleDisabledButton = (tipoDoc) => {
    if (estadoVinculacion !== 'DI') {
      return (
        listDocument.filter((value) => value.tipoDoc === tipoDoc).length >= 5
      );
    } else if (estadoVinculacion === 'DI') {
      return (
        listDocument.filter(
          (value) => value.estadoDoc !== 'DCE' && value.tipoDoc === tipoDoc
        ).length >= 5
      );
    }
  };

  const estadoActual = mensajesEstadoVinculacion.find(
    (mensaje) => mensaje.key === estadoVinculacion
  );

  const htmlCamaraDeComercio = (
    <Col span={12}>
      <Title level={4}>CÁMARA DE COMERCIO</Title>
      <h3>
        Requisitos:{' '}
        <h5 style={{ fontWeight: 'bold' }}>
          <b style={{ color: 'green' }}>Este no debe ser mayor a 30 días</b>
        </h5>
      </h3>
      {handleShowText('CC')}
      <Form.Item name='CC'>
        <br />
        <Upload
          fileList={handleDocuments('CC')}
          onPreview={handleOnPreview}
          onRemove={handleOnRemove}
          beforeUpload={(file, fileList) =>
            handleBeforeUpload(file, fileList, 'CC')
          }
          customRequest={(options) => handleSubirDocumentos(options, 'CC')}
          multiple={true}
          maxCount={MAX_FILES}
        >
          <Button
            icon={<UploadOutlined />}
            disabled={handleDisabledButton('CC')}
          >
            Clic Para Subir Archivo
          </Button>
        </Upload>
      </Form.Item>
    </Col>
  );

  const htmlCedulaRepLegal = (
    <Col span={12}>
      <Title level={4}>COPIA DE LA CÉDULA</Title>
      <h3>
        Requisitos:{' '}
        <h5 style={{ fontWeight: 'bold' }}>
          <b style={{ color: 'green' }}>
            Copia de la cédula del representante legal
          </b>
        </h5>
      </h3>
      {handleShowText('CCRL')}
      <Form.Item name='CCRL'>
        <br />
        <Upload
          fileList={handleDocuments('CCRL')}
          onPreview={handleOnPreview}
          onRemove={handleOnRemove}
          beforeUpload={(file, fileList) =>
            handleBeforeUpload(file, fileList, 'CCRL')
          }
          customRequest={(options) => handleSubirDocumentos(options, 'CCRL')}
          multiple={true}
          maxCount={MAX_FILES}
        >
          <Button
            icon={<UploadOutlined />}
            disabled={handleDisabledButton('CCRL')}
          >
            Clic Para Subir Archivo
          </Button>
        </Upload>
      </Form.Item>
    </Col>
  );

  const htmlCertificadoCuentaBancaria = (
    <Col span={12}>
      <Title level={4}>CERTIFICADO CUENTA BANCARIA</Title>
      <h3>
        Requisitos:{' '}
        <h5 style={{ fontWeight: 'bold' }}>
          <b style={{ color: 'green' }}>Este no debe ser mayor a 30 días</b>
        </h5>
      </h3>
      {handleShowText('CCB')}
      <Form.Item name='CCB'>
        <br />
        <Upload
          fileList={handleDocuments('CCB')}
          onPreview={handleOnPreview}
          onRemove={handleOnRemove}
          beforeUpload={(file, fileList) =>
            handleBeforeUpload(file, fileList, 'CCB')
          }
          customRequest={(options) => handleSubirDocumentos(options, 'CCB')}
          multiple={true}
          maxCount={MAX_FILES}
        >
          <Button
            icon={<UploadOutlined />}
            disabled={handleDisabledButton('CCB')}
          >
            Clic Para Subir Archivo
          </Button>
        </Upload>
      </Form.Item>
    </Col>
  );

  const htmlEstadosFinancieros = (
    <Col span={12}>
      <Title level={4}>ESTADOS FINANCIEROS</Title>
      <h3>
        Requisitos:{' '}
        <h5 style={{ fontWeight: 'bold' }}>
          <b style={{ color: 'green' }}>
            Estados financieros del cierre del año anterior <br /> y último
            corte de año en curso
          </b>
        </h5>
      </h3>
      {handleShowText('EF')}
      <Form.Item name='EF'>
        <br />
        <Upload
          fileList={handleDocuments('EF')}
          onPreview={handleOnPreview}
          onRemove={handleOnRemove}
          beforeUpload={(file, fileList) =>
            handleBeforeUpload(file, fileList, 'EF')
          }
          customRequest={(options) => handleSubirDocumentos(options, 'EF')}
          multiple={true}
          maxCount={MAX_FILES}
        >
          <Button
            icon={<UploadOutlined />}
            disabled={handleDisabledButton('EF')}
          >
            Clic Para Subir Archivo
          </Button>
        </Upload>
      </Form.Item>
    </Col>
  );

  const htmlCertificadoBF = (
    <Col span={12}>
      <Title level={4}>CERTIFICADO BENEFICIARIOS FINALES</Title>
      <h3>
        Requisitos:{' '}
        <h5 style={{ fontWeight: 'bold' }}>
          <b style={{ color: 'green' }}>
            Carga aquí la certificación firmada por tu oficial de cumplimiento o
            representante legal que garantice que tu empresa realizó la debida
            diligencia SAGRILAFT a sus beneficiarios finales <br />
          </b>
        </h5>
      </h3>
      {handleShowText('CSBF')}
      <Form.Item name='CSBF'>
        <Upload
          fileList={handleDocuments('CSBF')}
          onPreview={handleOnPreview}
          onRemove={handleOnRemove}
          beforeUpload={(file, fileList) =>
            handleBeforeUpload(file, fileList, 'CSBF')
          }
          customRequest={(options) => handleSubirDocumentos(options, 'CSBF')}
          multiple={true}
          maxCount={MAX_FILES}
        >
          <Button
            icon={<UploadOutlined />}
            disabled={handleDisabledButton('CSBF')}
          >
            Clic Para Subir Archivo
          </Button>
        </Upload>
      </Form.Item>
    </Col>
  );

  const htmlDocumentosAnexos = (
    <Col span={12}>
      <Title level={4}>ANEXOS</Title>
      Requisitos:{' '}
      <h4 style={{ fontWeight: 'bold' }}>
        <b style={{ color: 'green' }}>Campo opcional</b>
      </h4>
      {handleShowText('DAN')}
      <Form.Item name='DAN'>
        <br />
        <Upload
          fileList={handleDocuments('DAN')}
          onPreview={handleOnPreview}
          onRemove={handleOnRemove}
          beforeUpload={(file, fileList) =>
            handleBeforeUpload(file, fileList, 'DAN')
          }
          customRequest={(options) => handleSubirDocumentos(options, 'DAN')}
          multiple={true}
          maxCount={MAX_FILES}
        >
          <Button
            icon={<UploadOutlined />}
            disabled={handleDisabledButton('DAN')}
          >
            Clic Para Subir Archivo
          </Button>
        </Upload>
      </Form.Item>
    </Col>
  );

  const htmlRut = (
    <Col span={12}>
      <Title level={4}>RUT</Title>
      <h3>
        Requisitos:{' '}
        <h5 style={{ fontWeight: 'bold' }}>
          <b style={{ color: 'green' }}>RUT Actualizado</b>
        </h5>
      </h3>
      {handleShowText('RUT')}
      <Form.Item name='RUT'>
        <br />
        <Upload
          fileList={handleDocuments('RUT')}
          onPreview={handleOnPreview}
          onRemove={handleOnRemove}
          beforeUpload={(file, fileList) =>
            handleBeforeUpload(file, fileList, 'RUT')
          }
          customRequest={(options) => handleSubirDocumentos(options, 'RUT')}
          multiple={true}
          maxCount={MAX_FILES}
        >
          <Button
            icon={<UploadOutlined />}
            disabled={handleDisabledButton('RUT')}
          >
            Clic Para Subir Archivo
          </Button>
        </Upload>
      </Form.Item>
    </Col>
  );

  const htmlDeclaracionRenta = (
    <Col span={12}>
      <Title level={4}>DECLARACIÓN DE RENTA</Title>
      <h3>
        Requisitos:{' '}
        <h5 style={{ fontWeight: 'bold' }}>
          <b style={{ color: 'green' }}>Último periodo disponible</b>
        </h5>
      </h3>
      {handleShowText('DR')}
      <Form.Item name='DR'>
        <br />
        <Upload
          fileList={handleDocuments('DR')}
          onPreview={handleOnPreview}
          onRemove={handleOnRemove}
          beforeUpload={(file, fileList) =>
            handleBeforeUpload(file, fileList, 'DR')
          }
          customRequest={(options) => handleSubirDocumentos(options, 'DR')}
          multiple={true}
          maxCount={MAX_FILES}
        >
          <Button
            icon={<UploadOutlined />}
            disabled={handleDisabledButton('DR')}
          >
            Clic Para Subir Archivo
          </Button>
        </Upload>
      </Form.Item>
    </Col>
  );

  const htmlCompoAccionaria = (
    <Col span={12}>
      <Title level={4}>COMPOSICIÓN ACCIONARIA</Title>
      <h3>
        Requisitos:{' '}
        <h5 style={{ fontWeight: 'bold' }}>
          <b style={{ color: 'green' }}>
            La sumatoria de los accionistas debe ser del 100%
          </b>
        </h5>
      </h3>
      {handleShowText('CA')}
      <Form.Item name='CA'>
        <br />
        <Upload
          fileList={handleDocuments('CA')}
          onPreview={handleOnPreview}
          onRemove={handleOnRemove}
          beforeUpload={(file, fileList) =>
            handleBeforeUpload(file, fileList, 'CA')
          }
          customRequest={(options) => handleSubirDocumentos(options, 'CA')}
          multiple={true}
          maxCount={MAX_FILES}
        >
          <Button
            icon={<UploadOutlined />}
            disabled={handleDisabledButton('CA')}
          >
            Clic Para Subir Archivo
          </Button>
        </Upload>
      </Form.Item>
    </Col>
  );

  return (
    <>
      <Row className='users-style'>
        <Col span={24}>
          <Spin tip='Cargando...' spinning={loading}>
            {estadoVinculacion === 'E' && (
              <CardEstudio estadoVin={estadoVinculacion} />
            )}
            {estadoVinculacion === 'CE' && (
              <CardCompletoEnviado estadoVin={estadoVinculacion} />
            )}
            {estadoVinculacion === 'RE' && (
              <CardRechazado estadoVin={estadoVinculacion} />
            )}
            {!(
              estadoVinculacion === 'E' ||
              estadoVinculacion === 'CE' ||
              estadoVinculacion === 'RE'
            ) && (
              <>
                <h3>
                  <b>Estado Solicitud : </b> {estadoActual?.description}
                </h3>
                <b style={{ fontSize: '16px' }}>{estadoActual?.message}</b>
                <Card className='card-shadow card-radius default-border welcome-message'>
                  <Form
                    ref={form}
                    name='content_form'
                    layout='vertical'
                    onFinish={handleOnFinish}
                  >
                    <br />
                    {tipoProducto === 'F' &&
                      (estadoVinculacion === 'P' ||
                        estadoVinculacion === 'DI') && (
                        <>
                          <Row gutter={[16, 32]}>
                            {htmlCamaraDeComercio}
                            {htmlCedulaRepLegal}
                          </Row>
                          <br />
                          <Row gutter={[16, 32]}>
                            {htmlCertificadoCuentaBancaria}
                            {htmlEstadosFinancieros}
                          </Row>
                          <br />
                          <Row gutter={[16, 32]}>{htmlDocumentosAnexos}</Row>
                        </>
                      )}
                    {tipoProducto === 'F' && estadoVinculacion === 'AP' && (
                      <>
                        <Row gutter={[16, 32]}>
                          {htmlCamaraDeComercio}
                          {htmlRut}
                        </Row>
                        <br />
                        <Row gutter={[16, 32]}>
                          {htmlCedulaRepLegal}
                          {htmlCertificadoCuentaBancaria}
                        </Row>
                        <br />
                        <Row gutter={[16, 32]}>
                          {htmlDeclaracionRenta}
                          {htmlEstadosFinancieros}
                        </Row>
                        <br />
                        <Row gutter={[16, 32]}>
                          {htmlDocumentosAnexos}
                          {indicaBF === 'N' && tipoPersona === 'J'
                            ? <>{htmlCertificadoBF}</>
                            : null}
                        </Row>
                      </>
                    )}
                    {tipoProducto === 'C' && (
                      <>
                        <Row gutter={[16, 32]}>
                          {htmlCamaraDeComercio}
                          {htmlRut}
                        </Row>
                        <br />
                        <Row gutter={[16, 32]}>
                          {htmlCedulaRepLegal}
                          {htmlCertificadoCuentaBancaria}
                        </Row>
                        <br />
                        <Row gutter={[16, 32]}>
                          {htmlCompoAccionaria}
                          {htmlEstadosFinancieros}
                        </Row>
                        <br />
                        {indicaBF === 'N' && tipoPersona === 'J' ? (
                          <Row gutter={[16, 32]}>{htmlCertificadoBF}</Row>
                        ) : null}
                      </>
                    )}
                    {tipoProducto === 'FC' && (
                      <>
                        <Row gutter={[16, 32]}>
                          {htmlCamaraDeComercio}
                          {htmlRut}
                        </Row>
                        <br />
                        <Row gutter={[16, 32]}>
                          {htmlCedulaRepLegal}
                          {htmlCertificadoCuentaBancaria}
                        </Row>
                        <br />
                        <Row gutter={[16, 32]}>
                          {htmlDeclaracionRenta}
                          {htmlCompoAccionaria}
                        </Row>
                        <br />
                        <Row gutter={[16, 32]}>
                          {htmlEstadosFinancieros}
                          {htmlDocumentosAnexos}
                        </Row>
                        {indicaBF === 'N' && tipoPersona === 'J' ? (
                          <Row gutter={[16, 32]}>{htmlCertificadoBF}</Row>
                        ) : null}
                      </>
                    )}
                    <br />
                    <>
                      <Row align='middle' justify='center'>
                        <Col span={6}>
                          <Button
                            style={{ width: '100%' }}
                            className='btn btn-blue'
                            type='primary'
                            htmlType='submit'
                          >
                            Enviar Solicitud
                          </Button>
                        </Col>
                      </Row>
                    </>
                  </Form>
                </Card>
              </>
            )}
          </Spin>
        </Col>
      </Row>
    </>
  );
};
