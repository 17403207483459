import React, { Component } from 'react';
import { Row, Col, Button, Table, notification, Space, Form, Switch, Upload, Typography, Divider, Spin } from 'antd';
import { EditOutlined, DeleteOutlined, UploadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import equal from 'fast-deep-equal';
import { v4 as uuidv4 } from 'uuid';
import { tipoIds } from '../../../../../utilities/constants';
import Text from 'antd/lib/typography/Text';
import { grabarCertificadoSagrilaft } from '../../../../../services/proveedor/personServices';
import { connect } from 'react-redux';
import { PersonaAdicionalForm } from '../../../../../components/fichaCliente/juridica/finalBeneficiaries/personasAdicionalesForm';

const { Title } = Typography;
const mensajecargado = " ya has cargado este archivo anteriormente.";


const initialState = {
  beneficiariosFinales: [],
  visibleModal: false,
  snIndicaBF:true,
  fileuploaded:false,
  fileCertificadoSagrilaft:[],
  loading:false
};

let posiblesBen = [];

class FinalBeneficiaries extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'TIPO DE DOCUMENTO',
        dataIndex: 'dsTipoId',
      },
      {
        title: 'IDENTIFICACIÓN',
        dataIndex: 'id',
      },
      {
        title: 'NOMBRE / RAZÓN SOCIAL',
        dataIndex: 'nombre',
      },
      {
        title: 'ELIMINAR',
        dataIndex: '',
        render: (record) => (
          <DeleteOutlined
            className='info'
            onClick={() => this.deleteBeneficiario(record)}
          />
        ),
        showSorterTooltip: false,
      },
    ];
  }

  deleteBeneficiario = (record) => {
    const { beneficiariosFinales } = this.state;
    let accTemp = beneficiariosFinales.filter((value) => value.key !== record.key);
    this.setState({ ...this.state, beneficiariosFinales: accTemp });
  };

  handleOnFinish = () => {
    const { onFinish } = this.props;
    const { beneficiariosFinales,snIndicaBF,fileuploaded } = this.state;

    if (snIndicaBF && beneficiariosFinales.length === 0) {
      notification.warn({
        title: 'Alerta',
        message: 'Debe añadir minimo 1 beneficiario final',
        duration: 4,
      });
      return;
    }else if (!snIndicaBF && !fileuploaded){
      notification.warn({
        title: 'Alerta',
        message: 'Debe cargar y guardar el archivo solicitado',
        duration: 4,
      });
      return;
    }

    let cdIndicaBF = snIndicaBF ? 'S' : 'N';

    onFinish('6', { beneficiariosFinales: beneficiariosFinales,cdIndicaBF: cdIndicaBF });
  };

  handleOnOpenModal = () => {
    this.setState({ ...this.state, visibleModal: true });
  };

  getkey = (data) =>{
      
    let accTemp = [];
    
    if(data && data.length > 0){
      data.forEach((item) => {
        if (!item.key || item.key === '') {
          let uuid = uuidv4();
          item.key =  uuid;
          accTemp.push(item);
        }
      })
    }

    this.setState(prevState => ({
      beneficiariosFinales: [...prevState.beneficiariosFinales, ...accTemp]
    }));
  }

  handleOnFinishModal = (formValue) => {
    let finalBen = [];

      if(formValue.benAdicionales && formValue.benAdicionales.length > 0 ){

        formValue.benAdicionales.forEach((ben) => {
          let benTemp = posiblesBen.find((value) => value.id === ben);
          if(benTemp){
            let benSelected = {
              "nombre":benTemp.nombre,
              "id":benTemp.id,
              "key":undefined,
              "cdTipoPersona" :"BF",
              "cdTipoId":benTemp.tipo_id,
              "dsTipoId":tipoIds.find(value => value.key === benTemp.tipo_id).description
            }
            finalBen.push(benSelected)
          }

        })

      }

      if(formValue.cdTipoId && formValue.id && formValue.nombre && formValue.dsTipoId){
        let benSelected = {
          "nombre":formValue.nombre.toUpperCase(),
          "id":formValue.id,
          "key":undefined,
          "cdTipoPersona" :"BF",
          "cdTipoId":formValue.cdTipoId,
          "dsTipoId":formValue.dsTipoId
        }
        finalBen.push(benSelected);
      }

      this.getkey(finalBen);
      this.setState({ ...this.state, visibleModal: false });

    
  };

  handleOnChangeUploadCertificado = (info) => {
    if(info.file.status === 'removed'){
      this.setState({ ...this.state, fileCertificadoSagrilaft:[]});
    }else{
      const fileList = info.fileList.length > 1 ? info.fileList : [info.file];
      if(this.validateSizeExtfile(fileList)){
        this.setState({ ...this.state, fileCertificadoSagrilaft:fileList});
      }
    }
  };

  handleOnCloseModal = () => {
    this.setState({
      ...this.state,
      visibleModal: false,
    });
  };

  handleSwitchChange = (value) => {
    this.setState({ ...this.state, snIndicaBF: value });
  };

  validateSizeExtfile =  (files) => {
    let totalSize = 0;
    let res = true;
    const validFileFormats = ['zip','rar','pdf','jpeg','png','7zip','doc','docx'];
    files && files.forEach(file =>{
      totalSize += file.size;
      if(!validFileFormats.includes(file.name.split('.').pop())){
        notification.warn({
          title: 'Alerta',
          message: "El documento no tiene el formato valido('zip','rar','pdf','jpeg','png','7zip','doc','docx')",
          duration: 4,
        });
        res = false;
      }
    })
    if(totalSize > 10000000){
      notification.warn({
        title: 'Alerta',
        message: "Maximo de tamaño excedido (10MB)",
        duration: 4,
      });
      res = false;
    } 
    return res;
  }

  handleOnFinishSaveCSBF = async () => {
    const { fileCertificadoSagrilaft } = this.state;
    const { authtoken,company } = this.props;
    
    if(fileCertificadoSagrilaft.length === 0){
      notification.warn({
        title: 'Alerta',
        message: 'Por favor cargue el archivo antes de guardarlo',
        duration: 4,
      });
      return;
    }

    this.setState({ ...this.state, loading:true });

    try {
      const formData = new FormData();
      if(fileCertificadoSagrilaft.length > 1){
        fileCertificadoSagrilaft.forEach(file=>{
          formData.append('CSBF', file.originFileObj);
        });
      } else if(fileCertificadoSagrilaft.length === 1){
        formData.append('CSBF', fileCertificadoSagrilaft[0]);
      }

      const response = await grabarCertificadoSagrilaft(authtoken, formData, company);
          notification.success({
            message: 'Guardado',
            description: 'Documento cargado con éxito',
            duration: 4,
      });

      this.setState({ ...this.state, fileCertificadoSagrilaft:[],fileuploaded:true });      
    } catch (error) {
      notification.error({
        title: 'Error',
        message: 'Error guardando documento ' + error,
        duration: 4,
      });   
    }finally {
      this.setState({ ...this.state, loading:false });
    }
    
  };


  getPosiblesBen = (person) =>{
      if(person.dsTipoIdLeg && person.prApellidoLeg 
          && person.prNombreLeg && person.segApellidoLeg && person.idLeg){
            let benfinal = {
              "nombre": `${person.prNombreLeg} ${person.prApellidoLeg} ${person.segApellidoLeg}`,
              "tipo_id": tipoIds.find(item => item.description === person.dsTipoIdLeg).key,
              "id":person.idLeg
            }
          const exist = posiblesBen.some(item => item.id === benfinal.id);
          if (!exist) {
            posiblesBen.push(benfinal);
          } 
      }
   
      if(person.accionistas && person.accionistas.length > 0){
        person.accionistas.forEach(accionista =>{
          let benfinal = {
            "nombre": accionista.nombre.toUpperCase(),
            "tipo_id":accionista.cdTipoId,
            "id":accionista.id
          }
          const exist = posiblesBen.some(item => item.id === benfinal.id);
          if (!exist) {
            posiblesBen.push(benfinal);
          }
        })
      }
  }

  componentDidMount() {
    if (this.props.formValue) {
      this.getPosiblesBen(this.props.formValue);
    }
  }

  componentDidUpdate(props, state) {
    if (!equal(props.formValue, this.props.formValue)) {
      this.getPosiblesBen(this.props.formValue);
    }
  }

  

  render() {
    const { beneficiariosFinales, visibleModal,snIndicaBF,fileCertificadoSagrilaft,loading,fileuploaded } = this.state;
    return (
      <div className='content-container'>
        <div className='form-container card-background-gray card-shadow card-radius'>
        <Form
            name='final_beneficiers'
            layout='vertical'
        >
        <p><b>Indícanos la información de la(s) persona(s) que posee(n) o controlan la compañía y que en su nombre se realizan operaciones</b></p>
            <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                  <Col span={12}>
                    <Space>¿Tu empresa es libre de indicar a sus beneficiarios finales?</Space>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='cdIndicaBF'
                      valuePropName='checked'
                      noStyle
                    >
                        <Switch 
                            checkedChildren='Si' 
                            unCheckedChildren='No' 
                            onChange={this.handleSwitchChange}
                            defaultChecked
                        />
                    </Form.Item>
                  </Col>
            </Row>
            </Form>
            {
                snIndicaBF ? 
                <>
            <Row
              gutter={[16, 16]}
              align='middle'
              justify='end'
              style={{ marginBottom: '16px' }}
            >
              <Col span={6} style={{ textAlign: 'right' }}>
                <Button
                  className='btn-radius'
                  type='primary'
                  onClick={this.handleOnOpenModal}
                >
                  Nuevo Beneficiario
                </Button>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
              <Col flex='auto' style={{ overflow: 'auto' }}>
                <Table
                  style={{ width: '100%' }}
                  columns={this.columnas}
                  dataSource={beneficiariosFinales}
                  pagination={false}
                ></Table>
              </Col>
            </Row>
            </> 
            :
            <>
            <Row>
              <h4 style={{fontWeight:'bold'}}><b style={{color:'green'}}>Carga aquí la certificación firmada por tu oficial de cumplimiento o representante legal que garantice que tu empresa realizó la debida diligencia SAGRILAFT a sus beneficiarios finales</b></h4>
              <br/>
              <Col span={8}>
                <Upload
                    beforeUpload={() => {
                    return false;
                    }}
                    fileList={fileCertificadoSagrilaft}
                    onChange={this.handleOnChangeUploadCertificado}
                    maxCount={5}
                    multiple
                >
                  <Button icon={<UploadOutlined />}>Clic Para Cargar Archivo</Button>
                </Upload>
                {
                  fileuploaded  ? <Text type='secondary'><CheckCircleOutlined color='secondary'/><b style={{color:'gray'}} >{mensajecargado}</b></Text> : ''
                }
              </Col>
            <Col span={6} >
                <Button
                  className='btn-radius'
                  type='primary'
                  onClick={this.handleOnFinishSaveCSBF}
                  style={{marginLeft:'5px'}}
                >
                  Guardar Archivo
                  </Button>
            </Col>
            <Spin spinning={loading}/>
          </Row>
          <Divider/>
          </>
          }
          <Row
            gutter={[16, 16]}
            align='middle'
            justify='center'
            style={{ marginBottom: '16px' }}
          >
            <Col span={6}>
              <Button
                className='form-btn btn-radius'
                type='primary'
                onClick={this.handleOnFinish}
              >
                Siguiente
              </Button>
            </Col>
          </Row>

          <PersonaAdicionalForm
            visible={visibleModal}
            onClose={this.handleOnCloseModal}
            onFinish={this.handleOnFinishModal}
            modalTitle={"Beneficiarios"}
            formName={"beneficiarios_data"}
            personType={'BF'}
            posiblesBen={posiblesBen}
          />

          
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authtoken: state.auth.auth.access_token,
    company: state.billing.company,
  };
};

export default connect(mapStateToProps, {})(FinalBeneficiaries);

