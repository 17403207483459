import React from 'react';
import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Switch,
} from 'antd';
import {
  requiredValueMessage,
  digitsMessagge,
  digitsPattern,
} from '../../../../utilities/admin_validation_messages';
import { DATE_FORMAT } from '../../../../utilities/messages';
import {
  currencyInputFormat,
  currencyInputParser,
  percentageInputFormat,
  percentageInputParser,
} from '../../../../utilities/textTools';
import * as numberValidate from '../../../../utilities/validate/numberValidate';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const rules = {
  cdTipoEmpresa: [{ required: true, message: requiredValueMessage() }],
  cdSociedadComercial: [{ required: true, message: requiredValueMessage() }],
  cdActividad: [{ required: true, message: requiredValueMessage() }],
  cdRegimen: [{ required: true, message: requiredValueMessage() }],
  cdTipoRetencion: [{ required: true, message: requiredValueMessage() }],
  camaraDeComercio: [{ required: true, message: requiredValueMessage() }],
  cdSituacionTributaria: [{ required: true, message: requiredValueMessage() }],
  nmIngresos: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: digitsPattern(25, 8),
      message: digitsMessagge(25, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  vrBaseReteIva: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: digitsPattern(24, 8),
      message: digitsMessagge(24, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  vrBaseReteIca: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: digitsPattern(24, 8),
      message: digitsMessagge(24, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  poReteIca: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => numberValidate.range(value, 0, 100),
    },
    {
      pattern: digitsPattern(3, 2),
      message: digitsMessagge(3, 2),
    },
  ],
};

const rules2 = {
  cdTipoEmpresa: [{ required: false, message: requiredValueMessage() }],
  cdSociedadComercial: [{ required: false, message: requiredValueMessage() }],
  cdActividad: [{ required: false, message: requiredValueMessage() }],
  cdRegimen: [{ required: false, message: requiredValueMessage() }],
  cdTipoRetencion: [{ required: false, message: requiredValueMessage() }],
  camaraDeComercio: [{ required: false, message: requiredValueMessage() }],
  cdSituacionTributaria: [{ required: false, message: requiredValueMessage() }],
  nmIngresos: [
    {
      pattern: digitsPattern(25, 8),
      message: digitsMessagge(25, 8),
    }
  ],
  vrBaseReteIva: [
    {
      pattern: digitsPattern(24, 8),
      message: digitsMessagge(24, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  vrBaseReteIca: [
    {
      pattern: digitsPattern(24, 8),
      message: digitsMessagge(24, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  poReteIca: [
    {
      validator: (_, value) => numberValidate.range(value, 0, 100),
    },
    {
      pattern: digitsPattern(3, 2),
      message: digitsMessagge(3, 2),
    },
  ],
};

const tipoEmpresas = [
  { key: 'PRIVADA', description: 'Privada' },
  { key: 'PUBLICA', description: 'Pública' },
  { key: 'MIXTA', description: 'Mixta' },
];

const sociedadComercial = [
  { key: 'LIMITADA', description: 'Limitada' },
  { key: 'COLECTIVA', description: 'Colectiva' },
  { key: 'ANONIMA', description: 'Anónima' },
  { key: 'ACCIONES', description: 'En comandita por acciones' },
  { key: 'SIMPLE', description: 'En comandita simple' },
  { key: 'SUCURSAL', description: 'Sucursal de sociedad extranjera' },
  { key: 'UNIPER', description: 'Empresa unipersonal' },
  { key: 'LUCRO', description: 'Entidad sin ánimo de lucro' },
  { key: 'SAS', description: 'SAS' },
  { key: 'OTRO', description: 'Otro' },
];

const regimen = [
  { key: 'C', description: 'Común' },
  { key: 'S', description: 'Simplificado' },
  { key: 'G', description: 'Gran contribuyente' },
];

const tipoRetencion = [
  { key: 'EXENTO', description: 'Exento' },
  { key: 'AUTO', description: 'Autorretenedor' },
  { key: 'SUJETO', description: 'Sujeto de retención' },
];

const situacionTributaria = [
  { key: 'RE', description: 'Regimen especial' },
  { key: 'NC', description: 'No contribuyente' },
  { key: 'C', description: 'Contribuyente' },
];

class PersonEntity extends React.Component {
  render() {
    const {
      actividadSelect,
      cdReteIva,
      cdReteIca,
      onActividadSearch,
      onChangeCdReteIva,
      onChangeCdReteIca,
      companyType,
    } = this.props;
    return (
      <div className='content-container'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdTipoEmpresa'
                label='Tipo de empresa'
                rules={companyType === 'R' ? rules['cdTipoEmpresa'] : rules2['cdTipoEmpresa']}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {tipoEmpresas.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='cdSociedadComercial'
                label='Sociedad comercial o civil'
                rules={companyType === 'R' ? rules['cdSociedadComercial'] : rules2['cdSociedadComercial']}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {sociedadComercial.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdActividad'
                label='Tipo de actividad'
                rules={companyType === 'R' ? rules['cdActividad'] : rules2['cdActividad']}
              >
                <Select
                  placeholder={SELECT_PLACE_HOLDER}
                  className='module-selector-select'
                  showSearch
                  allowClear
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={onActividadSearch}
                  notFoundContent={
                    actividadSelect.fetching ? <Spin size='small' /> : null
                  }
                >
                  {actividadSelect.options.map((ciudad, i) => (
                    <Select.Option key={i} value={ciudad.codigo}>
                      {ciudad.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='feConsAct'
                label='Fecha de constitución'
                rules={companyType === 'R' ? rules['feConsAct'] :  rules2['feConsAct']}
              >
                <DatePicker
                  className='radius'
                  format={DATE_FORMAT}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='lugarAct'
                label='Lugar de constitución'
                rules={companyType === 'R' ? rules['lugarAct'] : rules2['lugarAct']}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='feVigenciaAct'
                label='Fecha de vigencia'
                rules={companyType === 'R' ? rules['feVigenciaAct'] : rules2['feVigenciaAct']}
              >
                <DatePicker
                  className='radius'
                  format={DATE_FORMAT}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='camaraDeComercio'
                label='Cámara de comercio donde se encuentra registrada'
                rules={companyType === 'R' ? rules['camaraDeComercio'] : rules2['camaraDeComercio']}
              >
                <Input minLength={4} placeholder='Nombre' />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='nmEmpleados'
                label='Número de empleados'
                rules={companyType === 'R' ? rules['nmEmpleados'] : rules2['nmEmpleados']}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdRegimen'
                label='Regimen tributario'
                rules={companyType === 'R' ? rules['cdRegimen'] : rules2['cdRegimen']}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {regimen.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='nmIngresos'
                label='Ingresos mensuales del ultimo año'
                rules={companyType === 'R' ? rules['nmIngresos'] : rules2['nmIngresos']}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  formatter={(value) => currencyInputFormat(value)}
                  parser={(value) => currencyInputParser(value)}
                  stringMode={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdSituacionTributaria'
                label='Situación tributaria'
                rules={companyType === 'R' ? rules['cdSituacionTributaria'] : rules2['cdSituacionTributaria']}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {situacionTributaria.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='cdTipoRetencion'
                label='Tipo de retención'
                rules={companyType === 'R' ? rules['cdTipoRetencion'] : rules2['cdTipoRetencion']}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {tipoRetencion.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item>
                <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                  <Col span={12}>
                    <Space>Retenedor Fuente:</Space>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='cdReteFuente'
                      valuePropName='checked'
                      noStyle
                    >
                      <Switch checkedChildren='Si' unCheckedChildren='No' />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                  <Col span={12}>
                    <Space>Operación con moneda extranjera:</Space>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='cdSnOpMontExt'
                      valuePropName='checked'
                      noStyle
                    >
                      <Switch checkedChildren='Si' unCheckedChildren='No' />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item>
                <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                  <Col span={12}>
                    <Space>Retenedor IVA:</Space>
                  </Col>
                  <Col span={12}>
                    <Form.Item name='cdReteIva' valuePropName='checked' noStyle>
                      <Switch
                        checkedChildren='Si'
                        unCheckedChildren='No'
                        onChange={onChangeCdReteIva}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            {cdReteIva && (
              <Col span={12}>
                <Form.Item
                  name='vrBaseReteIva'
                  label='Base IVA'
                  rules={companyType === 'R' ? rules['vrBaseReteIva'] : rules2['vrBaseReteIva']}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => currencyInputFormat(value)}
                    parser={(value) => currencyInputParser(value)}
                    stringMode={true}
                    min={0}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item>
                <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                  <Col span={12}>
                    <Space>Retenedor ICA:</Space>
                  </Col>
                  <Col span={12}>
                    <Form.Item name='cdReteIca' valuePropName='checked' noStyle>
                      <Switch
                        checkedChildren='Si'
                        unCheckedChildren='No'
                        onChange={onChangeCdReteIca}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            {cdReteIca && (
              <>
                <Col span={6}>
                  <Form.Item
                    name='vrBaseReteIca'
                    label='Base ICA'
                    rules={companyType === 'R' ? rules['vrBaseReteIca'] : rules2['vrBaseReteIca']}

                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={(value) => currencyInputFormat(value)}
                      parser={(value) => currencyInputParser(value)}
                      stringMode={true}
                      min={0}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name='poReteIca'
                    label='Porcentaje ICA'
                    rules={companyType === 'R' ? rules['poReteIca'] : rules2['poReteIca']}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      max={100}
                      min={0}
                      formatter={(value) => percentageInputFormat(value)}
                      parser={(value) => percentageInputParser(value)}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
          <Row gutter={16} style={{ marginTop: '2rem' }}>
            <Col>
              <p>Declaro tener un sistema de riesgos de LAFT:</p>
              <Form.Item name='cdSnSarlaft' valuePropName='checked' noStyle>
                <Switch checkedChildren='Si' unCheckedChildren='No' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: '2rem' }}>
            <Col>
              <p>
                ¿La empresa ha sido sancionada o investigada por procesos de
                lavado de activos o de financiación del terrorismo?
              </p>
              <Form.Item name='cdSnSancionado' valuePropName='checked' noStyle>
                <Switch checkedChildren='Si' unCheckedChildren='No' />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  const cdTypeCompany = props.match.params.cdTypeCompany;
  return {
    companyType: cdTypeCompany,
  };
};

export default withRouter(connect(mapStateToProps, {})(PersonEntity));
