import React, { useState } from "react";
import { Row, Col, Form, Input, Rate } from "antd";

export const TipoPreguntaRango = ({
  form,
  pregunta,
  subtitulo,
  rangoNps,
  name
}) => {
  return (
    <>
      <Row justify='center'>
        <Col span={24} style={{ textAlign: "center", fontSize: "15px" }}>
          <p style={{ margin: 0 }}>{pregunta}</p>
        </Col>
        <Col
          span={24}
          style={{ textAlign: "center", fontWeight: "bold", fontSize: "14px" }}
        >
          <p style={{ margin: 0 }}>{subtitulo}</p>
        </Col>
      </Row>
      <Row justify='center'>
        <Col>
          <Form.Item name={name}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <Rate
                count={rangoNps}
                style={{ fontSize: "24px" }}
                onChange={(value) => form.setFieldsValue({ [name]: value })}
              />
            </div>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export const TipoPreguntaAbierta = ({ pregunta, name }) => {
  return (
    <Row justify='center'>
      <Col span={24}>
        <Form.Item name={name} label={pregunta}>
          <Input.TextArea
            rows={4}
            maxLength={1000}
            style={{
              overflowY: "scroll", 
              resize: "none" 
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
