import axios from 'axios';
import { middleWare } from '../middleware';

const baseUrl = process.env.REACT_APP_SERVICES_URL_VINCULACION;

export const grabarDocumentosAnexos = (authtoken, formData, scCompania) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/docAnexos/cargar-archivos/${scCompania}`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authtoken}`,
      },
      data: formData,
    })
  );
};

export const listarDocumentosAnexos = (authToken, scCompania) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/docAnexos/listar-archivos/${scCompania}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  );
};

export const descargarDocumentos = (authToken, scCompania) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/docAnexos/descargar-archivos/${scCompania}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  );
};

export const validarArchivos = (authToken, scCompania) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/docAnexos/validar-archivos/${scCompania}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  );
};

export const actualizarArchivo = (authToken, scCompania, id) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/docAnexos/actualizar-archivo/${scCompania}/${id}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  );
};
