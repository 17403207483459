import React, { useState, useEffect } from 'react';
import {
  Col,
  Form,
  Row,
  Button,
  notification,
  AutoComplete
} from 'antd';


const msjnit = "Deber ser de 9 dígitos numérico";
const msjrs = "Deber ser de 2 a 100 carácteres";

export const validateNit = (nit) => {
  let regex = /^\d{9}$/;
  return regex.test(nit);
}

export const validateRS = (rs) => {
  let regex = /^.{2,100}$/;
  return regex.test(rs);
}

export const ModalClients = (props) => {

  const newclientobj = { "nit": "", "rs": "", "scpag": "" };
  const [clientsCount, setClientsCount] = useState([newclientobj, newclientobj, newclientobj]);
  const [payers, setPayers] = useState([]);
  const [options, setOptions] = useState([]);
  const [form] = Form.useForm();


  const handleOnSearchNit = (value) => {
    if (value && value.length >= 2) {
      let filterbynit = payers.filter(item => {
        const words = item.dsNit.split(' ').slice(0, value.length).join(' ');
        return words.includes(value);
      });
      setOptions(() => {
        return filterbynit.map((pay) => ({
          label: `${pay.dsNit}-${pay.dsRazonSocial}`,
          value: pay.dsNit,
        }));
      });
    }
  }

  const handleOnSearchRs = (value) => {
    if (value && value.length >= 2) {
      let filterbyrs = payers.filter(item => {
        const words = item.dsRazonSocial.split(' ').slice(0, value.length).join(' ').toLowerCase();
        return words.includes(value.toLowerCase());
      });
      setOptions(() => {
        return filterbyrs.map((pay) => ({
          label: `${pay.dsNit}-${pay.dsRazonSocial}`,
          value: pay.dsNit,
        }));
      });
    }
  }


  const handleFinish = () => {
    let counterCompleted = 0;
    let counterIncompleted = 0;
    let counterInvalid = 0;
    let duplicated = 0;
    let nitaux = "";
    for (const item of clientsCount) {
      if (item.nit && item.nit === nitaux) {
        duplicated = duplicated + 1;
      }
      if (item.nit && item.rs) {
        counterCompleted = counterCompleted + 1;
      }
      if (item.nit || item.rs) {
        counterIncompleted = counterIncompleted + 1;
      }
      if (item.msjnit || item.msjrs) {
        counterInvalid = counterInvalid + 1;
      }
      nitaux = item?.nit;
    }


    if (counterCompleted === 0) {
      notification.warning({
        message: 'Alerta',
        description: `Debe ingresar al menos 1 cliente para continuar.`,
        placement: 'topRight',
        duration: 3
      });
      return;
    } else if (counterCompleted !== counterIncompleted) {
      notification.warning({
        message: 'Alerta',
        description: `Debe ingresar nit y razón social en cada cliente.`,
        placement: 'topRight',
        duration: 3
      });
      return;
    } else if (counterInvalid > 0) {
      notification.warning({
        message: 'Alerta',
        description: `Por favor complete correctamente los campos`,
        placement: 'topRight',
        duration: 3
      });
      return;
    } else if (duplicated > 0) {
      notification.warning({
        message: 'Alerta',
        description: `Hay registros duplicados`,
        placement: 'topRight',
        duration: 3
      });
      return;
    }

    let clientsCountcopy = [...clientsCount];
    setOptions([]);
    form.resetFields();
    setClientsCount([newclientobj, newclientobj, newclientobj]);
    props.handleGuardarPag(clientsCountcopy);

  }

  const handleTextInputNit = (value, rowIndex) => {
    let nitValidate = validateNit(value);
    let payerObj = {};
    if (nitValidate) {
      let payerSelected = payers.filter(payer => payer.dsNit === value);
      if (payerSelected.length > 0) {
        payerObj = newclientobj;
        payerObj.nit = payerSelected[0].dsNit;
        payerObj.rs = payerSelected[0].dsRazonSocial;
        payerObj.scpag = payerSelected[0].scPagadorVd;
      }
      setOptions([]);
    }
    setClientsCount(prevClients => {
      const updatedClients = [...prevClients];
      updatedClients[rowIndex] = {
        ...updatedClients[rowIndex],
        nit: value,
        rs: payerObj.rs ? payerObj.rs : '',
        scpag: payerObj.scpag ? payerObj.scpag : '',
        msjnit: value ? !nitValidate : false
      };
      return updatedClients;
    });
  }

  const handleTextInputRs = (value, rowIndex) => {
    let rsValidate = validateRS(value);
    let nitValidate = validateNit(value);
    let payerObj = {};
    if (nitValidate) {
      let payerSelected = payers.filter(payer => payer.dsNit === value);
      if (payerSelected.length > 0) {
        payerObj = newclientobj;
        payerObj.nit = payerSelected[0].dsNit;
        payerObj.rs = payerSelected[0].dsRazonSocial;
        payerObj.scpag = payerSelected[0].scPagadorVd;
      }
      setClientsCount(prevClients => {
        const updatedClients = [...prevClients];
        updatedClients[rowIndex] = {
          ...updatedClients[rowIndex],
          rs: payerObj.rs ? payerObj.rs : '',
          nit: payerObj.nit ? payerObj.nit : '',
          scpag: payerObj.scpag ? payerObj.scpag : '',
          msjrs: false
        };
        return updatedClients;
      });
      setOptions([]);
    } else {
      setClientsCount(prevClients => {
        const updatedClients = [...prevClients];
        updatedClients[rowIndex] = {
          ...updatedClients[rowIndex],
          rs: value,
          msjrs: value ? !rsValidate : false
        };
        return updatedClients;
      });
    }
  }

  useEffect(() => {
    if(props?.pagadores && Array.isArray(props.pagadores)){
      setPayers(props.pagadores);
    }
  }, [])

  return (
    <>
      <Form layout='vertical' form={form} >
        {clientsCount.map((item, rowIndex) => (
          <Row key={rowIndex} gutter={[16, 16]} justify="center">
            <Col span={12}>
              <Form.Item
                style={{ margin: 0, padding: 0 }}
                help={<span style={{color: 'red'}}>{item?.msjnit ? msjnit : null}</span>}
              >
                <AutoComplete
                  style={{ width: '100%' }}
                  onSearch={handleOnSearchNit}
                  onChange={(e) => handleTextInputNit(e, rowIndex)}
                  placeholder="Digite el nit del cliente."
                  options={options}
                  value={item?.nit ? item.nit : ""}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                style={{ margin: 0, padding: 0 }}
                help={<span style={{color: 'red'}}>{item?.msjrs ? msjrs : null}</span>}
              >
                <AutoComplete
                  style={{ width: '100%' }}
                  onSearch={handleOnSearchRs}
                  onChange={(e) => handleTextInputRs(e, rowIndex)}
                  placeholder="Digite el nombre del cliente."
                  options={options}
                  value={item?.rs ? item.rs : ""}
                />
              </Form.Item>
            </Col>
          </Row>
        ))}

        <Row gutter={[8, 8]} justify='center'>
          <Col>
            <Form.Item>
              <Button
                type='primary'
                style={{ width: '100%' }}
                onClick={handleFinish}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row >
      </Form>
    </>
  )
}
