import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Spin,
  Modal,
  notification,
} from 'antd';
import TableReport from '../../../components/tableReport/tableReport';
import { sorterText } from '../../../utilities/textTools';
import '../../../pages/admin/administration/users/users.scss';
import { UsersForm } from './UsersForm/usersForm';
import { useSelector } from 'react-redux';
import {
  crearUsuarioPU,
  editarUsuarioPU,
  eliminarUsuarioPU,
  listaUsuariosPU,
} from '../../../services/vinculacion/usuarioPUService';
import { mensajesEstadoVinculacion } from '../../../components/fichaCliente/estadosVinculacion/mensajesEstadoVin';
import {
  CardEstudio,
  CardCompletoEnviado,
  CardRechazado,
  CardDocIncompleta,
} from '../../../components/fichaCliente/estadosVinculacion/cardsVinculacion';

const Perfiles = [
  {
    codigo: 'Admin',
    descripcion: 'Administrador',
  },
  {
    codigo: 'Factura',
    descripcion: 'Facturas',
  },
  {
    codigo: 'General',
    descripcion: 'General',
  },
];

export const InscripcionUsuariosForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [resultado, setResultado] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [modalState, setModalstate] = useState(false);
  const [modalEditState, setModalEditstate] = useState(false);
  const token = useSelector((state) => state.auth.auth.access_token);
  const scPersona = useSelector((state) => state.auth.auth.scPersona);
  const [scCompania, setscCompania] = useState('');
  const [idUser, setidUser] = useState('');
  const [userData, setUserData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [estadoVinculacion, setEstadoVinculacion] = useState('');

  const columnas = [
    {
      title: 'IDENTIFICACIÓN',
      dataIndex: 'id',
      sorter: (a, b) => sorterText(a.id, b.id),
      showSorterTooltip: false,
    },
    {
      title: 'NOMBRE',
      dataIndex: 'nombre',
      sorter: (a, b) => sorterText(a.nombre, b.nombre),
      showSorterTooltip: false,
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      sorter: (a, b) => sorterText(a.email, b.email),
      showSorterTooltip: false,
    },
    {
      title: 'EDITAR',
      dataIndex: '',
      render: (record) => (
        <Button type='primary' onClick={() => showModalEditar(record)}>
          EDITAR
        </Button>
      ),
      showSorterTooltip: false,
    },
    {
      title: 'ELIMINAR',
      dataIndex: '',
      render: (record) => (
        <Button
          type='primary'
          danger
          onClick={() => handleDelete(record)}
          disabled={record.id === idUser ? true : false}
        >
          ELIMINAR
        </Button>
      ),
      showSorterTooltip: false,
    },
  ];

  const showModalEditar = (user) => {
    setModalEditstate(true);
    setUserData(user);
  };

  const handleDelete = async (record) => {
    setLoading(true);
    try {
      const res = await eliminarUsuarioPU(token, record.scUsuario, record);

      if (res) {
        notification.success({
          title: 'Enviado',
          message: 'Usuario Eliminado',
          duration: 5,
        });

        getData(scCompania);
      }
    } catch (error) {
      notification.error({
        title: 'Error',
        message: 'Error Eliminando Usuario',
        duration: 5,
      });

      setLoading(false);
    }
  };

  const handleOnOpenModal = () => {
    setModalstate(true);
  };

  const handleOnCloseModal = () => {
    setModalstate(false);
  };

  const handleOnCloseEditModal = () => {
    setModalEditstate(false);
  };

  const handleOnFinish = async (value) => {
    setLoading(true);

    value = {
      ...value,
      scCompania: scCompania,
    };

    try {
      const res = await crearUsuarioPU(token, value);

      if (res) {
        notification.success({
          title: 'Enviado',
          message: 'Usuario Creado',
          duration: 5,
        });

        setModalstate(false);
        getData(scCompania);
      }
    } catch (error) {
      notification.error({
        title: 'Error',
        message: 'Error Creando Usuario',
        duration: 5,
      });

      setLoading(false);
    }
  };

  const handleOnEditFinish = async (value) => {
    let jsonPersona = JSON.parse(localStorage.getItem('personData') || '');
    let company = jsonPersona.scCompania;

    setLoading(true);
    try {
      const res = await editarUsuarioPU(token, value.scUsuario, value, company);

      if (res) {
        notification.success({
          title: 'Enviado',
          message: 'Usuario Editado',
          duration: 5,
        });

        setModalEditstate(false);
        getData(scCompania);
      }
    } catch (error) {
      notification.error({
        title: 'Error',
        message: 'Error Editando Usuario',
        duration: 5,
      });

      setLoading(false);
    }
  };

  const getData = async (sccompany) => {
    try {
      const response = await listaUsuariosPU(token, sccompany, getFiltro());

      if (response) {
        setResultado(response.data);
        setTotal(response.metadata.count);

        setLoading(false);
      }
    } catch (error) {
      notification.error({
        title: 'Error',
        message: 'Error Listando Usuarios',
        duration: 5,
      });

      setLoading(false);
    }
  };

  const getFiltro = () => {
    return {
      limit: limit,
      offset: offset,
    };
  };

  useEffect(() => {
    props.menuHandler('2');
    let ls = localStorage.getItem('personData');
    if (ls != null) {
      let personData = JSON.parse(ls);
      setscCompania(personData.scCompania);
      setidUser(personData.idUser);
      setEstadoVinculacion(personData.estadoVinculacion);
      setLoading(true);
      getData(personData.scCompania);
    } else {
      notification.error({
        message: 'Error',
        description: 'Error Buscando Usuarios',
        duration: 5,
      });
    }
  }, []);

  const estadoActual = mensajesEstadoVinculacion.find(
    (mensaje) => mensaje.key === estadoVinculacion
  );

  return (
    <Spin tip='Cargando...' spinning={loading}>
      <div className='users-style'>
        {estadoVinculacion === 'E' && (
          <CardEstudio estadoVin={estadoVinculacion} />
        )}
        {estadoVinculacion === 'DI' && (
          <CardDocIncompleta estadoVin={estadoVinculacion} />
        )}
        {estadoVinculacion === 'CE' && (
          <CardCompletoEnviado estadoVin={estadoVinculacion} />
        )}
        {estadoVinculacion === 'RE' && (
          <CardRechazado estadoVin={estadoVinculacion} />
        )}
        {!(
          estadoVinculacion === 'E' ||
          estadoVinculacion === 'DI' ||
          estadoVinculacion === 'CE' ||
          estadoVinculacion === 'RE'
        ) && (
          <>
            <h3>
              <b>Estado Solicitud: </b> {estadoActual?.description}
            </h3>
            <b style={{ fontSize: '16px' }}>{estadoActual?.message}</b>
            <Card className='card-shadow card-radius default-border welcome-message'>
              <div className='table-container'>
                <Row gutter={[16, 32]} align='middle'>
                  <Col flex='auto'>
                    <Space align='baseline' className='sub-title'>
                      Lista de usuarios
                    </Space>
                  </Col>
                  <Col flex='none'>
                    <span className='button'>
                      <Button
                        size='small'
                        className='btn-agregar'
                        onClick={handleOnOpenModal}
                      >
                        + Agregar nuevo usuario
                      </Button>
                    </span>
                  </Col>
                </Row>
                {modalState && (
                  <Modal
                    title='Nuevo Usuario'
                    visible={modalState}
                    className='modal-style'
                    footer={null}
                    onCancel={handleOnCloseModal}
                  >
                    <Spin tip='Cargando...' spinning={loading}>
                      <UsersForm
                        perfiles={Perfiles}
                        onFinish={handleOnFinish}
                        mode={'create'}
                      />
                    </Spin>
                  </Modal>
                )}
                {modalEditState && (
                  <Modal
                    title='Editar Usuario'
                    visible={modalEditState}
                    className='modal-style'
                    onCancel={handleOnCloseEditModal}
                    footer={null}
                  >
                    <Spin tip='Cargando...' spinning={loading}>
                      <UsersForm
                        perfiles={Perfiles}
                        onFinish={handleOnEditFinish}
                        data={userData}
                        idUser={idUser}
                        mode={'edit'}
                      />
                    </Spin>
                  </Modal>
                )}
                <Divider />
                <Row gutter={16}>
                  <Col span={24}>
                    <TableReport
                      currentPage={currentPage}
                      limit={limit}
                      total={total}
                      columns={columnas}
                      data={resultado}
                    />
                  </Col>
                </Row>
              </div>
            </Card>
          </>
        )}
      </div>
    </Spin>
  );
};

export default InscripcionUsuariosForm;
