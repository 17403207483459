import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Space, Row, Switch, Upload, Table } from 'antd';
import Text from 'antd/lib/typography/Text';
import { InboxOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { PersonaAdicionalForm } from '../finalBeneficiaries/personasAdicionalesForm';
import { v4 as uuidv4 } from 'uuid';


export const DirectorsBoard = ({ onChange, juntadirectiva, snIndicaJD, handleChangeSnIndicaJD, readMode }) => {

  const [modalJD, setModalJD] = useState(false);

  const handleSwitchChange = (value) => {
    handleChangeSnIndicaJD(value);
  }

  const deleteMiembroJD = (record) => {
    if (readMode === true) {
      return;
    }
    let accTemp = juntadirectiva.filter((value) => value.key !== record.key);
    onChange(accTemp);
  }

  const handleOnOpenModal = () => {
    setModalJD(true);
  }

  const handleOnCloseModal = () => {
    setModalJD(false);
  }

  const handleOnFinish = (formValue) => {

    let accTemp = [];

    if (formValue.key && formValue.key !== '') {
      accTemp = juntadirectiva.map((value) =>
        value.key === formValue.key ? formValue : value
      );
    } else {
      let uuid = uuidv4();
      accTemp = [{ ...formValue, key: uuid }, ...juntadirectiva];
    }

    setModalJD(false);
    onChange(accTemp);
  }

  const columnas = [
    {
      title: 'TIPO DE DOCUMENTO',
      dataIndex: 'dsTipoId',
    },
    {
      title: 'IDENTIFICACIÓN',
      dataIndex: 'id',
    },
    {
      title: 'NOMBRE/RAZON SOCIAL',
      dataIndex: 'nombre',
    },
    {
      title: 'ELIMINAR',
      dataIndex: '',
      render: (record) => (
        <DeleteOutlined
          className='info'
          onClick={() => deleteMiembroJD(record)}
        />
      ),
      showSorterTooltip: false,
    },
  ];

  return (
    <div className='content-container'>
      <div className='form-container card-background-gray card-shadow card-radius'>
        <p><b>Indícanos la información de la(s) persona(s) hacen parte de la directiva de tu empresa</b></p>
        <Row justify='start' style={{ paddingTop: '2.5rem' }}>
          <Col span={12}>
            <Space>¿Tu empresa tiene junta directiva?</Space>
          </Col>
          <Col span={12}>
            <Form.Item
              name='cdIndicaJD'
              valuePropName='checked'
              noStyle
            >
              <Switch
                disabled={readMode === true ? true : false}
                checkedChildren='Si'
                unCheckedChildren='No'
                onChange={handleSwitchChange}
                defaultChecked
              />
            </Form.Item>
          </Col>
        </Row>
        {
          snIndicaJD ?

            <>
              <Row
                gutter={[16, 16]}
                align='middle'
                justify='end'
                style={{ marginBottom: '16px' }}
              >
                <Col span={6} style={{ textAlign: 'right' }}>
                  <Button
                    className='btn-radius'
                    type='primary'
                    onClick={handleOnOpenModal}
                    disabled={readMode === true ? true : false}
                  >
                    Agregar Miembro JD
                  </Button>
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
                <Col flex='auto'>
                  <Table
                    columns={columnas}
                    dataSource={juntadirectiva}
                    pagination={false}
                  ></Table>
                </Col>
              </Row>

              <PersonaAdicionalForm
                visible={modalJD}
                onFinish={handleOnFinish}
                onClose={handleOnCloseModal}
                modalTitle={"Miembros JD"}
                formName={"miembrosjd_data"}
                personType={'JD'}
              />
            </>

            :
            <>
            </>
        }
      </div>
    </div>
  )
}
