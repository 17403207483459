import React, { useEffect, useState } from 'react'
import Users from '../../../../admin/administration/users/users'
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Modal,
  notification,
  Row,
  Space,
  Spin,
} from 'antd';
import { connect } from 'react-redux';
import TableReport from '../../../../../components/tableReport/tableReport';
import { listaUsuarios } from '../../../../../services/admin/usersService';
import { BUSQUEDA_TITLE } from '../../../../../utilities/messages';
import { sorterText } from '../../../../../utilities/textTools';
import '../../../../../pages/admin/administration/users/users.scss';
import UsersNewModal from '../../../../../pages/admin/administration/users/usersNewModal/usersNewModal';
import { UsersForm } from '../../../../vinculacion/InscripcionUsuarios/UsersForm/usersForm';
import { useDispatch, useSelector } from "react-redux";
import QueryString from 'qs';
import '../../../../../pages/admin/administration/users/users.scss';

import { useHistory, useParams } from 'react-router-dom';
import {
  crearUsuarioPU,
  editarUsuarioPU,
  eliminarUsuarioPU,
  listaUsuariosPU,
} from '../../../../../services/admin/usuarioPUAdminService';

const Perfiles = [
  {
    codigo: "Admin",
    descripcion: "Administrador"

  },
  {
    codigo: "Factura",
    descripcion: "Facturas"

  },
  {
    codigo: "General",
    descripcion: "General"

  },
];

export const InscripcionUsuariosAdminForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [resultado, setResultado] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [modalState, setModalstate] = useState(false);
  const [modalEditState, setModalEditstate] = useState(false);
  const token = useSelector(state => state.auth.auth.access_token);
  const scPersona = useSelector(state => state.auth.auth.scPersona);
  const [scCompania, setscCompania] = useState("");
  const [idUser, setidUser] = useState("");
  const [userData, setUserData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const params = useParams();
  const history = useHistory();

  const columnas = [
    {
      title: 'IDENTIFICACIÓN',
      dataIndex: 'id',
      sorter: (a, b) => sorterText(a.id, b.id),
      showSorterTooltip: false,
    },
    {
      title: 'NOMBRE',
      dataIndex: 'nombre',
      sorter: (a, b) => sorterText(a.nombre, b.nombre),
      showSorterTooltip: false,
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      sorter: (a, b) => sorterText(a.email, b.email),
      showSorterTooltip: false,
    },
    {
      title: 'EDITAR',
      dataIndex: '',
      render: (record) => (
        <Button type='primary' onClick={() => showModalEditar(record)}>
          EDITAR
        </Button>
      ),
      showSorterTooltip: false,
    },
    {
      title: 'ELIMINAR',
      dataIndex: '',
      render: (record) => (
        <Button
          type='primary'
          danger
          onClick={() => handleDelete(record)}
          disabled={false}
        >
          ELIMINAR
        </Button>
      ),
      showSorterTooltip: false,
    },
  ];

  const showModalEditar = (user) => {
    setModalEditstate(true);
    setUserData(user);
  };

  const redirectToRequests = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const search = QueryString.stringify({
      scSolicitud: queryParameters.get('scSolicitud'),
      tipoSol: queryParameters.get('tipoSol'),
      id_nit: queryParameters.get('id_nit'),
      rs: queryParameters.get('rs'),
      estSol: queryParameters.get('estSol'),
      feDesde: queryParameters.get('feDesde'),
      feHasta: queryParameters.get('feHasta'),
    });
    history.push({
      pathname: `${process.env.PUBLIC_URL}/request/${params.scSolicitud}`,
      search,
    });
  };

  const handleDelete = async (record) => {
    setLoading(true);
    try {
      const res = await eliminarUsuarioPU(token, record.scUsuario);
      if (res) {

        notification.success({
          title: 'Enviado',
          message: 'Usuario Eliminado',
          duration: 5,
        });

        getData(scCompania);
      }
    } catch (error) {
      notification.error({
        title: 'Error',
        message: 'Error Eliminando Usuario',
        duration: 5,
      });

      setLoading(false);
    }
  }

  const handleOnOpenModal = () => {
    setModalstate(true);
  };
  const handleOnCloseModal = () => {
    setModalstate(false);
  };

  const handleOnCloseEditModal = () => {
    setModalEditstate(false);
  }

  const handleOnFinish = async (value) => {
    setLoading(true);

    value = {
      ...value,
      scCompania: scCompania
    }

    try {
      const res = await crearUsuarioPU(token, value);

      if (res) {

        notification.success({
          title: 'Enviado',
          message: 'Usuario Creado',
          duration: 5,
        });

        setModalstate(false);
        getData(scCompania);

      }

    } catch (error) {

      notification.error({
        title: 'Error',
        message: "Error Creando Usuario",
        duration: 5,
      });

      setLoading(false);
    }
  }
  
  const handleOnEditFinish = async (value) => {

    setLoading(true);
    try {

      const res = await editarUsuarioPU(token, value.scUsuario, value);

      if (res) {

        notification.success({
          title: 'Enviado',
          message: 'Usuario Editado',
          duration: 5,
        });

        setModalEditstate(false);
        getData(scCompania);


      }
    } catch (error) {
      notification.error({
        title: 'Error',
        message: "Error Editando Usuario",
        duration: 5,
      });

      setLoading(false);
    }
  }

  const getData = async (sccompany) => {
    try {
      const response = await listaUsuariosPU(
        token,
        sccompany,
        getFiltro()
      );

      if (response) {
        setResultado(response.data);
        setTotal(response.metadata.count);
        setLoading(false);
      }


    } catch (error) {

      notification.error({
        title: 'Error',
        message: "Error Listando Usuarios",
        duration: 5,
      });

      setLoading(false);
    }
  };

  const getFiltro = () => {
    return {
      limit: limit,
      offset: offset,
    };
  };

  useEffect(() => {
    const user = Buffer.from(params.idUser, 'base64').toString('utf8');
    const compania = Buffer.from(params.scCompania, 'base64').toString('utf8');
    setscCompania(compania);
    setidUser(user);
    setLoading(true);
    getData(compania);
  }, []);
  return (
    <Spin tip='Cargando...' spinning={loading}>
      <div className='users-style'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <div className='table-container'>
            <Row gutter={[16, 32]} align='middle'>
              <Col flex='auto'>
                <Space align='baseline' className='sub-title'>
                  Lista de usuarios
                </Space>
              </Col>
              <Col flex='none'>
                <span className='button'>
                  <Button
                    size='small'
                    className='btn-agregar'
                    onClick={handleOnOpenModal}
                  >
                    + Agregar nuevo usuario
                  </Button>
                </span>
              </Col>
            </Row>
            {modalState && (
              <Modal
                title='Nuevo Usuario'
                visible={modalState}
                className='modal-style'
                footer={null}
                onCancel={handleOnCloseModal}
              >
                <Spin tip='Cargando...' spinning={loading}>
                  <UsersForm
                    perfiles={Perfiles}
                    onFinish={handleOnFinish}
                    mode={"create"}
                  />
                </Spin>
              </Modal>
            )}
            {modalEditState && (
              <Modal
                title='Editar Usuario'
                visible={modalEditState}
                className='modal-style'
                onCancel={handleOnCloseEditModal}
                footer={null}
              >
                <Spin tip='Cargando...' spinning={loading}>
                  <UsersForm
                    perfiles={Perfiles}
                    onFinish={handleOnEditFinish}
                    data={userData}
                    idUser={idUser}
                    mode={"edit"}
                  />
                </Spin>
              </Modal>
            )}
            <Divider />
            <Row gutter={16}>
              <Col span={24}>
                <TableReport
                  currentPage={currentPage}
                  limit={limit}
                  total={total}
                  columns={columnas}
                  data={resultado}
                //handleChange={handleChange}
                />
              </Col>
            </Row>
          </div>
          <Row>
            <Col span={6}>
              <Form.Item>
                <Button
                  className='form-btn btn-radius'
                  type='primary'
                  onClick={redirectToRequests}
                >
                  Regresar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </div>
    </Spin>
  )
}

export default InscripcionUsuariosAdminForm;
