import React from "react";
import { Row, Col, Button } from "antd";

const NpsMensajeExito = ({ mensaje, onClose }) => {
  return (
    <Row justify="center" align="middle">
      <Col span={24} style={{ textAlign: "center", padding: "20px" }}>
        <h3 style={{ fontWeight: "bold" }}>{mensaje.titulo}</h3>
      </Col>
      <Col span={24} style={{ textAlign: "center", paddingBottom: "10px" }}>
        <p style={{ color: "green", fontWeight: "bold" }}>{mensaje.subtitulo}</p>
      </Col>
      <Col span={24} style={{ textAlign: "center", paddingBottom: "20px" }}>
        <p>{mensaje.cuerpo}</p>
      </Col>
      <Col>
        <Button type="primary" onClick={onClose}>
          Cerrar
        </Button>
      </Col>
    </Row>
  );
};

export default NpsMensajeExito;
