import { Button, Card, Col, Divider, Modal, Row, Space, Spin, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import { tipoSolicitud } from '../../../utilities/tipoSolicitud';
import { FormRequest } from './FormRequest/formRequest';
import { Documentos } from './DocsFormRequest/documentos';
import { estadoSolicitudMsj } from '../../../utilities/estadoSolicitudMsj';

export const OldRequest = (props) => {
    const [loading, setloading] = useState(false);
    const [tipoSol, setTipoSol] = useState("");
    const [scSolicitud, setScsolicitud] = useState("");
    const [desPro, setDesPro] = useState("");
    const [solState, setSolState] = useState("");
    const [provState, setProvState] = useState(false);
    const params = useParams();

    const solproveedor = {
      cdOrigenSol : 'S'
    }
    
    const changeStatesol = (state) =>{
      setSolState(state);
    }

    useEffect(() => {
        const estado = Buffer.from(params.cdEstado,'base64').toString('utf8');
        const tipoSol = Buffer.from(params.tipoSolicitud,'base64').toString('utf8');
        const scSolicitud = Buffer.from(params.scSolicitud,'base64').toString('utf8');
        setScsolicitud(scSolicitud);
        setTipoSol(tipoSol);
        setSolState(estado);
        const found = tipoSolicitud.find((element) => element.key === tipoSol);
        if (found != undefined){
          setDesPro(found.description);
        }
    }, [params.tipoSolicitud])
    
  return (
    <Spin tip='Cargando...' spinning={loading}>
        <div className='users-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <div className='table-container'>
              <Row gutter={[16, 32]} align='middle'>
                <Col flex='auto'>
                  <Space align='baseline' className='sub-title'>
                    Solicitud - {desPro}
                  </Space>
                </Col>
              </Row>
              <Divider />
                {
                    solState === 'PD' || solState === 'DI' ?
                        <Documentos tipoSolicitud={tipoSol} 
                                    scSolicitud = {scSolicitud} 
                                    setState={changeStatesol}
                                    estadoSolicitud= {solState}/> :
                    solState === 'SI' ?
                        (<Row gutter={16}>
                            <FormRequest 
                              tipoSolicitud={tipoSol}
                              setState={changeStatesol}
                              readMode={false}
                              recordData={solproveedor}
                             /> 
                        </Row>) : 
                    <h3><b>{estadoSolicitudMsj.find(e => e.key === solState)?.description}</b></h3>
                }
            </div>
          </Card>
        </div>
      </Spin>
  )
}
