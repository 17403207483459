import React, { useState } from 'react'
import { Modal, Row } from 'antd'
import { WHATSAPP_CORP_INIT_NO_FORMAT } from '../utilities/messages';

function LineaWhatsAppModal({showModalLineaWhatsApp}) {

  const [modalLineaWhatsApp, setModalLineaWhatsApp] = useState(showModalLineaWhatsApp);
  const handleOnClose = localStorage.getItem('handleOnClose');

  const OnClose = () => {
     localStorage.setItem('handleOnClose', 'false'); 
    setModalLineaWhatsApp(false); 
  };
  
  return (
    <Modal
      className="content-modal"
      visible={handleOnClose !== "false" ? modalLineaWhatsApp : false}
      footer={false}
      closable
      maskClosable
      onCancel={OnClose}
      style={{ width: "50%", maxWidth: "50%" }} 
      centered
    >
      <Row>
        <a
          href={`https://wa.me/${WHATSAPP_CORP_INIT_NO_FORMAT}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src='https://resources-affirmatum.s3.us-east-1.amazonaws.com/pop-up-2.jpg'
            className='img-linea-whatsapp'
            style={{ maxWidth: "100%", height: "auto", objectFit: "contain" }}
          />
        </a>
      </Row>
    </Modal>
  );
}

export default LineaWhatsAppModal;
