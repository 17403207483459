import axios from 'axios';
import queryString from 'query-string';
import { middleWare } from '../../middleware';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const listarCompañias = (authToken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-companias?${queryString.stringify(filtros)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  );
};

export const reporteSarlaft = (authToken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/sagrilaft/consultaSarlaft?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  );
};

export const reporteSarlaftDetails = (authToken, scCompania) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/sagrilaft/${scCompania}/sarlaftDetails`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  );
};

export const reporteUnusualOperations = (authToken, filtros) => {
  let filters = formatoFechas(filtros);
  console.log(filters); 
  return middleWare(
    axios({
      url: `${baseUrl}/api/operacionesInusuales/consultaOperIn?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  );
};

export const reportExcel = (authToken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/operacionesInusuales/descargarReporte?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  );
};

export const actualizarEstado = (authToken, payload) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/operacionesInusuales/actualizar`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: payload,
    })
  );
};

export const insertarRegistro = (authToken, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/operacionesInusuales/insertar`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: data,
    })
  );
};

const formatoFechas = (filtros) => {
  let empty = {};
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') || nombre.includes('Hasta')) &&
        typeof filtros[nombre] === 'object' &&
        filtros[nombre] !== null
      ) {
        empty[nombre] = filtros[nombre].format('YYYYMMDD');
      } else {
        if (filtros[nombre] !== undefined) {
          empty[nombre] = filtros[nombre];
        }
      }
    }
  });
  return empty;
};
