import axios from 'axios';
import { middleWare } from '../middleware';

const baseUrl = process.env.REACT_APP_SERVICES_URL_VINCULACION;

export const buscarTipoFicha = (authToken, scCompania) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/buscarTipoFicha/${scCompania}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  );
};

export const actualizarModalConsultaRues = (authToken, id) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/aceptarConsultaRues/${id}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  );
};

export const buscarOperacionesLey = (authToken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/medios/buscarOperacionesLey`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const grabarDatosOperacionesLey = (authToken, dataMedio) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/medios/actualizarOperacionesLey`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: dataMedio,
    })
  );
};
