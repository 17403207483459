import React from 'react';
import { Col, DatePicker, Form, Input, Row, Select, Spin } from 'antd';
import {
  requiredValueMessage,
  validateFechaNacimiento,
} from '../../../../utilities/admin_validation_messages';
import { tipoIds, Genero } from '../../../../utilities/constants';
import { DATE_FORMAT } from '../../../../utilities/messages';
import { length, maxLength } from '../../../../utilities/validate/textValidate';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const SELECT_PLACE_HOLDER = 'Seleccionar...';
const regex = '^\\d*$';

const rules = {
  prNombreLeg: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 2, 100),
    },
  ],
  segNombreLeg: [
    {
      validator: (_, value) => length(value, 2, 100),
    },
  ],
  prApellidoLeg: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 2, 100),
    },
  ],
  segApellidoLeg: [
    {
      validator: (_, value) => length(value, 2, 100),
    },
  ],
  cdGenero: [{ required: true, message: requiredValueMessage() }],
  cdTipoIdLeg: [{ required: true, message: requiredValueMessage() }],
  idLeg: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 5, 10),
    },
  ],
  feExpedicionLeg: [{ required: true, message: requiredValueMessage() }],
  lugarExpedicion: [{ required: true, message: requiredValueMessage() }],
  feNacimientoLeg: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => validateFechaNacimiento(value, moment()),
    },
  ],
  cdPaisNacLeg: [{ required: true, message: requiredValueMessage() }],
  cdMunNacLeg: [{ required: true, message: requiredValueMessage() }],
  cargoLeg: [
    { required: true, message: requiredValueMessage() },
  ],
  dirResidenciaLeg: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 5, 300),
    },
  ],
  cdPaisResidenciaLeg: [{ required: true, message: requiredValueMessage() }],
  cdDepResidenciaLeg: [{ required: true, message: requiredValueMessage() }],
  cdMunResidenciaLeg: [{ required: true, message: requiredValueMessage() }],
  telResidenciaLeg: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp(regex),
      message: 'El valor ingresado no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 10, 10),
    },
  ],
  emailLeg: [
    { required: true, message: requiredValueMessage() },
    {
      pattern:
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      message: 'El valor ingresado no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 5, 200),
    },
  ],
};

const rules2 = {
  prNombreLeg: [
    {
      validator: (_, value) => length(value, 2, 100),
    },
  ],
  segNombreLeg: [
    {
      validator: (_, value) => length(value, 2, 100),
    },
  ],
  prApellidoLeg: [
    {
      validator: (_, value) => length(value, 2, 100),
    },
  ],
  segApellidoLeg: [
    {
      validator: (_, value) => length(value, 2, 100),
    },
  ],
  cdGenero: [{ required: false, message: requiredValueMessage() }],
  cdTipoIdLeg: [{ required: false, message: requiredValueMessage() }],
  idLeg: [
    {
      validator: (_, value) => length(value, 5, 10),
    },
  ],
  feExpedicionLeg: [{ required: false, message: requiredValueMessage() }],
  lugarExpedicion: [{ required: false, message: requiredValueMessage() }],
  feNacimientoLeg: [
    {
      validator: (_, value) => validateFechaNacimiento(value, moment()),
    },
  ],
  cdPaisNacLeg: [{ required: false, message: requiredValueMessage() }],
  cdMunNacLeg: [{ required: false, message: requiredValueMessage() }],
  dirResidenciaLeg: [
    {
      validator: (_, value) => length(value, 5, 300),
    },
  ],
  cdPaisResidenciaLeg: [{ required: false, message: requiredValueMessage() }],
  cdDepResidenciaLeg: [{ required: false, message: requiredValueMessage() }],
  cdMunResidenciaLeg: [{ required: false, message: requiredValueMessage() }],
  telResidenciaLeg: [
    {
      pattern: new RegExp(regex),
      message: 'El valor ingresado no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 10, 10),
    },
  ],
  emailLeg: [
    {
      pattern:
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      message: 'El valor ingresado no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 5, 200),
    },
  ],
};

class Legal extends React.Component {
  render() {
    const {
      paises,
      departamentos,
      municipios,
      onChangePais,
      onChangeDepartamento,
      ciudadesSelect,
      onCiudadesSearch,
      onChangePaisNac,
      companyType,
    } = this.props;

    return (
      <div className='content-container'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='prNombreLeg'
                label='Primer nombre'
                rules={
                  companyType === 'R'
                    ? rules['prNombreLeg']
                    : rules2['prNombreLeg']
                }
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name='segNombreLeg'
                label='Segundo nombre'
                rules={
                  companyType === 'R'
                    ? rules['segNombreLeg']
                    : rules2['segNombreLeg']
                }
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='prApellidoLeg'
                label='Primer apellido'
                rules={
                  companyType === 'R'
                    ? rules['prApellidoLeg']
                    : rules2['prApellidoLeg']
                }
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='segApellidoLeg'
                label='Segundo apellido'
                rules={
                  companyType === 'R'
                    ? rules['segApellidoLeg']
                    : rules2['segApellidoLeg']
                }
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='cdGenero'
                label='Genero'
                rules={
                  companyType === 'R' ? rules['cdGenero'] : rules2['cdGenero']
                }
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {Genero.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdTipoIdLeg'
                label='Tipo de documento'
                rules={
                  companyType === 'R'
                    ? rules['cdTipoIdLeg']
                    : rules2['cdTipoIdLeg']
                }
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {tipoIds.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='idLeg'
                label='Número de identificación'
                rules={companyType === 'R' ? rules['idLeg'] : rules2['idLeg']}
              >
                <Input type='number' />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='feExpedicionLeg'
                label='Fecha de expedición'
                rules={
                  companyType === 'R'
                    ? rules['feExpedicionLeg']
                    : rules2['feExpedicionLeg']
                }
              >
                <DatePicker
                  className='radius'
                  allowClear
                  style={{ width: '100%' }}
                  format={DATE_FORMAT}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='lugarExpedicion'
                label='Lugar de expedición'
                rules={
                  companyType === 'R'
                    ? rules['lugarExpedicion']
                    : rules2['lugarExpedicion']
                }
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='feNacimientoLeg'
                label='Fecha de nacimiento'
                rules={
                  companyType === 'R'
                    ? rules['feNacimientoLeg']
                    : rules2['feNacimientoLeg']
                }
              >
                <DatePicker
                  className='radius'
                  allowClear
                  style={{ width: '100%' }}
                  format={DATE_FORMAT}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name='cdPaisNacLeg'
                label='País de nacimiento'
                rules={
                  companyType === 'R'
                    ? rules['cdPaisNacLeg']
                    : rules2['cdPaisNacLeg']
                }
              >
                <Select
                  placeholder={SELECT_PLACE_HOLDER}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={onChangePaisNac}
                >
                  {paises.map((item, i) => (
                    <Select.Option key={i} value={item.codigo}>
                      {item.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdMunNacLeg'
                label='Ciudad de nacimiento'
                rules={
                  companyType === 'R'
                    ? rules['cdMunNacLeg']
                    : rules2['cdMunNacLeg']
                }
              >
                <Select
                  placeholder={SELECT_PLACE_HOLDER}
                  className='module-selector-select'
                  showSearch
                  allowClear
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={(value) => onCiudadesSearch(value)}
                  notFoundContent={
                    ciudadesSelect.fetching ? <Spin size='small' /> : null
                  }
                >
                  {ciudadesSelect.options.map((ciudad, i) => (
                    <Select.Option key={i} value={ciudad.codigo}>
                      {ciudad.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='cargoLeg'
                label='Cargo u oficio'
                rules={
                  companyType === 'R' ? rules['cargoLeg'] : rules2['cargoLeg']
                }
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='dirResidenciaLeg'
                label='Dirección de contacto'
                rules={
                  companyType === 'R'
                    ? rules['dirResidenciaLeg']
                    : rules2['dirResidenciaLeg']
                }
              >
                <Input minLength={5} maxLength={300} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='cdPaisResidenciaLeg'
                label='País'
                rules={
                  companyType === 'R'
                    ? rules['cdPaisResidenciaLeg']
                    : rules2['cdPaisResidenciaLeg']
                }
              >
                <Select
                  placeholder={SELECT_PLACE_HOLDER}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={onChangePais}
                >
                  {paises.map((item, i) => (
                    <Select.Option key={i} value={item.codigo}>
                      {item.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdDepResidenciaLeg'
                label='Departamento'
                rules={
                  companyType === 'R'
                    ? rules['cdDepResidenciaLeg']
                    : rules2['cdDepResidenciaLeg']
                }
              >
                <Select
                  placeholder={SELECT_PLACE_HOLDER}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={
                    departamentos.fetching ? <Spin size='small' /> : null
                  }
                  onChange={onChangeDepartamento}
                >
                  {departamentos.options.map((item, i) => (
                    <Select.Option key={i} value={item.codigo}>
                      {item.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='cdMunResidenciaLeg'
                label='Ciudad/Municipio'
                rules={
                  companyType === 'R'
                    ? rules['cdMunResidenciaLeg']
                    : rules2['cdMunResidenciaLeg']
                }
              >
                <Select
                  placeholder={SELECT_PLACE_HOLDER}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={
                    municipios.fetching ? <Spin size='small' /> : null
                  }
                >
                  {municipios.options.map((item, i) => (
                    <Select.Option key={i} value={item.codigo}>
                      {item.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='telResidenciaLeg'
                label='Teléfono'
                rules={
                  companyType === 'R'
                    ? rules['telResidenciaLeg']
                    : rules2['telResidenciaLeg']
                }
              >
                <Input minLength={7} maxLength={20} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='emailLeg'
                label='Correo electrónico'
                rules={
                  companyType === 'R' ? rules['emailLeg'] : rules2['emailLeg']
                }
              >
                <Input minLength={5} maxLength={200} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const cdTypeCompany = props.match.params.cdTypeCompany;
  return {
    companyType: cdTypeCompany,
  };
};

export default withRouter(connect(mapStateToProps, {})(Legal));
