import axios from "axios";
import { middleWare } from "../middleware";

const baseUrl = process.env.REACT_APP_SERVICES_URL_NPS;

export const obtenerPreguntaNps = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/nps/validar-pregunta`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`
      },
      params: filtros
    })
  );
};

export const grabarRespuesta = (authtoken, respuestas) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/nps/grabar-respuesta`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`
      },
      data: respuestas
    })
  );
};
