import React from 'react';
import { Col, Form, InputNumber, Row, Select, Space, Switch } from 'antd';
import {
  digitsMessagge,
  digitsPattern,
  requiredValueMessage,
} from '../../../../utilities/admin_validation_messages';
import {
  currencyInputFormat,
  currencyInputParser,
  percentageInputFormat,
  percentageInputParser,
} from '../../../../utilities/textTools';
import * as numberValidate from '../../../../utilities/validate/numberValidate';
import { length } from '../../../../utilities/validate/textValidate';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const situacionTributaria = [
  { key: 'RE', description: 'Regimen especial' },
  { key: 'NC', description: 'No contribuyente' },
  { key: 'C', description: 'Contribuyente' },
];

const rules = {
  cdSituacionTributaria: [{ required: true, message: requiredValueMessage() }],
  vrBaseReteIva: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: digitsPattern(24, 8),
      message: digitsMessagge(24, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  vrBaseReteIca: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: digitsPattern(24, 8),
      message: digitsMessagge(24, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  poReteIca: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: digitsPattern(3, 2),
      message: digitsMessagge(3, 2),
    },
    {
      validator: (_, value) => numberValidate.range(value, 0, 100),
    },
  ],
};

const rules2 = {
  vrBaseReteIva: [
    {
      pattern: digitsPattern(24, 8),
      message: digitsMessagge(24, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  vrBaseReteIca: [
    {
      pattern: digitsPattern(24, 8),
      message: digitsMessagge(24, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  poReteIca: [
    {
      validator: (_, value) => numberValidate.range(value, 0, 100),
    },
    {
      pattern: digitsPattern(3, 2),
      message: digitsMessagge(3, 2),
    },
  ],
  otraEntidadVigila: [
    {
      validator: (_, value) => length(value, 5, 100),
    },
  ],
};

const entidadVigila = [
  { key: 'SF', description: 'Superintendencia financiera' },
  { key: 'SIC', description: 'Superintendencia de industria y comercio' },
  { key: 'SS', description: 'Superintendencia de sociedades' },
  { key: 'SES', description: 'Superintendencia de economía solidaria' },
  { key: 'OTRA', description: 'Otra' },
];

class TributaryNatural extends React.Component {
  render() {
    const {
      cdReteIva,
      cdReteIca,
      cdEntidadVigila,
      onChangeCdReteIva,
      onChangeCdReteIca,
      onChangeCdEntidadVigilada,
      companyType
    } = this.props;
    return (
      <div className='content-container'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdSituacionTributaria'
                label='Situación tributaria'
                rules={companyType === 'R' ? rules['cdSituacionTributaria'] : rules2['cdSituacionTributaria']}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {situacionTributaria.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                  <Col span={12}>
                    <Space>Gran contribuyente:</Space>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='cdSnGranContribuyente'
                      valuePropName='checked'
                      noStyle
                    >
                      <Switch checkedChildren='Si' unCheckedChildren='No' />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item>
                <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                  <Col span={12}>
                    <Space>Retenedor IVA:</Space>
                  </Col>
                  <Col span={12}>
                    <Form.Item name='cdReteIva' valuePropName='checked' noStyle>
                      <Switch
                        checkedChildren='Si'
                        unCheckedChildren='No'
                        onChange={onChangeCdReteIva}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            {cdReteIva && (
              <Col span={12}>
                <Form.Item
                  name='vrBaseReteIva'
                  label='Base IVA'
                  rules={companyType === 'R' ? rules['vrBaseReteIva'] : rules2['vrBaseReteIva']}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => currencyInputFormat(value)}
                    parser={(value) => currencyInputParser(value)}
                    stringMode={true}
                    min={0}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item>
                <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                  <Col span={12}>
                    <Space>Retenedor Fuente:</Space>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='cdReteFuente'
                      valuePropName='checked'
                      noStyle
                    >
                      <Switch checkedChildren='Si' unCheckedChildren='No' />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item>
                <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                  <Col span={12}>
                    <Space>Retenedor ICA:</Space>
                  </Col>
                  <Col span={12}>
                    <Form.Item name='cdReteIca' valuePropName='checked' noStyle>
                      <Switch
                        checkedChildren='Si'
                        unCheckedChildren='No'
                        onChange={onChangeCdReteIca}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            {cdReteIca && (
              <>
                <Col span={6}>
                  <Form.Item
                    name='vrBaseReteIca'
                    label='Base ICA'
                    rules={companyType === 'R' ? rules['vrBaseReteIca'] : rules2['vrBaseReteIca']}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={(value) => currencyInputFormat(value)}
                      parser={(value) => currencyInputParser(value)}
                      stringMode={true}
                      min={0}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name='poReteIca'
                    label='Porcentaje ICA'
                    rules={companyType === 'R' ? rules['poReteIca'] : rules2['poReteIca']}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      max={100}
                      min={0}
                      formatter={(value) => percentageInputFormat(value)}
                      parser={(value) => percentageInputParser(value)}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const cdTypeCompany = props.match.params.cdTypeCompany;
  return {
    companyType : cdTypeCompany
  };
};

export default withRouter(connect(mapStateToProps, {})(TributaryNatural));

