import React from 'react';
import { Col, Form, Input, Row, Select, Spin, DatePicker } from 'antd';
import { DATE_FORMAT } from '../../../../utilities/messages';
import { length } from '../../../../utilities/validate/textValidate';
import {
  emailPattern,
  formatValueMessage,
  maxValueMessage,
  requiredValueMessage,
} from '../../../../utilities/admin_validation_messages';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const SELECT_PLACE_HOLDER = 'Seleccionar...';
const regex = '^\\d*$';

const rules = {
  feElaboracion: [{ required: true, message: requiredValueMessage() }],
  razonSocial: [
    { required: true, requiredValueMessage },
    {
      validator: (_, value) => length(value, 2, 300),
    },
  ],
  nit: [
    { required: true, requiredValueMessage },
    {
      pattern: new RegExp('^\\d{9}$'),
      message: 'El NIT debe constar de 9 dígitos numéricos.',
    },
  ],
  dirEntidad: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 5, 300),
    },
  ],
  cdPaisEntidad: [{ required: true, message: requiredValueMessage() }],
  cdDepEntidad: [{ required: true, message: requiredValueMessage() }],
  cdMunEntidad: [{ required: true, message: requiredValueMessage() }],
  tel1Entidad: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp(regex),
      message: 'El valor ingresado no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 7, 20),
    },
  ],
  ext1Entidad: [
    {
      pattern: new RegExp(regex),
      message: 'El valor ingresado no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 1, 10),
    },
  ],
  tel2Entidad: [
    {
      pattern: new RegExp(regex),
      message: 'El valor ingresado no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 10, 10),
    },
  ],
  emailEntidad: [
    { required: true, message: requiredValueMessage() },
    {
      pattern:
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      message: 'El valor ingresado no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 5, 200),
    },
  ],
  emailFacturacion: [
    { required: true, message: requiredValueMessage() },
    { max: 100, message: maxValueMessage(100) },
    { pattern: emailPattern, message: formatValueMessage() },
  ],
};

const rules2 = {
  feElaboracion: [{ required: false, message: requiredValueMessage() }],
  razonSocial: [
    { required: false, requiredValueMessage },
    {
      validator: (_, value) => length(value, 2, 300),
    },
  ],
  nit: [
    { required: false, requiredValueMessage },
    {
      pattern: new RegExp('^\\d{9}$'),
      message: 'El NIT debe constar de 9 dígitos numéricos.',
    },
  ],
  dirEntidad: [
    {
      validator: (_, value) => length(value, 5, 300),
    },
  ],
  cdPaisEntidad: [{ required: false, message: requiredValueMessage() }],
  cdDepEntidad: [{ required: false, message: requiredValueMessage() }],
  cdMunEntidad: [{ required: false, message: requiredValueMessage() }],
  tel1Entidad: [
    {
      pattern: new RegExp(regex),
      message: 'El valor ingresado no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 7, 20),
    },
  ],
  ext1Entidad: [
    {
      pattern: new RegExp(regex),
      message: 'El valor ingresado no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 1, 10),
    },
  ],
  tel2Entidad: [
    {
      pattern: new RegExp(regex),
      message: 'El valor ingresado no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 10, 10),
    },
  ],
  emailEntidad: [
    {
      pattern:
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      message: 'El valor ingresado no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 5, 200),
    },
  ],
  emailFacturacion: [
    { required: false, message: requiredValueMessage() },
    { max: 100, message: maxValueMessage(100) },
    { pattern: emailPattern, message: formatValueMessage() },
  ],
};

class GeneralEntityData extends React.Component {
  render() {
    const {
      viewFeElabora,
      paises,
      departamentos,
      municipios,
      onChangePais,
      onChangeDepartamento,
    } = this.props;

    const { companyType } = this.props;

    return (
      <div className='content-container'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          {viewFeElabora && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='feElaboracion'
                  label='Fecha de elaboración'
                  rules={
                    companyType === 'R'
                      ? rules['feElaboracion']
                      : rules2['feElaboracion']
                  }
                >
                  <DatePicker
                    disabled
                    className='radius'
                    format={DATE_FORMAT}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='razonSocial'
                label='Razón o denominación social'
                rules={
                  companyType === 'R'
                    ? rules['razonSocial']
                    : rules2['razonSocial']
                }
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                name='nit' 
                label='NIT' 
                rules={
                  companyType === 'R'
                    ? rules['nit']
                    : rules2['nit']
                }
              >      
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='dirEntidad'
                label='Dirección'
                rules={
                  companyType === 'R'
                    ? rules['dirEntidad']
                    : rules2['dirEntidad']
                }
              >
                <Input minLength={5} maxLength={300} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='cdPaisEntidad'
                label='País'
                rules={
                  companyType === 'R'
                    ? rules['cdPaisEntidad']
                    : rules2['cdPaisEntidad']
                }
              >
                <Select
                  placeholder={SELECT_PLACE_HOLDER}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={onChangePais}
                >
                  {paises.map((item, i) => (
                    <Select.Option key={i} value={item.codigo}>
                      {item.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdDepEntidad'
                label='Departamento'
                rules={
                  companyType === 'R'
                    ? rules['cdDepEntidad']
                    : rules2['cdDepEntidad']
                }
              >
                <Select
                  placeholder={SELECT_PLACE_HOLDER}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={
                    departamentos.fetching ? <Spin size='small' /> : null
                  }
                  onChange={onChangeDepartamento}
                >
                  {departamentos.options.map((item, i) => (
                    <Select.Option key={i} value={item.codigo}>
                      {item.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='cdMunEntidad'
                label='Ciudad/Municipio'
                rules={
                  companyType === 'R'
                    ? rules['cdMunEntidad']
                    : rules2['cdMunEntidad']
                }
              >
                <Select
                  placeholder={SELECT_PLACE_HOLDER}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={
                    municipios.fetching ? <Spin size='small' /> : null
                  }
                >
                  {municipios.options.map((item, i) => (
                    <Select.Option key={i} value={item.codigo}>
                      {item.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='tel1Entidad'
                label='Teléfono Entidad'
                rules={
                  companyType === 'R'
                    ? rules['tel1Entidad']
                    : rules2['tel1Entidad']
                }
              >
                <Input minLength={7} maxLength={20} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='ext1Entidad'
                label='Extensión'
                rules={
                  companyType === 'R'
                    ? rules['ext1Entidad']
                    : rules2['ext1Entidad']
                }
              >
                <Input minLength={1} maxLength={10} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='tel2Entidad'
                label='Celular'
                rules={
                  companyType === 'R'
                    ? rules['tel2Entidad']
                    : rules2['tel2Entidad']
                }
              >
                <Input minLength={7} maxLength={20} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='emailEntidad'
                label='Correo Electrónico Entidad'
                rules={
                  companyType === 'R'
                    ? rules['emailEntidad']
                    : rules2['emailEntidad']
                }
              >
                <Input minLength={5} maxLength={200} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='emailFacturacion'
                label='Correo electrónico de facturación'
                rules={
                  companyType === 'R'
                    ? rules['emailEntidad']
                    : rules2['emailEntidad']
                }
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const cdTypeCompany = props.match.params.cdTypeCompany;
  return {
    companyType: cdTypeCompany,
  };
};

export default withRouter(connect(mapStateToProps, {})(GeneralEntityData));
