import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../reportsSagrilaft.scss';
import {
  Spin,
  Divider,
  Card,
  Form,
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  Switch,
  notification,
} from 'antd';
import { sorterText } from '../../../../../../utilities/textTools';
import moment from 'moment';
import TableReport from '../../../../../../components/tableReport/tableReport';
import { ModalEditarEstado } from '../unusualOperationsModalEdit/ModalEditarEstado';
import { ModalInsert } from '../unusualOperationsModalInsert/ModalInsert';

import {
  reporteUnusualOperations,
  reportExcel,
  listarCompañias,
  actualizarEstado,
  insertarRegistro,
} from '../../../../../../services/admin/reports/sagrilaft';
import { FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { setExcel } from '../../../../../../utilities/downloadTools';
import {
  DATE_POST_FORMAT,
  BUSQUEDA_TITLE,
  DATE_FORMAT,
} from '../../../../../../utilities/messages';

const SELECT_PLACE_HOLDER = 'Seleccione...';

const estadosOperInusuales = [
  { key: 'E', description: 'En estudio' },
  { key: 'I', description: 'Inusual' },
  { key: 'S', description: 'Sospechosa' },
];

const initialState = {
  form: {
    feDesdeAlerta: moment().subtract(1, 'days'),
    feHastaAlerta: moment(),
    feDesdeCierre: moment().subtract(1, 'days'),
    feHastaCierre: moment(),
    scCompania: '',
  },
  loading: false,
  resultado: [],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  companiaSelect: {
    fetching: false,
    options: [],
  },
  useCierre: false,
  showModalUpdate: false,
  showModalInsert: false,
  selectedRecord: null,
  data: {},
};

class UnusualOperations extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'NIT',
        dataIndex: 'idPersona',
        sorter: (a, b) => sorterText(a.idPersona, b.idPersona),
        showSorterTooltip: false,
      },
      {
        title: 'COMPAÑIA',
        dataIndex: 'nombrePersona',
        sorter: (a, b) => sorterText(a.nombrePersona, b.nombrePersona),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE ALERTA',
        dataIndex: 'feAlerta',
        sorter: (a, b) => sorterText(a.feAlerta, b.feAlerta),
        showSorterTooltip: false,
      },
      {
        title: 'MOTIVO DE LA ALERTA',
        dataIndex: 'dsMotivo',
        sorter: (a, b) => sorterText(a.dsMotivo, b.dsMotivo),
        showSorterTooltip: false,
      },
      {
        title: 'DETALLES',
        dataIndex: 'dsDetalle',
        sorter: (a, b) => sorterText(a.dsDetalle, b.dsDetalle),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        dataIndex: 'dsEstado',
        sorter: (a, b) => sorterText(a.dsEstado, b.dsEstado),
        showSorterTooltip: false,
      },
      {
        title: 'CIERRE DE LA ALERTA',
        dataIndex: 'fecierre',
        sorter: (a, b) => sorterText(a.fecierre, b.fecierre),
        showSorterTooltip: false,
      },
      {
        title: 'EDITAR',
        render: (record) => (
          <Button
            type='primary'
            title='Resultado'
            onClick={() => this.handleOpenModalUpdate(record)}
          >
            CAMBIAR ESTADO
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];

    this.formRef = React.createRef();
  }

  handleOpenModalUpdate = (record) => {
    this.setState({
      showModalUpdate: true,
      selectedRecord: record,
    });
  };

  handleCloseModalUpdate = () => {
    this.setState({
      showModalUpdate: false,
      selectedRecord: null,
    });
  };

  handleOnFinishCambiarEstado = async (values) => {
    const { authToken } = this.props;

    const { cdEstado, dsObservacion, record } = values;

    const payload = {
      scOperacion: record.scOperacion,
      cdEstado,
      ...(cdEstado === 'I' && { dsObservacion: dsObservacion || '' }),
    };

    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await actualizarEstado(authToken, payload);
      notification.success({
        message: 'Operacion exitosa',
        description: `${response.message}`,
        duration: 5,
      });
      this.searchData();
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Se presentó un error inesperado',
        duration: 5,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
    this.handleCloseModalUpdate();
  };

  handleOpenModalInsert = () => {
    this.setState({
      showModalInsert: true,
    });
  };

  handleCloseModalInsert = () => {
    this.setState({
      showModalInsert: false,
    });
  };

  handleOnFinishInsert = async (values) => {
    const { authToken } = this.props;

    this.setState({
      ...this.state,
      loading: false,
    });

    const data = {
      scPersona: values.scPersona,
      cdEstado: values.cdEstado,
      cdMotivo: values.cdMotivo,
      dsDetalle: values.dsDetalle,
      dsObservacion: values.dsObservacion,
      feAlerta: values.feAlerta,
    }

    try {
      const response = await insertarRegistro(authToken, data);
      notification.success({
        message: 'Operacion exitosa',
        description: `${response.message}`,
        duration: 5,
      });
      this.searchData();
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Se presentó un error inesperado',
        duration: 5,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
    this.handleCloseModalInsert();
  };

  handleOnSearchCompania = async (nombre) => {
    const { authToken } = this.props;
    if (nombre && nombre.length >= 3) {
      const { companiaSelect } = this.state;
      this.setState({
        companiaSelect: {
          ...companiaSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarCompañias(authToken, {
          nombre: nombre,
        });
        this.setState({
          companiaSelect: {
            ...companiaSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          companiaSelect: {
            ...companiaSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleSelectCompania = (compania) => {
    this.setState({
      form: {
        ...this.state.form,
        scCompania: compania,
      },
    });
  };

  handleSelectEstado = (estado) => {
    this.setState({
      form: {
        ...this.state.form,
        estadoOperInsual: estado,
      },
    });
  };

  componentDidMount() {
    const { feDesdeAlerta, feHastaAlerta } = this.state.form;
    this.formRef.current.setFieldsValue({
      fechaRango: [feDesdeAlerta, feHastaAlerta],
      estadoOperInsual: this.state.form.estadoOperInsual,
    });
  }

  handleSubmit = (values) => {
    const { fechaRango, estadoOperInsual } = values;
    const { useCierre } = this.state;
    const dateRangeKey = useCierre ? 'feDesdeCierre' : 'feDesdeAlerta';
    const dateRangeKeyEnd = useCierre ? 'feHastaCierre' : 'feHastaAlerta';

    this.setState(
      (prevState) => ({
        form: {
          ...prevState.form,
          [dateRangeKey]: moment(fechaRango[0]).format(DATE_POST_FORMAT),
          [dateRangeKeyEnd]: moment(fechaRango[1]).format(DATE_POST_FORMAT),
          estadoOperInsual,
        },
        currentPage: initialState.currentPage,
        offset: initialState.offset,
      }),
      () => {
        this.searchData();
      }
    );
  };

  searchData = async () => {
    const { authToken } = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await reporteUnusualOperations(authToken, this.getFiltro());
      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Se presentó un error inesperado ' + error,
        duration: 5,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  exportExcel = async () => {
    const { authToken } = this.props;
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });

      try {
        const response = await reportExcel(
          authToken,
          this.getFiltro()
        );
        setExcel(response, `Operaciones_Inusuales`);
      } catch (error) {
        notification.error({
          message: 'Error',
          description: 'Se presentó un error inesperado',
          duration: 5,
        });
      } finally {
        this.setState({
          loading: false,
        });
      }
    }
  };

  validateForm = () => {
    const { useCierre } = this.state;
    const feDesdeKey = useCierre ? 'feDesdeCierre' : 'feDesdeAlerta';
    const feHastaKey = useCierre ? 'feHastaCierre' : 'feHastaAlerta';
    const feDesde = this.state.form[feDesdeKey];
    const feHasta = this.state.form[feHastaKey];

    if (
      feDesde === '' ||
      feDesde === null ||
      feHasta === '' ||
      feHasta === null
    ) {
      notification.error({
        message: 'Validacion de Campos',
        description: 'Los campos fecha inicial y fecha final son obligatorios',
        duration: 4,
      });
      return false;
    }

    return true;
  };

  getFiltro = () => {
    const { form, limit, offset, sort, useCierre } = this.state;
    const filtro = {
      scCompania: form.scCompania,
      estadoOperInsual: form.estadoOperInsual,
      feDesdeAlerta: form.feDesdeAlerta,
      feHastaAlerta: form.feHastaAlerta,
      feDesdeCierre: form.feDesdeCierre,
      feHastaCierre: form.feHastaCierre,
      limit: limit,
      offset: offset,
      sort: sort,
    };

    if (!useCierre) {
      delete filtro.feDesdeCierre;
      delete filtro.feHastaCierre;
    } else {
      delete filtro.feDesdeAlerta;
      delete filtro.feHastaAlerta;
    }
    
    return filtro;
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  handleSwitchChange = (checked) => {
    this.setState(
      {
        useCierre: checked,
      },
      () => {
        const { feDesdeAlerta, feHastaAlerta, feDesdeCierre, feHastaCierre } =
          this.state.form;
        const dateRange = checked
          ? [moment(feDesdeCierre), moment(feHastaCierre)]
          : [moment(feDesdeAlerta), moment(feHastaAlerta)];
        this.formRef.current.setFieldsValue({
          fechaRango: dateRange,
        });
      }
    );
  };

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resultado,
      companiaSelect,
      useCierre,
      showModalUpdate,
      showModalInsert,
      selectedRecord,
    } = this.state;

    const { authToken } = this.props;

    return (
      <div className='reports-style-f'>
        <Card className='card-shadow card-radius'>
          <div className='align-center'>
            <div className='reports-container'>
              <Spin tip='Cargando...' spinning={loading}>
                <div className='header-container'>
                  <p className='title'>{BUSQUEDA_TITLE}</p>
                  <Form
                    ref={this.formRef}
                    name='content_form'
                    layout='vertical'
                    className='filters-container'
                    onFinish={this.handleSubmit}
                    initialValues={{
                      fechaRango: [
                        moment(this.state.form.feDesdeAlerta),
                        moment(this.state.form.feHastaAlerta),
                      ],
                      estadoOperInsual: this.state.form.estadoOperInsual,
                    }}
                  >
                    <Row gutter={16}>
                      <Col span={24}>
                        <Switch
                          checkedChildren='Fecha Cierre'
                          unCheckedChildren='Fecha Alerta'
                          justify='end'
                          style={{ marginLeft: '25rem' }}
                          checked={useCierre}
                          onChange={this.handleSwitchChange}
                        />
                        <Form.Item
                          name='fechaRango'
                          label={
                            !useCierre
                              ? 'Rango de fechas de alerta'
                              : 'Rango de fechas de cierre'
                          }
                          rules={[
                            {
                              required: true,
                              message:
                                'Por favor seleccione un rango de fechas',
                            },
                          ]}
                        >
                          <DatePicker.RangePicker
                            name='fechaRango'
                            format={DATE_FORMAT}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          label={
                            <span style={{ color: '#828282' }}>Compañía</span>
                          }
                          name='compania'
                          style={{ marginBottom: 0 }}
                        >
                          <Select
                            placeholder={SELECT_PLACE_HOLDER}
                            showSearch
                            allowClear
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={this.handleOnSearchCompania}
                            onChange={this.handleSelectCompania}
                            notFoundContent={
                              companiaSelect.fetching ? (
                                <Spin size='small' />
                              ) : null
                            }
                            style={{ width: '100%' }}
                          >
                            {companiaSelect.options.map((d) => (
                              <Select.Option key={d.codigo} value={d.codigo}>
                                {d.descripcion}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label={
                            <span style={{ color: '#828282' }}>Estado</span>
                          }
                          name='estadoOperInsual'
                          style={{ marginBottom: 0 }}
                        >
                          <Select
                            allowClear
                            style={{ width: '100%' }}
                            onChange={this.handleSelectEstado}
                          >
                            {estadosOperInusuales.map(
                              ({ key, description }) => (
                                <Select.Option key={key} value={key}>
                                  {description}
                                </Select.Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        span={24}
                        style={{ textAlign: 'center', marginTop: 16 }}
                      >
                        <Button
                          htmlType='submit'
                          className='form-btn btn-radius'
                          type='primary'
                          style={{
                            width: '35%',
                            backgroundColor: '#3399ff',
                            marginTop: '1rem',
                          }}
                        >
                          Buscar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
                {this.state.resultado.length > 0 && (
                  <Row gutter={30} className='bill-selection' justify='end'>
                    <Col lg={{ span: 12 }} xl={{ span: 12 }} className='export'>
                      <Button
                        size='medium'
                        className='btn-excel'
                        title='Exportar a excel'
                        onClick={this.exportExcel}
                        icon={<FileExcelOutlined />}
                      ></Button>
                    </Col>
                  </Row>
                )}
                <Row gutter={16}>
                  <Col span={1}>
                    <Button
                      size='medium'
                      className='btn-agregar'
                      onClick={this.handleOpenModalInsert}
                      title='Agregar operacion inusual'
                      icon={<PlusOutlined style={{ color: '#fff' }} />}
                      style={{
                        backgroundColor: '#3399ff',
                        borderRadius: '5rem',
                      }}
                    ></Button>
                  </Col>
                </Row>
                <div className='table-container'>
                  <Divider />
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    resumen={null}
                    columns={this.columnas}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </div>
              </Spin>
            </div>
          </div>
        </Card>
        <div>
          <ModalEditarEstado
            onFinish={this.handleOnFinishCambiarEstado}
            record={selectedRecord}
            visible={showModalUpdate}
            onCancel={this.handleCloseModalUpdate}
          />
          <ModalInsert
            onFinish={this.handleOnFinishInsert}
            authToken={authToken}
            visible={showModalInsert}
            onCancel={this.handleCloseModalInsert}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(UnusualOperations);
