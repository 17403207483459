import {
  Button,
  Card,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Spin,
  Switch,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import { useSelector } from 'react-redux';
import { requiredValueMessage } from '../../../../utilities/admin_validation_messages';
import { totalLength } from '../../../../utilities/validate/textValidate';
import {
  consultarNits,
  downloadTemplate,
  exportarArchivoListaControl,
  exportarArchivoRues,
  exportarArchivoTransunion,
  tipoConsulta,
} from '../../../../services/admin/creditsService';
import moment from 'moment';
import { setExcel } from '../../../../utilities/downloadTools';

const rules = {
  nit: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^\\d{9}$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => totalLength(value, 9),
    },
  ],
  tipoFuente: [{ required: true, message: requiredValueMessage() }],
};

const FORMAT_DATE = 'DDMMYYYY';
const SELECT_PLACE_HOLDER = 'Ingrese un nit...';

const Consultation = ({ menuHandler }) => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [switchState, setSwitchState] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const form = useRef(null);
  const authToken = useSelector((state) => state.auth.auth.access_token);

  const handleSwitchChange = (checked) => {
    setSelectedOption(null);
    setFileList([]);
    setSwitchState(checked);
  };

  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };

  const handleUpload = async () => {
    if (!selectedOption) {
      notification.error({
        message: 'Debe seleccionar una fuente de consulta',
        duration: 4,
      });
      return;
    }

    if (fileList.length <= 0) {
      notification.error({
        message: 'El archivo es requerido',
        duration: 4,
      });
      return;
    }

    if (fileList.length > 1) {
      notification.error({
        message: 'Solo se permite un archivo',
        duration: 4,
      });
      return;
    }

    const formData = new FormData();

    let mustReturn = false;

    fileList.forEach((file) => {
      if (file.name.slice(-3) === 'xls' || file.name.slice(-4) === 'xlsx') {
        formData.append('file', file);
      } else {
        mustReturn = true;
        notification.error({
          message: 'El archivo debe tener formato de excel (xls, xlsx)',
          duration: 4,
        });
      }
    });

    if (mustReturn) {
      return;
    }

    setLoading(true);
    try {
      const response = await consultarNits(authToken, formData, selectedOption);

      response.forEach((msg) => {
        notification.info({
          description: msg,
          duration: 5,
        });
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Error al cargar el archivo',
        error,
        duration: 5,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = () => {
    if (fileList.length > 1) {
      setFileList([...fileList.slice(1)]);
    }
  };

  const downloadTemp = async () => {
    setLoading(true);
    try {
      const response = await downloadTemplate(authToken);
      const url = window.URL.createObjectURL(
        new Blob([response], {
          type: 'application/vnd.ms-excel;charset=ANSI',
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'carga_masiva_fuentes_externas.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Error al descargar el archivo',
        error,
        duration: 5,
      });
    } finally {
      setLoading(false);
    }
  };

  const ejecutarTarea = async () => {
    try {
      const formValue = await form.current.validateFields();
      const optionConfig = options.find((opt) => opt.value === selectedOption);

      if (!optionConfig) return;

      setLoading(true);

      const res = optionConfig.exportFunction
        ? await optionConfig.exportFunction(authToken, formValue.idProveedor)
        : null;

      if (res) setExcel(res, optionConfig.reportName);
    } catch (error) {
      console.log("Error consultando rues:",error);
    } finally {
      setLoading(false);
    }
  };

  const getTipoConsulta = async () => {
    setLoading(true);

    try {
      const response = await tipoConsulta(authToken);

      const staticOptions = {
        TU: {
          exportFunction: exportarArchivoTransunion,
          reportName: 'reporte_transunion',
        },
        LC: {
          exportFunction: exportarArchivoListaControl,
          reportName: 'reporte_lista_control',
        },
        RU: {
          exportFunction: exportarArchivoRues,
          reportName: 'reporte_rues_' + moment().format(FORMAT_DATE),
        },
      };

      setOptions(
        response.map((option) => ({
          value: option.codigo,
          label: option.descripcion,
          ...staticOptions[option.codigo],
        }))
      );
    } catch {
      notification.info({
        message: 'Info',
        description: 'No se pudo obtener el tipo de consulta',
        duration: 5,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    menuHandler('120');
    getTipoConsulta();
  }, []);

  return (
    <>
      <Spin tip='Cargando...' spinning={loading}>
        <Row
          className='consultation-style'
          gutter={16}
          style={{ padding: '20px' }}
        >
          <Col span={24}>
            <Card className='card-shadow card-radius default-border welcome-message'>
              <Form ref={form} layout='vertical'>
                <Form.Item
                  label='Tipo de consulta:'
                  style={{ marginBottom: '16px' }}
                >
                  <Switch
                    checkedChildren='Consulta masiva'
                    unCheckedChildren='Consulta individual'
                    onChange={handleSwitchChange}
                    defaultChecked={false}
                  />
                </Form.Item>
                {!switchState && (
                  <>
                    <Row
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gap: '16px',
                      }}
                    >
                      <Form.Item
                        name='idProveedor'
                        label={
                          <span
                            style={{ fontSize: '14px', lineHeight: '32px' }}
                          >
                            NIT:
                          </span>
                        }
                        rules={rules.nit}
                        required
                        style={{ marginBottom: '0' }}
                      >
                        <Input
                          placeholder={SELECT_PLACE_HOLDER}
                          allowClear
                          style={{
                            height: '32px',
                            padding: '0 12px',
                            fontSize: '14px',
                            boxSizing: 'border-box',
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label={
                          <span
                            style={{ fontSize: '14px', lineHeight: '32px' }}
                          >
                            Fuente de consulta:
                          </span>
                        }
                        rules={rules.tipoFuente}
                        required
                        style={{ marginBottom: '0' }}
                      >
                        <Select
                          placeholder='Seleccione una opción'
                          onChange={handleSelectChange}
                          allowClear
                          value={selectedOption}
                          style={{
                            height: '32px',
                            fontSize: '14px',
                            boxSizing: 'border-box',
                          }}
                        >
                          {options.map(({ value, label }) => (
                            <Select.Option key={value} value={value}>
                              {label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Row>
                    <Row
                      justify='center'
                      style={{ textAlign: 'center', marginTop: '16px' }}
                    >
                      <Button
                        type='primary'
                        onClick={ejecutarTarea}
                        disabled={!selectedOption}
                        style={{
                          width: '160px',
                          height: '32px',
                          fontSize: '14px',
                        }}
                      >
                        Consultar
                      </Button>
                    </Row>
                  </>
                )}
                {switchState && (
                  <>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Dragger
                          multiple={false}
                          onChange={handleChange}
                          onRemove={(file) => {
                            const index = fileList.indexOf(file);
                            const newFileList = fileList.slice();
                            newFileList.splice(index, 1);
                            setFileList(newFileList);
                          }}
                          beforeUpload={(file) => {
                            setFileList((prevState) => [...prevState, file]);
                            return false;
                          }}
                          fileList={fileList}
                        >
                          <p className='ant-upload-drag-icon'>
                            <InboxOutlined />
                          </p>
                          <p className='ant-upload-text'>
                            Suelta el archivo aquí o selecciona alguno
                          </p>
                        </Dragger>
                      </Col>
                    </Row>
                    <Row
                      justify='center'
                      style={{ marginTop: '26px', textAlign: 'center' }}
                    >
                      <Button style={{ margin: 10 }} onClick={downloadTemp}>
                        Descargar plantilla
                      </Button>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: '14px', lineHeight: '32px' }}
                            >
                              Fuente de consulta:
                            </span>
                          }
                          rules={rules.tipoFuente}
                          required
                          style={{ marginBottom: '0' }}
                        >
                          <Select
                            placeholder='Seleccione una opción'
                            onChange={handleSelectChange}
                            allowClear
                            value={selectedOption}
                            style={{
                              height: '32px',
                              fontSize: '14px',
                              boxSizing: 'border-box',
                            }}
                          >
                            {options.map(({ value, label }) => (
                              <Select.Option
                                key={value}
                                value={value}
                                disabled={value !== 'RU'}
                                title={
                                  value !== 'RU'
                                    ? 'No disponible por el momento'
                                    : ''
                                }
                              >
                                {label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Button
                          type='primary'
                          onClick={handleUpload}
                          disabled={!selectedOption}
                          style={{
                            width: '100%',
                            height: '32px',
                            fontSize: '14px',
                            padding: '0',
                            marginTop: '40px',
                          }}
                        >
                          Consultar
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </Form>
            </Card>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default Consultation;
