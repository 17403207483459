import React, { Component } from 'react';
import { Row, Col, Card } from 'antd';
import './dashboard.scss';
import avatar from '../../../assets/img/assisted-man.png';
import LineaWhatsAppModal from '../../../components/modalLineaWhatsApp';

class Dashboard extends Component {
  componentDidMount() {
    this.props.menuHandler('1');
  }
  render() {
    return (
      <div className='dashboard-style'>
        <Card className='card-shadow card-radius default-border welcome-message '>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <p className='dash-welcome'>
                <span>¡Hola {this.props.name}! </span>Bienvenido a Affirmatum
              </p>
            </Col>
          </Row>
          <Row gutter={[16, 16]} justify='center' align='middle'>
            <Col span={9}>
              <img className='dash-avatar' src={avatar} alt='' />
            </Col>
            <Col span={15}>
              <p>
                Te presentamos la nueva versión de Affirmatum ¿Quieres usarlo?
                Todas tus opciones de siempre en un nuevo menú:
                <br />
                <br />
                <ul>
                  <li>
                    <b>Proveedores:</b> encuentra la información de los
                    proveedores asociados a tu compañía.
                  </li>
                  <li>
                    <b>Desembolso:</b> te permite confirmar los pagos realizados
                    a los proveedores.
                  </li>
                  <li>
                    <b>Soporte:</b> puedes visualizar las facturas que
                    Exponencial le ha emitido a tu compañía.
                  </li>
                  <li>
                    <b>Operaciones:</b> te permite ver los convenios en los
                    cuales participa tu compañía, asignar y configurar el cupo y
                    liquidez de cada convenio, generar el archivo para los pagos
                    a los proveedores (ACH) y visualizar el histórico de
                    archivos ACH generados.
                  </li>
                  <li>
                    <b>Reportes:</b> encuentra los diferentes reportes a los
                    cuales puedes acceder.
                  </li>
                  <li>
                    <b>Ayuda:</b> en esta sección encuentra tutoriales y
                    nuestros canales de atención.
                  </li>
                </ul>
                <br />
                ¡Esperamos que disfrutes esta nueva experiencia!
              </p>
            </Col>
          </Row>
        </Card>
        <LineaWhatsAppModal showModalLineaWhatsApp={true}/>
      </div>
    );
  }
}

export default Dashboard;
