import React from 'react';
import { Col, DatePicker, Form, Input, Row, Select, Spin } from 'antd';
import { requiredValueMessage } from '../../../../utilities/admin_validation_messages';
import { tipoIds, Genero } from '../../../../utilities/constants';
import { length } from '../../../../utilities/validate/textValidate';
import { DATE_FORMAT } from '../../../../utilities/messages';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const rules = {
  cdMunElaboracion: [{ required: true, message: requiredValueMessage() }],
  feElaboracion: [{ required: true, message: requiredValueMessage() }],
  prNombre: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 0, 20),
    },
  ],
  segNombre: [
    {
      validator: (_, value) => length(value, 0, 20),
    },
  ],
  prApellido: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 0, 20),
    },
  ],
  segApellido: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 0, 20),
    },
  ],
  cdTipoId: [{ required: true, message: requiredValueMessage() }],
  id: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 0, 20),
    },
    {
      pattern: new RegExp('^[0-9a-zA-Z-]*$'),
      message: 'El valor no tiene el formato correcto',
    },
  ],
  feExpedicion: [{ required: true, message: requiredValueMessage() }],
  lugarExpedicion: [{ required: true, message: requiredValueMessage() }],
};

class PersonalData extends React.Component {
  render() {
    const { ciudadesSelect, onCiudadesSearch } = this.props;

    return (
      <div className='content-container general-style'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdMunElaboracion'
                label='Ciudad de elaboración'
                rules={rules['cdMunElaboracion'] || ''}
              >
                <Select
                  placeholder={SELECT_PLACE_HOLDER}
                  className='module-selector-select'
                  showSearch
                  allowClear
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={(value) => onCiudadesSearch(value)}
                  notFoundContent={
                    ciudadesSelect.fetching ? <Spin size='small' /> : null
                  }
                >
                  {ciudadesSelect.options.map((ciudad, i) => (
                    <Select.Option key={i} value={ciudad.codigo}>
                      {ciudad.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='feElaboracion'
                label='Fecha de elaboración'
                rules={rules['feElaboracion'] || ''}
              >
                <DatePicker
                  className='radius'
                  format={DATE_FORMAT}
                  style={{ width: '100%' }}
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='prNombre'
                label='Primer nombre'
                rules={rules['prNombre'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name='segNombre'
                label='Segundo nombre'
                rules={rules['segNombre'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='prApellido'
                label='Primer apellido'
                rules={rules['prApellido'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='segApellido'
                label='Segundo apellido'
                rules={rules['segApellido'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='cdGenero'
                label='Genero'
                rules={rules['cdGenero'] || ''}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {Genero.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdTipoId'
                label='Tipo de documento'
                rules={rules['cdTipoId'] || ''}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {tipoIds.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='id'
                label='Número de identificación'
                rules={rules['id'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='feExpedicion'
                label='Fecha de expedición'
                rules={rules['feExpedicion'] || ''}
              >
                <DatePicker
                  className='radius'
                  allowClear={false}
                  style={{ width: '100%' }}
                  format={DATE_FORMAT}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='lugarExpedicion'
                label='Lugar de expedición'
                rules={rules['lugarExpedicion'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default PersonalData;
