import {
  Spin,
  Card,
  Row,
  Col,
  Divider,
  Button,
  notification,
  Modal,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  actualizarSolicitud,
  buscarSolicitudes,
  descargarDocumentos,
  getProveedorPU,
  guardarSolFac,
  guardarSolConf,
  cambiarSolAFac,
  cambiarSolAConf,
  getSolicitudesAsociadasVD,
} from '../../../../services/admin/requestServices';
import { FormRequest } from '../../../proveedor/requests/FormRequest/formRequest';
import { useParams } from 'react-router-dom';
import { FormGestionarSolicitud } from './formGestionarSolicitud';
import QueryString from 'qs';
import history from '../../../../services/history';
import { setExportZip } from '../../../../utilities/downloadTools';
import FichaClienteView from './fichaClienteView';
import { RequestView } from './requestView/requestView';
import './requests.scss';
import {
  activarFichaNaturalPU,
  grabarDocumentosFirmadosN,
} from '../../../../services/vinculacion/fichaNaturalPUAdminservice';
import {
  activarFichaJuridicaPU,
  grabarDocumentosFirmadosJ,
} from '../../../../services/vinculacion/fichaJuridicaPUAdminservice';
import { SOL_NO_APROBADA } from '../../../../utilities/messages';
import { ModalDocFirmados } from '../../reports/customer_profile/modalDocsFirmados/ModalDocFirmados';
import moment from 'moment';

export const RequestDetails = () => {
  const [loading, setLoading] = useState(false);
  const [loadingModalDocsFirmados, setLoadingModalDocsFirmados] =
    useState(false);
  const [solicitudData, setSolicitudData] = useState({});
  const [vinData, setVinData] = useState(null);
  const [solicitudesAsoc, setSolAsoc] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [showModalGestionarSol, setShowModalGestionarSol] = useState(false);
  const [showModalFichaCliente, setshowModalFichaCliente] = useState(false);
  const [showModalDocsFirm, setShowModalDocsFirm] = useState(false);
  const [scsolicitud, setScsolicitud] = useState('');
  const token = useSelector((state) => state.auth.auth.access_token);

  const params = useParams();

  const handleRegresar = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const search = QueryString.stringify({
      scSolicitud: queryParameters.get('scSolicitud'),
      tipoSol: queryParameters.get('tipoSol'),
      id_nit: queryParameters.get('id_nit'),
      rs: queryParameters.get('rs'),
      estSol: queryParameters.get('estSol'),
      feDesde: queryParameters.get('feDesde'),
      feHasta: queryParameters.get('feHasta'),
    });
    history.push({
      pathname: `${process.env.PUBLIC_URL}/requests`,
      search,
    });
  };

  const handleDescargarDocs = async () => {
    if (solicitudData.cdEstado === 'PD') {
      notification.warning({
        message: 'Alerta',
        description:
          'No se puede descargar los documentos de una solicitud pendiente de documentación.',
        duration: 4,
      });
      return;
    }
    try {
      setLoading(true);
      const res = await descargarDocumentos(token, scsolicitud);
      setExportZip(res, 'Documentos');
      notification.success({
        message: 'Descargado',
        description: 'Documentos descargados con éxito',
        duration: 4,
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Error descargando documentos: ' + error,
        duration: 4,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleGestionarSolicitud = () => {
    if (solicitudData.cdOrigenSol === 'S' && solicitudData.cdEstado === 'PD') {
      notification.warning({
        message: 'Alerta',
        description:
          'No se puede cambiar el estado a una solicitud Pendiente de documentación.',
        duration: 4,
      });
      return;
    }
    setShowModalGestionarSol(true);
  };

  const handleActivar = async () => {
    if ((buscarSolicitudAprobada()) || 
        (vinData?.estadoFicha === 'C' && vinData?.estadoVinculacion === 'CE')) {
      try {
        setLoading(true);
        let objectData = {
          idUser: vinData.idUser,
          idNit: solicitudData.idNit,
        };
        let res = '';
        if (vinData.cdTipoPersona === 'N') {
          res = await activarFichaNaturalPU(token, [objectData]);
        } else if (vinData.cdTipoPersona === 'J') {
          res = await activarFichaJuridicaPU(token, [objectData]);
        }

        notification.success({
          message: 'Activado',
          description: res.message,
          duration: 4,
        });
      } catch (error) {
        notification.error({
          message: 'Error',
          description: error.message,
          duration: 4,
        });
      } finally {
        setLoading(false);
      }
    } else {
      notification.warning({
        message: 'Alerta',
        description: SOL_NO_APROBADA,
        duration: 4,
      });
    }
  };

  const handleMostrarFichaCliente = () => {
    if (solicitudData.cdOrigenSol === 'VD') {
      const queryParameters = new URLSearchParams(window.location.search);
      const search = QueryString.stringify({
        scSolicitud: queryParameters.get('scSolicitud'),
        tipoSol: queryParameters.get('tipoSol'),
        id_nit: queryParameters.get('id_nit'),
        rs: queryParameters.get('rs'),
        estSol: queryParameters.get('estSol'),
        feDesde: queryParameters.get('feDesde'),
        feHasta: queryParameters.get('feHasta'),
      });
      history.push({
        pathname: `${
          process.env.PUBLIC_URL
        }/reports/customer_profile/fichaClienteEdit/${Buffer.from(
          vinData.idUser
        ).toString('base64')}/${Buffer.from(vinData.cdTipoPersona).toString(
          'base64'
        )}/${Buffer.from(scsolicitud).toString('base64')}/R`,
        search,
      });
    } else {
      setshowModalFichaCliente(true);
    }
  };

  const handleCargarDocsFirmados = async () => {
    if(buscarSolicitudAprobada()){
      setShowModalDocsFirm(true);
    }else if (vinData?.estadoFicha === 'C' && vinData?.estadoVinculacion === 'CE') {
      setShowModalDocsFirm(true);
    } else {
      notification.warning({
        message: 'Alerta',
        description: SOL_NO_APROBADA,
        duration: 4,
      });
    }
  };

  const handleInscripcionUsuarios = () => {
    if (solicitudData.cdOrigenSol === 'VD') {
      const queryParameters = new URLSearchParams(window.location.search);
      const search = QueryString.stringify({
        scSolicitud: queryParameters.get('scSolicitud'),
        tipoSol: queryParameters.get('tipoSol'),
        id_nit: queryParameters.get('id_nit'),
        rs: queryParameters.get('rs'),
        estSol: queryParameters.get('estSol'),
        feDesde: queryParameters.get('feDesde'),
        feHasta: queryParameters.get('feHasta'),
      });
      history.push({
        pathname: `${
          process.env.PUBLIC_URL
        }/reports/customer_profile/inscripcionUsuarios/${Buffer.from(
          solicitudData.scVinDigital
        ).toString('base64')}/${Buffer.from(vinData.idUser).toString(
          'base64'
        )}/${Buffer.from(scsolicitud).toString('base64')}`,
        search,
      });
    }
  };

  const handleOnCloseModal = () => {
    setShowModalGestionarSol(false);
  };

  const handleOnCloseModalFC = () => {
    setshowModalFichaCliente(false);
  };

  const handleUpload = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(fileList);
  };

  const handleOnFinishDocsFirm = async () => {
    let response;

    if (fileList.length > 0) {
      setLoadingModalDocsFirmados(true);
      try {
        if (vinData.cdTipoPersona === 'J') {
          response = await grabarDocumentosFirmadosJ(
            token,
            fileList,
            solicitudData.scVinDigital
          );
        } else if (vinData.cdTipoPersona === 'N') {
          response = await grabarDocumentosFirmadosN(
            token,
            fileList,
            solicitudData.scVinDigital
          );
        }
        notification.success({
          message: 'Documentos cargados con éxito',
          description: response.message,
          duration: 5,
        });
      } catch {
        notification.error({
          message: 'Error',
          description: 'No se logro guardar los cambios',
          duration: 4,
        });
      } finally {
        setLoadingModalDocsFirmados(false);
        setShowModalDocsFirm(false);
        setFileList([]);
      }
    } else {
      setLoading(false);
      notification.warn({
        message: 'Alerta',
        description: 'No hay archivos para guardar',
        duration: 4,
      });
    }
  };

  const buscarSolicitudAprobada  = () => {
    if(Array.isArray(solicitudesAsoc) && solicitudesAsoc.length > 0){
     return solicitudesAsoc.filter(sol => (sol?.estado === 'A' && sol?.tipoSolicitud === solicitudData.cdTipoSolicitud)).length > 0 ? true : false ;
    }
    return false;
  }

  const handleOnFinish = async (data) => {
    setShowModalGestionarSol(false);
    setLoading(true);

    const {
      cdTipoSolicitud,
      scVinDigital,
      cdCentralesRiesgo,
      cdDatosPersonales,
      cdTermYcond,
    } = solicitudData;

    if (!data.tipoGestion) {
      data.tipoGestion = 'CE';
    }

    switch (data.tipoGestion) {
      case 'CE':
        data['cdTipoSolicitud'] = cdTipoSolicitud;

        if (cdTipoSolicitud === 'CSR' && data.cdEstado === 'CE') {
          data.cdEstado = 'A';
          data.feAprobacion = moment(new Date());
        }

        if (data.solExcepcion === undefined || data.solExcepcion === false) {
          data['solExcepcion'] = 'N';
        } else if (data.solExcepcion === true) {
          data['solExcepcion'] = 'S';
        }

        if (data.cdEstado != 'A') {
          delete data.solExcepcion;
        }

        try {
          const res = await actualizarSolicitud(token, scsolicitud, data);
          notification.success({
            message: 'Actualizado',
            description: 'Estado Actualizado con éxito',
            duration: 4,
          });
          getSolicitud(scsolicitud);
        } catch (error) {
          notification.error({
            message: 'Error',
            description: 'Error Actualizando solicitud ' + error,
            duration: 4,
          });
        } finally {
          setLoading(false);
        }

        break;

      case 'NS':
        if (cdTipoSolicitud === 'FA') {
          const sol = {
            cdTipoSolicitud: 'CSR',
            cdCentralesRiesgo,
            cdDatosPersonales,
            cdTermYcond,
            scClientes: vinData.pagadores.map((e) => e.scPagadorVd),
          };

          try {
            const res = await guardarSolConf(token, scVinDigital, sol);
            notification.success({
              message: 'Solicitud creada exitosamente',
              description: `Una nueva solicitud de Confirming fue creada con el consecutivo: ${res.message}`,
              duration: 4,
            });
            getSolicitud(scsolicitud);
          } catch (error) {
            notification.error({
              message: 'Error',
              description: 'Error creando solicitud ' + error,
              duration: 4,
            });
          } finally {
            setLoading(false);
          }
        } else if (cdTipoSolicitud === 'CSR') {
          if (!data.DR || !data.EF) {
            notification.error({
              message: 'Error',
              description: 'Los documentos requeridos son obligatorios',
              duration: 3,
            });
            return;
          }

          const sol = {
            cdTipoSolicitud: 'FA',
            cdCentralesRiesgo,
            cdDatosPersonales,
            cdTermYcond,
            scClientes: vinData.pagadores.map((e) => e.scPagadorVd),
            montoSolicitar: data.montoSolicitar,
          };

          const appendFiles = (formData, key, files) => {
            if (files.length) {
              files.forEach((file) =>
                formData.append(key, file.originFileObj || file)
              );
            }
          };

          try {
            const formData = new FormData();
            appendFiles(formData, 'DR', data.DR);
            appendFiles(formData, 'EF', data.EF);

            const res = await guardarSolFac(token, scVinDigital, sol, formData);

            notification.success({
              message: 'Solicitud creada exitosamente',
              description: `Una nueva solicitud de Factoring fue creada con el consecutivo: ${res.message}`,
              duration: 4,
            });
            getSolicitud(scsolicitud);
          } catch (error) {
            notification.error({
              message: 'Error',
              description: 'Error creando solicitud ' + error,
              duration: 4,
            });
          } finally {
            setLoading(false);
          }
        }

        break;

      case 'CTS':
        if (cdTipoSolicitud === 'FA') {
          try {
            const res = await cambiarSolAConf(token, scsolicitud);

            notification.success({
              message: 'Solicitud actualizada exitosamente',
              description: res.message,
              duration: 4,
            });
            handleRegresar();
          } catch (error) {
            notification.error({
              message: 'Error',
              description: 'Error actualizando solicitud ' + error,
              duration: 4,
            });
          } finally {
            setLoading(false);
          }
        } else if (cdTipoSolicitud === 'CSR') {
          if (!data.DR || !data.EF) {
            notification.error({
              message: 'Error',
              description: 'Los documentos requeridos son obligatorios',
              duration: 3,
            });
            return;
          }

          const appendFiles = (formData, key, files) => {
            if (files.length) {
              files.forEach((file) =>
                formData.append(key, file.originFileObj || file)
              );
            }
          };

          try {
            const formData = new FormData();
            appendFiles(formData, 'DR', data.DR);
            appendFiles(formData, 'EF', data.EF);

            const res = await cambiarSolAFac(
              token,
              scsolicitud,
              data.montoSolicitar,
              formData
            );

            notification.success({
              message: 'Solicitud actualizada exitosamente',
              description: res.message,
              duration: 4,
            });
            handleRegresar();
          } catch (error) {
            notification.error({
              message: 'Error',
              description: 'Error actualizando solicitud ' + error,
              duration: 4,
            });
          } finally {
            setLoading(false);
          }
        }

        break;
    }
  };

  const getSolicitud = async (scsolicitud) => {
    let filtro = {
      scSolicitud: scsolicitud,
    };
    setLoading(true);
    try {
      const res = await buscarSolicitudes(token, filtro);
      setSolicitudData(res.data[0]);
      if (res.data[0]?.cdOrigenSol === 'VD' || res.data[0]?.cdOrigenSol === 'VDS' ) {
        const resVin = await getProveedorPU(token, res.data[0]?.scVinDigital);
        setVinData(resVin);
        if(resVin?.tipoProducto === 'FC'){
          const solAsociadas = await getSolicitudesAsociadasVD(token, res.data[0]?.scVinDigital);
          setSolAsoc(solAsociadas?.data);
        }  
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Error consultando solicitud ' + error,
        duration: 4,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const scSolicitud = Buffer.from(params.scSolicitud, 'base64').toString(
      'utf8'
    );
    setScsolicitud(scSolicitud);
    getSolicitud(scSolicitud);
  }, []);

  return (
    <div className='requests-style'>
      <Spin tip='Cargando...' spinning={loading}>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <h2 align='center'>Solicitud # {scsolicitud}</h2>
          <h3 align='center'>
            Tipo Solicitud: {solicitudData.dsTipoSolicitud}
          </h3>

          {(solicitudData.cdOrigenSol === 'VD' || solicitudData.cdOrigenSol === 'VDS')
            && vinData ? (
            <RequestView data={solicitudData} dataVin={vinData} />
          ) : solicitudData.cdOrigenSol === 'S' ? (
            <FormRequest
              tipoSolicitud={solicitudData.cdTipoSolicitud}
              readMode={true}
              recordData={solicitudData}
            />
          ) : null}

          <Divider />

          <Row align='middle' justify='center' gutter={16}>
            <Col span={6}>
              <Button
                type='primary'
                className='btn btn-blue'
                onClick={handleRegresar}
              >
                Regresar
              </Button>
            </Col>
            <Col span={6}>
              <Button
                type='primary'
                className='btn btn-green'
                onClick={handleDescargarDocs}
              >
                Descargar Documentos
              </Button>
            </Col>
            <Col span={6}>
              <Button
                type='primary'
                className='btn btn-blue'
                onClick={handleGestionarSolicitud}
              >
                Gestionar Solicitud
              </Button>
            </Col>
            <Col span={6}>
              <Button
                type='primary'
                className='btn btn-green'
                onClick={handleMostrarFichaCliente}
              >
                Ficha Cliente
              </Button>
            </Col>
          </Row>

          {solicitudData.cdOrigenSol === 'VD' ? (
            <>
              <br />
              <Row justify='center'>
                <Col span={6}>
                  <Button
                    type='primary'
                    className='btn btn-green'
                    onClick={handleActivar}
                  >
                    Activar
                  </Button>
                </Col>
                <Col span={6}>
                  <Button
                    type='primary'
                    className='btn btn-blue'
                    onClick={handleCargarDocsFirmados}
                  >
                    Cargar Docs Firmados
                  </Button>
                </Col>
                <Col span={6}>
                  <Button
                    type='primary'
                    className='btn btn-green'
                    onClick={handleInscripcionUsuarios}
                  >
                    Inscripcion Usuarios
                  </Button>
                </Col>
              </Row>
            </>
          ) : null}

          {showModalGestionarSol && (
            <Modal
              title='Gestión de Solicitudes'
              visible={showModalGestionarSol}
              className='modal-style'
              footer={null}
              onCancel={handleOnCloseModal}
            >
              <FormGestionarSolicitud
                onFinish={handleOnFinish}
                data={solicitudData}
                dataVin={vinData}
                tipoSolicitud={solicitudData.cdTipoSolicitud}
              />
            </Modal>
          )}
          {showModalFichaCliente && (
            <Modal
              visible={showModalFichaCliente}
              footer={null}
              onCancel={handleOnCloseModalFC}
              width={1000}
            >
              <FichaClienteView
                cdOrigen={solicitudData.cdOrigenSol}
                scCompany={
                  solicitudData.cdOrigenSol === 'VD' 
                    ? solicitudData.scVinDigital
                    : solicitudData.scProveedor
                }
              />
            </Modal>
          )}
          <Modal
            title='Cargar Documentos Firmados'
            visible={showModalDocsFirm}
            className='modal-style'
            footer={null}
            onCancel={() => {
              setShowModalDocsFirm(false);
            }}
          >
            <ModalDocFirmados
              handleUpload={handleUpload}
              handleOnFinish={handleOnFinishDocsFirm}
              fileList={fileList}
              token={token}
              scCompania={solicitudData.scVinDigital}
              toggleLoading={loadingModalDocsFirmados}
            />
          </Modal>
        </Card>
      </Spin>
    </div>
  );
};
