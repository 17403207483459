import React,{useState,useEffect} from 'react';
import { Button, Col, Form, Space, Row, Switch, Upload, Table } from 'antd';
import Text from 'antd/lib/typography/Text';
import { InboxOutlined,DeleteOutlined,UploadOutlined} from '@ant-design/icons';
import { PersonaAdicionalForm } from '../finalBeneficiaries/personasAdicionalesForm';
import { v4 as uuidv4 } from 'uuid';
import { tipoIds } from '../../../../utilities/constants';


export const FinalBeneficiaries = ({ beneficiarios, onChange, snIndicaBF, handleChangeSnIndicaBF,accionistas,person }) => {

    const [modalBF, setModalBF] = useState(false);
    const [posiblesBen, setPosiblesBen] = useState([]);

    const handleSwitchChange = (value) => {
      handleChangeSnIndicaBF(value);
    }

    const deleteBeneficiarie = (record) => {
  
      let accTemp = beneficiarios.filter((value) => value.key !== record.key);
      onChange(accTemp);
    }

    const handleOnOpenModal = () => {
        setModalBF(true);
    }

    const handleOnCloseModal = () => {
        setModalBF(false);
    }

    const getkey = (data) =>{
      
      let accTemp = [];
      
      if(data && data.length > 0){
        data.forEach((item) => {
          if (!item.key || item.key === '') {
            let uuid = uuidv4();
            item.key =  uuid;
            accTemp.push(item);
          }
        })
      }

      beneficiarios.forEach((ben) => {
        if(ben.key && ben.key !== ''){
          accTemp.push(ben);
        }
      });

      onChange(accTemp);

    }

    const handleOnFinish = (formValue) => {

      let finalBen = [];

      if(formValue.benAdicionales && formValue.benAdicionales.length > 0 ){

        formValue.benAdicionales.forEach((ben) => {
          let benTemp = posiblesBen.find((value) => value.id === ben);
          if(benTemp){
            let benSelected = {
              "nombre":benTemp.nombre,
              "id":benTemp.id,
              "key":undefined,
              "cdTipoPersona" :"BF",
              "cdTipoId":benTemp.tipo_id,
              "dsTipoId":tipoIds.find(value => value.key === benTemp.tipo_id).description
            }
            finalBen.push(benSelected)
          }

        })

      }

      if(formValue.cdTipoId && formValue.id && formValue.nombre && formValue.dsTipoId){
        let benSelected = {
          "nombre":formValue.nombre.toUpperCase(),
          "id":formValue.id,
          "key":undefined,
          "cdTipoPersona" :"BF",
          "cdTipoId":formValue.cdTipoId,
          "dsTipoId":formValue.dsTipoId
        }
        finalBen.push(benSelected);
      }

      getkey(finalBen);
      setModalBF(false);
    }

    useEffect(() => {
      let posiblesBen = [];
      if(person.cdTipoIdLeg && person.prApellidoLeg 
          && person.prNombreLeg && person.segApellidoLeg && person.idLeg){
            let benfinal = {
              "nombre": `${person.prNombreLeg} ${person.prApellidoLeg} ${person.segApellidoLeg}`,
              "tipo_id":person.cdTipoIdLeg,
              "id":person.idLeg
            }
        posiblesBen.push(benfinal);
      }

      if(accionistas && accionistas.length > 0){

        accionistas.forEach(accionista =>{
          let benfinal = {
            "nombre": accionista.nombre.toUpperCase(),
            "tipo_id":accionista.cdTipoId,
            "id":accionista.id
          }
          posiblesBen.push(benfinal);
        })
      }

      setPosiblesBen(posiblesBen);
      
    }, [])
  
    const columnas = [
        {
          title: 'TIPO DE DOCUMENTO',
          dataIndex: 'dsTipoId',
        },
        {
          title: 'IDENTIFICACIÓN',
          dataIndex: 'id',
        },
        {
          title: 'NOMBRE/RAZON SOCIAL',
          dataIndex: 'nombre',
        },
        {
          title: 'ELIMINAR',
          dataIndex: '',
          render: (record) => (
            <DeleteOutlined
              className='info'
              onClick={() => deleteBeneficiarie(record)}
            />
          ),
          showSorterTooltip: false,
        },
      ];

  return (
    <div className='content-container'>
        <div className='form-container card-background-gray card-shadow card-radius'>
            <p><b>Indícanos la información de la(s) persona(s) que posee(n) o controlan la compañía y que en su nombre se realizan operaciones</b></p>
            <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                  <Col span={12}>
                    <Space>¿Tu empresa es libre de indicar a sus beneficiarios finales?</Space>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='cdIndicaBF'
                      valuePropName='checked'
                      noStyle
                    >
                        <Switch 
                            checkedChildren='Si' 
                            unCheckedChildren='No' 
                            onChange={handleSwitchChange}
                            defaultChecked
                        />
                    </Form.Item>
                  </Col>
            </Row>
            {
                snIndicaBF ? 
                
                <>
                <Row
                    gutter={[16, 16]}
                    align='middle'
                    justify='end'
                    style={{ marginBottom: '16px' }}
                >
                    <Col span={6} style={{ textAlign: 'right' }}>
                    <Button
                        className='btn-radius'
                        type='primary'
                        onClick={handleOnOpenModal}
                    >
                        Agregar Beneficiario
                    </Button>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
                    <Col flex='auto'>
                    <Table
                        columns={columnas}
                        dataSource={beneficiarios}
                        pagination={false}
                    ></Table>
                    </Col>
                </Row>
                <PersonaAdicionalForm
                    visible={modalBF}
                    onFinish={handleOnFinish}
                    onClose={handleOnCloseModal}
                    modalTitle={"Beneficiarios"}
                    formName={"beneficiarios_data"}
                    personType={'BF'}
                    posiblesBen={posiblesBen}
                />
                </> 
                
                : null
                
            }
        </div>
    </div>
  )
}
