import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect } from 'react';
import {
  emailPattern,
  formatValueMessage,
  maxValueMessage,
  requiredValueMessage,
} from '../../../../utilities/admin_validation_messages';
import { tipoIds } from '../../../../utilities/tipoIds';
const rules = {
  cdTipoId: [{ required: true, message: requiredValueMessage() }],
  id: [
    { required: true, message: requiredValueMessage() },
    { max: 15, message: maxValueMessage(15) },
  ],
  prNombre: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  segNombre: [{ max: 20, message: maxValueMessage(20) }],
  prApellido: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  segApellido: [{ max: 20, message: maxValueMessage(20) }],
  email: [
    { required: true, message: requiredValueMessage() },
    { max: 100, message: maxValueMessage(100) },
    { pattern: emailPattern, message: formatValueMessage() },
  ],
  telefono1: [
    { required: true, message: requiredValueMessage() },
    { max: 10, message: maxValueMessage(10) },
  ],
  cargo: [
    { required: true, message: requiredValueMessage() },
    { max: 50, message: maxValueMessage(50) },
  ],
  cdPerfil: [{ required: true, message: requiredValueMessage() }],
};

export const UsersForm = (props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.data !== undefined) {
      form.setFieldsValue(props.data);
    }
  }, []);

  return (
    <div className='header-container'>
      <Form layout='vertical' form={form} onFinish={props.onFinish}>
        <Form.Item name='scUsuario' hidden='true'>
          <Input />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='cdTipoId'
              label='TIPO DE IDENTIFICACIÓN'
              rules={rules['cdTipoId'] || ''}
            >
              <Select>
                {tipoIds.map(({ key, description }) => (
                  <Select.Option key={key} value={key}>
                    {description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='id'
              label='IDENTIFICACIÓN'
              rules={rules['id'] || ''}
            >
              <Input
                disabled={
                  props.mode === 'edit'
                    ? props.idUser === props.data.id
                      ? true
                      : false
                    : null
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='prNombre'
              label='PRIMER NOMBRE'
              rules={rules['prNombre'] || ''}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='segNombre'
              label='SEGUNDO NOMBRE'
              rules={rules['segNombre'] || ''}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='prApellido'
              label='PRIMER APELLIDO'
              rules={rules['prApellido'] || ''}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='segApellido'
              label='SEGUNDO APELLIDO'
              rules={rules['segApellido'] || ''}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col flex='auto'>
            <Form.Item
              name='email'
              label='CORREO ELECTRÓNICO'
              rules={rules['email'] || ''}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name='telefono'
              label='TELÉFONO'
              rules={rules['telefono1'] || ''}
            >
              <Input type='number' />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='cdTipoContacto'
              label='PERFIL DE USUARIO'
              rules={rules['cdPerfil'] || ''}
            >
              <Select>
                {props.perfiles.map(({ codigo, descripcion }) => (
                  <Select.Option key={codigo} value={codigo}>
                    {descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='cargo' label='CARGO' rules={rules['cargo'] || ''}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify='center'>
          <Col span={8}>
            <Form.Item>
              <Button
                className='form-btn btn-radius'
                type='primary'
                htmlType='submit'
              >
                Grabar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
