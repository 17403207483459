import React, { Component } from 'react'
import { Row, Col, Card } from 'antd'
import './dashboard.scss'
import avatar from '../../../assets/img/assisted-man.png'
import LineaWhatsAppModal from '../../../components/modalLineaWhatsApp'

class Dashboard extends Component {
  componentDidMount() {
    this.props.menuHandler('1')
  }
  render() {
    return (
      <div className="dashboard-style">
        <Card className="card-shadow card-radius default-border welcome-message ">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <p className="dash-welcome">
                <span>¡Hola {this.props.name}! </span>Bienvenido a Affirmatum
              </p>
            </Col>
          </Row>
          <Row gutter={[16, 16]} justify="center" align="middle">
            <Col span={9}>
              <img className="dash-avatar" src={avatar} alt="" />
            </Col>
            <Col span={15}>
              <p>
                Buscamos generar la oportunidad de obtener parte de los
                descuentos comerciales por pronto pago y mayores plazos para los
                Pagadores, atender las necesidades de liquidez de los
                Proveedores, y proporcionarles una rentabilidad a los
                Inversionstas privados que proveen los recursos para el fondeo
                de la operación.
              </p>
              <p>
                Nuestra Compañia fue diseñada para cumplir la normatividad
                aplicable de las sociedades vigiladas por la Superintendencia
                Financiera de Colombia en lo que respecta a Saro, Sarlaft,
                Código de Buen Gobierno, Código de Ética, Sarc y Sarm.
              </p>
              <p>
                Nos caracterizamos por ofrecer alternativas de inversión
                novedosas y diferentes, estableciendo el balance perfecto entre
                riesgo y oportunidad, para inversionistas nacionales o
                internacionales.
              </p>
            </Col>
          </Row>
        </Card>
        <LineaWhatsAppModal showModalLineaWhatsApp={true}/>
      </div>
    )
  }
}

export default Dashboard
