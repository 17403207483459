import React, { useState, useEffect } from 'react';
import { Row, Card, Tabs, Col } from 'antd';
import { ReportFactoring } from './reports/reportFactoring/reportFactoring';
import { ReportConfirming } from './reports/reportConfirming/reportConfirming';
import './requests.scss';

export const Requests = ({ menuHandler }) => {
  const [activeTab, setActiveTab] = useState('0');

  const changeTab = (activeKey) => {
    setActiveTab(activeKey);

    window.history.replaceState(null, '', window.location.pathname);
  };

  useEffect(() => {
    menuHandler('46');
    const queryParameters = new URLSearchParams(window.location.search);
    if (queryParameters.size > 0 && queryParameters.get('tipoSol')) {
      if (queryParameters.get('tipoSol') === 'FA') {
        setActiveTab('0');
      } else if (queryParameters.get('tipoSol') === 'CSR') {
        setActiveTab('1');
      }
    }
  }, []);

  return (
    <>
      <Row className='requests-style'>
        <Col span={24}>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <Tabs
              activeKey={activeTab}
              onChange={changeTab}
              animated
              destroyInactiveTabPane
            >
              <Tabs.TabPane key={0} tab='Factoring'>
                <ReportFactoring />
              </Tabs.TabPane>
              <Tabs.TabPane key={1} tab='Confirming'>
                <ReportConfirming />
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </>
  );
};
