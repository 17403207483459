import {
  Button,
  Col,
  Form,
  Row,
  Select,
  DatePicker,
  Checkbox,
  InputNumber,
  Typography,
  Upload,
  notification,
  Space,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { requiredValueMessage } from '../../../../utilities/admin_validation_messages';
import { estadoSolicitudMsj2 } from '../../../../utilities/estadoSolicitudMsj';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../utilities/messages';
import Text from 'antd/lib/typography/Text';
import TextArea from 'antd/lib/input/TextArea';
import {
  currencyInputFormat,
  currencyInputParser,
  percentageInputFormat,
  percentageInputParser,
} from '../../../../utilities/textTools';
import './requests.scss';
import * as numberValidate from '../../../../utilities/validate/numberValidate';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { sizeFileValidation } from '../../../../utilities/validate/fileValidate';

const { Title } = Typography;
const MAX_FILES = 5;
const ACCEPTED_EXTENSIONS = [
  '.zip',
  '.rar',
  '.xlsx',
  '.xls',
  '.pdf',
  '.jpeg',
  '.png',
  '.7z',
  '.doc',
  '.docx',
  '.pptx',
  '.ppt',
];

const rules = {
  checkbox: [{ required: true, message: requiredValueMessage() }],
  cdEstado: [{ required: true, message: requiredValueMessage() }],
  tipoGestion: [{ required: true, message: requiredValueMessage() }],
  instanciaAprobacion: [{ required: true, message: requiredValueMessage() }],
  cupoAprobado: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9a-zA-Z-]*$'),
      message: 'El valor no tiene el formato correcto',
    },
  ],
  fechaAprobacionCupo: [{ required: true, message: requiredValueMessage() }],
  fechaVencimientoCupo: [{ required: true, message: requiredValueMessage() }],
  riesgo: [{ required: true, message: requiredValueMessage() }],
  tasa: [{ required: true, message: requiredValueMessage() }],
  causalRechazo: [{ required: true, message: requiredValueMessage() }],
  observacionesDI: [{ required: true, message: requiredValueMessage() }],
  montoSolicitar: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => numberValidate.range(value, 0, 999999999999),
    },
  ],
};

export const instancia_aprobacion = [
  { key: 'FT', name: 'Fast Track' },
  { key: 'CC', name: 'Cómite Credito' },
  { key: 'JD', name: 'Junta Directiva' },
];

export const riesgo = [
  { key: 'BA', name: 'Bajo' },
  { key: 'ME', name: 'Medio' },
  { key: 'MEAL', name: 'Medio Alto' },
  { key: 'AL', name: 'Alto' },
];

export const causal_rechazo = [
  { key: 'CE', name: 'Capacidad de endeudamiento' },
  { key: 'CR', name: 'Centrales de riesgo' },
  { key: 'TC', name: 'Tiempo de constitución' },
  { key: 'PC', name: 'Procesos concursales' },
  { key: 'MMC', name: 'Matricula mercantil vencida' },
  { key: 'PN', name: 'Patrimonio negativo' },
  { key: 'TS', name: 'Tipo de sociedad' },
  { key: 'AD', name: 'Alteración de documentos' },
  { key: 'SR', name: 'SARLAFT' },
  { key: 'IN', name: 'Inactividad' },
];

const opcionesGestionar = [
  { key: 'CE', name: 'Cambiar estado' },
  { key: 'NS', name: 'Añadir nueva solicitud' },
  { key: 'CTS', name: 'Cambiar tipo solicitud' },
];

export const FormGestionarSolicitud = (props) => {
  const [form] = Form.useForm();
  const [estado, setEstado] = useState(false);
  const [tipoGestion, setTipoGestion] = useState(null);
  const [disableEdit, setDisableEdit] = useState(false);
  const [disableGuardar, setDisableGuardar] = useState(false);
  const [estadosSol, setEstadosSol] = useState(null);
  const [showFactAnexos, setShowFactoringAnexos] = useState(false);
  const [fileDeclaracionRenta, setFileDeclaracionRenta] = useState([]);
  const [fileEstadosFinancieros, setFileEstadosFinancieros] = useState([]);
  const [tipoProducto, setTipoProducto] = useState(null);

  const handleSelectChange = (value) => {
    setEstado(value);
    if (value === 'A') {
      calcularFechasPorDefecto();
    }
  };

  const handleOnChangeUploadDecRent = (info) => {
    const filteredList = info.fileList.filter(
      (file) => file.status !== 'removed'
    );

    setFileDeclaracionRenta(filteredList);
    form.setFieldsValue({ DR: filteredList });
  };

  const handleOnChangeUploadEstadosFin = (info) => {
    const filteredList = info.fileList.filter(
      (file) => file.status !== 'removed'
    );

    setFileEstadosFinancieros(filteredList);
    form.setFieldsValue({ EF: filteredList });
  };

  const calcularFechasPorDefecto = () => {
    let date = new Date();
    date.setMonth(date.getMonth() + 6);
    form.setFieldsValue({
      feVenCupo: moment(date),
      feAprobacion: moment(new Date()),
    });
  };

  const getDataAprobado = () => {
    return {
      cdEstado: props.tipoSolicitud === 'CSR' ? 'CE' : props.data.cdEstado,
      cdInstaciaAprobacion: props.data.cdInstaciaAprobacion,
      nmCupoAprobado: props.data.nmCupoAprobado,
      feAprobacion: moment(props.data.feAprobacion),
      feVenCupo: moment(props.data.feVenCupo),
      cdRiesgo: props.data.cdRiesgo,
      nmTasaAprobada: props.data.nmTasaAprobada,
      solExcepcion: props.data.solExcepcion === 'S' ? true : false,
      dsObservacion: props.data.dsObservacion,
    };
  };

  const getEstadosSol = () => {
    let result = [];
    if (props.tipoSolicitud === 'FA') {
      result = estadoSolicitudMsj2.filter((item) => item.type.includes('F'));
    } else if (props.tipoSolicitud === 'CSR') {
      result = estadoSolicitudMsj2.filter((item) => item.type.includes('C'));
    }
    setEstadosSol(result);
  };

  const handleChangeTipoGestion = (value) => {
    setTipoGestion(value);
    if (value === 'NS' && tipoProducto === 'FC') {
      setDisableGuardar(true);
    } else if (value === 'CTS' && tipoProducto === 'FC') {
      setDisableGuardar(true);
    } else if (
      value === 'NS' &&
      tipoProducto === 'F' &&
      (props.data.cdEstado === 'A' || props.data.cdEstado === 'R')
    ) {
      setDisableGuardar(true);
    } else if (
      value === 'NS' &&
      tipoProducto === 'C' &&
      props.data.cdEstado === 'A'
    ) {
      setDisableGuardar(true);
    } else if (
      value === 'CTS' &&
      tipoProducto === 'F' &&
      (props.data.cdEstado === 'A' || props.data.cdEstado === 'R')
    ) {
      setDisableGuardar(true);
    } else if (
      value === 'CTS' &&
      tipoProducto === 'C' &&
      props.data.cdEstado === 'A'
    ) {
      setDisableGuardar(true);
    } else if (
      value === 'CE' &&
      props.tipoSolicitud === 'CSR' &&
      props.data.cdEstado === 'A'
    ) {
      setDisableGuardar(true);
    } else if (value === 'CE' && props.data.cdEstado == "SI") {
      setDisableGuardar(true);
    } else if (value !== 'CE' && props?.dataVin?.cdTipoPersona === 'N') {
      setDisableGuardar(true);
    } else {
      setDisableGuardar(false);
    }
  };

  const handleBeforeUpload = async (file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase();

    try {
      await sizeFileValidation([file]);
    } catch (error) {
      notification.error({
        message: 'Error de validación',
        description: error.message,
      });
      return Upload.LIST_IGNORE;
    }

    if (!ACCEPTED_EXTENSIONS.includes(`.${fileExtension}`)) {
      notification.error({
        message: 'Formato no válido',
        description: `El documento ${
          file.name
        } no tiene el formato válido (${ACCEPTED_EXTENSIONS.join(', ')})`,
      });
      return Upload.LIST_IGNORE;
    }

    return false;
  };

  const htmlFactoringAdicionales = (
    <>
      <Row gutter={8} justify='center'>
        <Col span={12}>
          <Form.Item
            name='montoSolicitar'
            label='Monto solicitado'
            rules={rules['montoSolicitar'] || ''}
          >
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              formatter={currencyInputFormat}
              parser={currencyInputParser}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12} justify='center'>
        <Col span={8} offset={4}>
          <Title level={4}>DECLARACIÓN DE RENTA</Title>
          <h3>
            Requisitos:{' '}
            <h5 style={{ fontWeight: 'bold' }}>
              <b style={{ color: 'green' }}>Último cierre fiscal disponible</b>
            </h5>
          </h3>
          <Form.Item name='DR'>
            <br />
            <br />
            <Upload
              beforeUpload={handleBeforeUpload}
              fileList={fileDeclaracionRenta}
              onChange={handleOnChangeUploadDecRent}
              maxCount={MAX_FILES}
              multiple
            >
              <Button icon={<UploadOutlined />}>Clic Para Subir Archivo</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={10}>
          <Title level={4}>ESTADOS FINANCIEROS</Title>
          <h3>
            Requisitos:{' '}
            <h5 style={{ fontWeight: 'bold' }}>
              <b style={{ color: 'green' }}>
                Estados financieros del cierre del año anterior <br /> y último
                corte de año en curso
              </b>
            </h5>
          </h3>
          <Form.Item name='EF'>
            <br />
            <Upload
              beforeUpload={handleBeforeUpload}
              fileList={fileEstadosFinancieros}
              onChange={handleOnChangeUploadEstadosFin}
              maxCount={MAX_FILES}
              multiple
            >
              <Button icon={<UploadOutlined />}>Clic Para Subir Archivo</Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  useEffect(() => {
    if (props.data) {
      setEstado(props.data.cdEstado);
      if (props.data.cdEstado === 'A') {
        form.setFieldsValue(getDataAprobado());
        if (props.tipoSolicitud === 'CSR') {
          setDisableGuardar(true);
        }
        setDisableEdit(true);
      } else if (props.data.cdEstado === 'DI' || props.data.cdEstado === 'R') {
        form.setFieldsValue(props.data);
        calcularFechasPorDefecto();
      }
    } else {
      calcularFechasPorDefecto();
    }
    getEstadosSol();
    setTipoProducto(props?.dataVin?.tipoProducto);
  }, []);

  useEffect(() => {
    if (props?.dataVin?.estadoVinculacion === 'A' && tipoProducto !== 'FC') {
      if (estado === 'DI') {
        setDisableGuardar(true);
      } else if (estado === 'CE' || estado === 'A') {
        setDisableGuardar(false);
      }
    }
  }, [estado]);

  return (
    <div className='requests-style'>
      <Form layout='vertical' form={form} onFinish={props.onFinish}>
        {props.data.cdOrigenSol === 'VD' ? (
          <Row gutter={16} justify='center'>
            <Col span={12}>
              <Form.Item
                name='tipoGestion'
                label='Seleccione una opción'
                rules={rules['tipoGestion'] || ''}
              >
                <Select onChange={handleChangeTipoGestion}>
                  {opcionesGestionar.map(({ key, name }) => (
                    <Select.Option key={key} value={key}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        ) : null}
        {tipoGestion === 'CE' ||
        props.data.cdOrigenSol === 'S' ||
        props.data.cdOrigenSol === 'VDS' ? (
          <>
            {props.data.cdEstado !== 'SI' ? (
              <Row gutter={16} justify='center'>
                <Col span={12}>
                  <Form.Item
                    name='cdEstado'
                    label='Estado'
                    rules={rules['cdEstado'] || ''}
                  >
                    <Select
                      onChange={(value) => handleSelectChange(value)}
                      disabled={disableEdit}
                    >
                      {estadosSol != null &&
                        estadosSol.map(({ key, name }) => (
                          <Select.Option key={key} value={key}>
                            {name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Row justify='center'>
                <p style={{ textAlign: 'center', fontSize: '15px' }}>
                  <Space>
                    <InfoCircleOutlined
                      style={{ fontSize: 20, color: 'orange' }}
                    />
                    <Typography.Text style={{ fontSize: 16, color: 'orange' }}>
                      La solicitud debe estar radicada para actualizar los
                      estados
                    </Typography.Text>
                  </Space>
                </p>
              </Row>
            )}
            {estado === 'A' && props.tipoSolicitud === 'FA' ? (
              <>
                {props?.dataVin?.estadoVinculacion === 'A' && (
                  <Row gutter={16}>
                    <Col
                      span={24}
                      style={{ textAlign: 'center', marginBottom: 18 }}
                    >
                      <Space>
                        <InfoCircleOutlined
                          style={{ fontSize: 20, color: 'orange' }}
                        />
                        <Typography.Text
                          style={{ fontSize: 16, color: 'orange' }}
                        >
                          La ficha de este proveedor ya fue activada
                        </Typography.Text>
                      </Space>
                    </Col>
                  </Row>
                )}
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name='cdInstaciaAprobacion'
                      label='Instancia Aprobación'
                      rules={rules['instanciaAprobacion'] || ''}
                    >
                      <Select>
                        {instancia_aprobacion.map(({ key, name }) => (
                          <Select.Option key={key} value={key}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='nmCupoAprobado'
                      label='Cupo Aprobado'
                      rules={rules['cupoAprobado'] || ''}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        min={0}
                        formatter={(value) => currencyInputFormat(value)}
                        parser={(value) => currencyInputParser(value)}
                        stringMode={true}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name='feAprobacion'
                      label='Fecha Aprobación Cupo'
                      rules={rules['fechaAprobacionCupo'] || ''}
                    >
                      <DatePicker format={DATE_FORMAT} disabled={disableEdit} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='feVenCupo'
                      label='Fecha Vencimiento Cupo'
                      rules={rules['fechaVencimientoCupo'] || ''}
                    >
                      <DatePicker format={DATE_FORMAT} disabled={disableEdit} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name='cdRiesgo'
                      label='Riesgo'
                      rules={rules['riesgo'] || ''}
                    >
                      <Select>
                        {riesgo.map(({ key, name }) => (
                          <Select.Option key={key} value={key}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='nmTasaAprobada'
                      label='Tasa'
                      rules={rules['tasa'] || ''}
                    >
                      <InputNumber
                        min={0}
                        max={100}
                        precision={2}
                        style={{ width: '100%' }}
                        formatter={percentageInputFormat}
                        parser={percentageInputParser}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} justify='center'>
                  <Col>
                    <div className='check'>
                      <Form.Item
                        name='solExcepcion'
                        rules={rules['excepcion'] || ''}
                        valuePropName='checked'
                      >
                        <Checkbox className='tax-info-check'>
                          <Text level={2}>Solicitud con excepción</Text>
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='auto'>
                    <Form.Item
                      name='dsObservacion'
                      label='Observaciónes'
                      rules={rules['observaciones'] || ''}
                    >
                      <TextArea
                        maxLength={300}
                        style={{ height: 100, width: '100%', resize: 'none' }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : estado === 'R' && props?.tipoSolicitud === 'FA' ? (
              <>
                <Row gutter={16} justify='center'>
                  <Col span={12}>
                    <Form.Item
                      name='cdCausalRechazo'
                      label='Causal Rechazo'
                      rules={rules['causalRechazo'] || ''}
                    >
                      <Select>
                        {causal_rechazo.map(({ key, name }) => (
                          <Select.Option key={key} value={key}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : estado === 'CE' && props?.dataVin?.estadoVinculacion === 'A' ? (
              <p style={{ textAlign: 'center', fontSize: '15px' }}>
                <Space>
                  <InfoCircleOutlined
                    style={{ fontSize: 20, color: 'orange' }}
                  />
                  <Typography.Text style={{ fontSize: 16, color: 'orange' }}>
                    La ficha de este proveedor ya fue activada
                  </Typography.Text>
                </Space>
              </p>
            ) : estado === 'DI' ? (
              <>
                <Row gutter={16}>
                  <Col flex='auto'>
                    <Form.Item
                      name='dsObservacionDI'
                      label='Observaciónes de documentación incompleta'
                      rules={rules['observacionesDI'] || ''}
                    >
                      <TextArea
                        maxLength={300}
                        style={{ height: 100, width: '100%', resize: 'none' }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : null}
          </>
        ) : tipoGestion === 'NS' ? (
          tipoProducto === 'FC' ? (
            <p style={{ textAlign: 'center', fontSize: '15px' }}>
              <Space>
                <InfoCircleOutlined style={{ fontSize: 20, color: 'orange' }} />
                <Typography.Text style={{ fontSize: 16, color: 'orange' }}>
                  Este cliente tiene solicitudes de ambos productos
                </Typography.Text>
              </Space>
            </p>
          ) : tipoProducto === 'F' &&
            (props.data.cdEstado === 'A' || props.data.cdEstado === 'R') ? (
            <p style={{ textAlign: 'center', fontSize: '15px' }}>
              <Space>
                <InfoCircleOutlined style={{ fontSize: 20, color: 'orange' }} />
                <Typography.Text style={{ fontSize: 16, color: 'orange' }}>
                  No se pueden añadir solicitudes en estado aprobado o rechazado
                </Typography.Text>
              </Space>
            </p>
          ) : tipoProducto === 'C' && props.data.cdEstado === 'A' ? (
            <p style={{ textAlign: 'center', fontSize: '15px' }}>
              <Space>
                <InfoCircleOutlined style={{ fontSize: 20, color: 'orange' }} />
                <Typography.Text style={{ fontSize: 16, color: 'orange' }}>
                  No se pueden añadir solicitudes en estado completa y enviada
                </Typography.Text>
              </Space>
            </p>
          ) : props?.dataVin?.cdTipoPersona === 'N' ? (
            <p style={{ textAlign: 'center', fontSize: '15px' }}>
              <Space>
                <InfoCircleOutlined style={{ fontSize: 20, color: 'orange' }} />
                <Typography.Text style={{ fontSize: 16, color: 'orange' }}>
                  No se pueden añadir solicitudes factoring de personas
                  naturales
                </Typography.Text>
              </Space>
            </p>
          ) : (
            <>
              <p style={{ textAlign: 'center', fontSize: '15px' }}>
                <b>Nueva solicitud de :</b>
              </p>
              <Row gutter={4} justify={'center'}>
                <Col>
                  <div className='check'>
                    <Form.Item
                      name='factSelectNuevo'
                      rules={
                        props.tipoSolicitud === 'FA'
                          ? null
                          : rules['checkbox'] || ''
                      }
                      valuePropName='checked'
                    >
                      <Checkbox
                        className='tax-info-check'
                        disabled={props.tipoSolicitud === 'FA' ? true : false}
                        onChange={() => {
                          setShowFactoringAnexos(!showFactAnexos);
                        }}
                      >
                        <Text level={2}>Factoring</Text>
                      </Checkbox>
                    </Form.Item>
                  </div>
                </Col>
                <Col>
                  <div className='check'>
                    <Form.Item
                      name='confSelectNuevo'
                      rules={
                        props.tipoSolicitud === 'CSR'
                          ? null
                          : rules['checkbox'] || ''
                      }
                      valuePropName='checked'
                    >
                      <Checkbox
                        className='tax-info-check'
                        disabled={props.tipoSolicitud === 'CSR' ? true : false}
                      >
                        <Text level={2}>Confirming</Text>
                      </Checkbox>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              {showFactAnexos ? htmlFactoringAdicionales : null}
            </>
          )
        ) : tipoGestion === 'CTS' ? (
          tipoProducto === 'FC' ? (
            <p style={{ textAlign: 'center', fontSize: '15px' }}>
              <Space>
                <InfoCircleOutlined style={{ fontSize: 20, color: 'orange' }} />
                <Typography.Text style={{ fontSize: 16, color: 'orange' }}>
                  Este cliente tiene solicitudes de ambos productos
                </Typography.Text>
              </Space>
            </p>
          ) : tipoProducto === 'F' &&
            (props.data.cdEstado === 'A' || props.data.cdEstado === 'R') ? (
            <p style={{ textAlign: 'center', fontSize: '15px' }}>
              <Space>
                <InfoCircleOutlined style={{ fontSize: 20, color: 'orange' }} />
                <Typography.Text style={{ fontSize: 16, color: 'orange' }}>
                  No se pueden cambiar solicitudes en estado aprobado o
                  rechazado
                </Typography.Text>
              </Space>
            </p>
          ) : tipoProducto === 'C' && props.data.cdEstado === 'A' ? (
            <p style={{ textAlign: 'center', fontSize: '15px' }}>
              <Space>
                <InfoCircleOutlined style={{ fontSize: 20, color: 'orange' }} />
                <Typography.Text style={{ fontSize: 16, color: 'orange' }}>
                  No se pueden cambiar solicitudes en estado completa y enviada
                </Typography.Text>
              </Space>
            </p>
          ) : props?.dataVin?.cdTipoPersona === 'N' ? (
            <p style={{ textAlign: 'center', fontSize: '15px' }}>
              <Space>
                <InfoCircleOutlined style={{ fontSize: 20, color: 'orange' }} />
                <Typography.Text style={{ fontSize: 16, color: 'orange' }}>
                  No se pueden cambiar solicitudes a factoring de personas
                  naturales
                </Typography.Text>
              </Space>
            </p>
          ) : (
            <>
              <p style={{ textAlign: 'center', fontSize: '15px' }}>
                <b>Cambiar Tipo Solicitud:</b>
              </p>
              <Row gutter={4} justify={'center'}>
                <Col>
                  <div className='check'>
                    <Form.Item
                      name='factSelectCambio'
                      rules={
                        props.tipoSolicitud === 'FA'
                          ? null
                          : rules['checkbox'] || ''
                      }
                      valuePropName='checked'
                    >
                      <Checkbox
                        className='tax-info-check'
                        disabled={props.tipoSolicitud === 'FA' ? true : false}
                        onChange={() => {
                          setShowFactoringAnexos(!showFactAnexos);
                        }}
                      >
                        <Text level={2}>{'Confirming -> Factoring'}</Text>
                      </Checkbox>
                    </Form.Item>
                  </div>
                </Col>
                <Col>
                  <div className='check'>
                    <Form.Item
                      name='confSelectCambio'
                      rules={
                        props.tipoSolicitud === 'CSR'
                          ? null
                          : rules['checkbox'] || ''
                      }
                      valuePropName='checked'
                    >
                      <Checkbox
                        className='tax-info-check'
                        disabled={props.tipoSolicitud === 'CSR' ? true : false}
                      >
                        <Text level={2}>{'Factoring -> Confirming'}</Text>
                      </Checkbox>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              {showFactAnexos ? htmlFactoringAdicionales : null}
            </>
          )
        ) : null}
        <Row justify='center'>
          <Col span={8}>
            <Form.Item>
              <Button
                className='form-btn btn-radius'
                type='primary'
                htmlType='submit'
                disabled={disableGuardar}
              >
                Grabar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
