import React from 'react';
import { Typography, Space } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';

const { Title, Text } = Typography;

class ModalFuentesExternas extends React.Component {
  render() {
    return (
      <Space
        direction='vertical'
        align='center'
        style={{ width: '100%', padding: '25px' }}
      >
        <InfoCircleTwoTone style={{ fontSize: '45px', marginBottom: '15px' }} />
        <Title level={3} style={{ textAlign: 'center', marginBottom: '25px' }}>
          Datos precargados para facilitar tu vinculación
        </Title>
        <Text
          style={{ fontSize: '16px', color: '#595959', textAlign: 'center' }}
        >
          Para que tengas una mejorar experiencia y podamos agilizar el proceso
          de vinculación, hemos precargado algunos datos de fuentes públicas en
          este formulario. Si encuentras información <b>incorrecta</b> o
          <b> desactualizada</b>, puedes editarla y corregirla sin problema.
        </Text>
      </Space>
    );
  }
}

export default ModalFuentesExternas;
