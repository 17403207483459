import React from 'react';
import { Modal, Row  } from 'antd';

function PoliticaTratamientoDatosModal ({open, onClose }) {

    return (
      <Modal
        visible={open}
        onCancel={onClose}
        width={800}
        footer={false}
      >
        <Row>
          <p>
            Al hacer clic en Guardar, autorizas a EXPONENCIAL CONFIRMING S.A.S.
            a recolectar, almacenar, transmitir, transferir, usar y en general
            tratar sus datos personales para registrarlos en nuestros sistemas,
            enviar publicidad e información comercial por medio de e-mail, SMS,
            Apps, llamada telefónica o cualquier otro canal electrónico o
            físico, realizar consultas y reportes ante operadores de información
            y demás finalidades dispuestas en nuestra&nbsp;
            <a
              href='https://resources-affirmatum.s3.us-east-1.amazonaws.com/GE-PO-01-Politica-de-tratamiento-de-datos-personales-Exponencial-Confirming-S.A.S.-V3.pdf'
              target='_blank'
              rel='noreferrer'
            >
              <u>Política de Tratamiento de Datos Personales</u>
            </a>
            , la cual declaras conocer y aceptar, así como los derechos allí
            publicados que tienes como Titular, los cuales podrás ejercer a
            través de&nbsp;
            <a href="mailto:notificaciones@affirmatum.com">
              notificaciones@affirmatum.com.
            </a>
            <p>
            <br />
            Consulta el detalle de nuestra política dando clic en "Política de 
            Tratamiento de Datos Personales".
            </p>
          </p>
          </Row>
      </Modal>
    );
}
export default PoliticaTratamientoDatosModal;