import React from 'react';
import { Col, Form, Input, Row, Select, Space, Spin, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { requiredValueMessage } from '../../../../utilities/admin_validation_messages';


const SELECT_PLACE_HOLDER = 'Seleccionar...';

const rules = {
  docConstDeLaSociedad: [{ required: true, message: requiredValueMessage() }],
  camaraDeComercio: [{ required: true, message: requiredValueMessage() }],
  cdActividad: [{ required: true, message: requiredValueMessage() }],
};

class OtherDocumentsN extends React.Component {
  render() {
    const { actividadSelect, onActividadSearch } = this.props;
    return (
      <div className='content-container'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='camaraDeComercio'
                label='Cámara de comercio donde se encuentra registrada'
                rules={rules['camaraDeComercio'] || ''}
              >
                <Input minLength={4} placeholder='Nombre' />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='cdActividad'
                label='Tipo de actividad'
                rules={rules['cdActividad'] || ''}
              >
                <Select
                  placeholder={SELECT_PLACE_HOLDER}
                  className='module-selector-select'
                  showSearch
                  allowClear
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={onActividadSearch}
                  notFoundContent={
                    actividadSelect.fetching ? <Spin size='small' /> : null
                  }
                >
                  {actividadSelect.options.map((actividad, i) => (
                    <Select.Option key={i} value={actividad.codigo}>
                      {actividad.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col>
              <Form.Item>
                <Row justify='start' style={{ paddingTop: '0.5rem' }}>
                  <Col>
                    <Space>
                      ¿Has sido sancionado o investigado por procesos de lavado
                      de activos o de financiación al terrorismo?
                    </Space>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='cdSnSancionado'
                      valuePropName='checked'
                      noStyle
                    >
                      <Switch checkedChildren='Si' unCheckedChildren='No' />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name='docConstDeLaSociedad'
                label='Documento de constitución de la sociedad'
                rules={rules['docConstDeLaSociedad'] || ''}
              >
                <TextArea
                  rows={5}
                  minLength={1}
                  maxLength={4000}
                  style={{ resize: 'none' }}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default OtherDocumentsN;
