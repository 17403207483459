import React from 'react';
import { Card } from 'antd';
import { mensajesEstadoVinculacion } from './mensajesEstadoVin';

const handleEstadoActual = (estadoVin) =>
  mensajesEstadoVinculacion.find(
    (mensaje) => mensaje.key === estadoVin?.estadoVin
  );

export const CardEstudio = (estadoVin) => {
  const estadoActual = handleEstadoActual(estadoVin);

  return (
    <>
      <Card className='card-shadow card-radius default-border'>
        <h1 style={{ fontWeight: 'bold', color: '#28a745' }}>
          Tu solicitud está en estudio...
        </h1>
        <h3>
          <b>Estado Solicitud: </b>
          {estadoActual?.description}
        </h3>
        <p style={{ fontSize: '16px' }}>
          Estamos revisando tu solicitud para asegurarnos que cumpla con todos
          los requisitos necesarios. Tan pronto tengamos noticias, te
          avisaremos. ¡Gracias por confiar en nosotros!
        </p>
      </Card>
    </>
  );
};

export const CardDocIncompleta = (estadoVin) => {
  const estadoActual = handleEstadoActual(estadoVin);

  return (
    <>
      <Card className='card-shadow card-radius default-border'>
        <h1 style={{ fontWeight: 'bold', color: '#28a745' }}>
          Tu solicitud tiene documentos pendientes
        </h1>
        <h3>
          <b>Estado Solicitud: </b>
          {estadoActual?.description}
        </h3>
        <p style={{ fontSize: '16px' }}>{estadoActual?.message}</p>
      </Card>
    </>
  );
};

export const CardCompletoEnviado = (estadoVin) => {
  const estadoActual = handleEstadoActual(estadoVin);

  return (
    <>
      <Card className='card-shadow card-radius default-border'>
        <h1 style={{ fontWeight: 'bold', color: '#28a745' }}>
          ¡Tu solicitud fue enviada con éxito!
        </h1>
        <h3>
          <b>Estado Solicitud: </b>
          {estadoActual?.description}
        </h3>
        <p style={{ fontSize: '16px' }}>
          Nuestro equipo está revisando tu solicitud y te informará sobre los
          siguientes pasos para completar la vinculación y firma de documentos
          en la plataforma. ¡Gracias por confiar en nosotros!
        </p>
      </Card>
    </>
  );
};

export const CardRechazado = (estadoVin) => {
  const estadoActual = handleEstadoActual(estadoVin);

  return (
    <>
      <Card className='card-shadow card-radius default-border'>
        <h1 style={{ fontWeight: 'bold', color: '#28a745' }}>
          Tu solicitud no pudo ser aprobada en este momento...
        </h1>
        <h3>
          <b>Estado Solicitud: </b>
          {estadoActual?.description}
        </h3>
        <p style={{ fontSize: '16px' }}>
          Hemos revisado tu solicitud y la información proporcionada.
          Lamentablemente, en este momento no podemos aprobarla. Agradecemos tu
          confianza en nosotros y te invitamos a que sigas en contacto para
          futuras oportunidades.
        </p>
      </Card>
    </>
  );
};
