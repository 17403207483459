import React, { Component } from 'react';
import { Row, Col, Button, Table, notification, Space, Form, Switch } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import equal from 'fast-deep-equal';
import { v4 as uuidv4 } from 'uuid';
import { tipoIds } from '../../../../../utilities/constants';
import { PersonaAdicionalForm } from '../../../../../components/fichaCliente/juridica/finalBeneficiaries/personasAdicionalesForm';


const initialState = {
  miembrosJuntaDirectiva: [],
  visibleModal: false,
  snIndicaJD:true,
};

class DirectorsBoard extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'TIPO DE DOCUMENTO',
        dataIndex: 'dsTipoId',
      },
      {
        title: 'IDENTIFICACIÓN',
        dataIndex: 'id',
      },
      {
        title: 'NOMBRE / RAZÓN SOCIAL',
        dataIndex: 'nombre',
      },
      {
        title: 'ELIMINAR',
        dataIndex: '',
        render: (record) => (
          <DeleteOutlined
            className='info'
            onClick={() => this.deleteMiembroJD(record)}
          />
        ),
        showSorterTooltip: false,
      },
    ];
  }

  deleteMiembroJD = (record) => {
    const { miembrosJuntaDirectiva } = this.state;
    let accTemp = miembrosJuntaDirectiva.filter((value) => value.key !== record.key);
    this.setState({ ...this.state, miembrosJuntaDirectiva: accTemp });
  };

  handleOnFinish = () => {
    const { onFinish } = this.props;
    const { miembrosJuntaDirectiva,snIndicaJD } = this.state;

    if (snIndicaJD && miembrosJuntaDirectiva.length < 2) {
      notification.warn({
        title: 'Alerta',
        message: 'Debe añadir minimo 2 miembros de junta directiva',
        duration: 4,
      });
      return;
    }

    let cdIndicaJD = snIndicaJD ? 'S' : 'N';

    onFinish('-1', { miembrosJuntaDirectiva: miembrosJuntaDirectiva,cdIndicaJD: cdIndicaJD });
  };

  handleOnOpenModal = () => {
    this.setState({ ...this.state, visibleModal: true });
  };

  handleOnFinishModal = (formValue) => {
    const { miembrosJuntaDirectiva } = this.state;

    let accTemp = [];

    if (formValue.key && formValue.key !== '') {
      accTemp = miembrosJuntaDirectiva.map((value) =>
        value.key === formValue.key ? formValue : value
      );
    } else {
      let uuid = uuidv4();
      accTemp = [{ ...formValue, key: uuid }, ...miembrosJuntaDirectiva];
    }

    this.setState({
      ...this.state,
      miembrosJuntaDirectiva: accTemp,
      visibleModal: false,
    });

  };

  handleOnCloseModal = () => {
    this.setState({
      ...this.state,
      visibleModal: false,
    });
  };

  handleSwitchChange = (value) => {
    this.setState({ ...this.state, snIndicaJD: value });
  };

  render() {
    const { miembrosJuntaDirectiva, visibleModal,snIndicaJD } = this.state;
    return (
      <div className='content-container'>
        <div className='form-container card-background-gray card-shadow card-radius'>
        <Form
            name='directors_board'
            layout='vertical'
        >
       <p><b>Indícanos la información de la(s) persona(s) hacen parte de la directiva de tu empresa</b></p>
            <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                  <Col span={12}>
                    <Space>¿Tu empresa tiene junta directiva?</Space>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='cdIndicaJD'
                      valuePropName='checked'
                      noStyle
                    >
                        <Switch 
                            checkedChildren='Si' 
                            unCheckedChildren='No' 
                            onChange={this.handleSwitchChange}
                            defaultChecked
                        />
                    </Form.Item>
                  </Col>
            </Row>
            </Form>
            {
                snIndicaJD ? 
                <>
            <Row
              gutter={[16, 16]}
              align='middle'
              justify='end'
              style={{ marginBottom: '16px' }}
            >
              <Col span={6} style={{ textAlign: 'right' }}>
                <Button
                  className='btn-radius'
                  type='primary'
                  onClick={this.handleOnOpenModal}
                >
                  Nuevo Miembro JD
                </Button>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
              <Col flex='auto' style={{ overflow: 'auto' }}>
                <Table
                  style={{ width: '100%' }}
                  columns={this.columnas}
                  dataSource={miembrosJuntaDirectiva}
                  pagination={false}
                ></Table>
              </Col>
            </Row>
            </> : <br/> }
          <Row
            gutter={[16, 16]}
            align='middle'
            justify='center'
            style={{ marginBottom: '16px' }}
          >
            <Col span={6}>
              <Button
                className='form-btn btn-radius'
                type='primary'
                onClick={this.handleOnFinish}
              >
                Guardar cambios
              </Button>
            </Col>
          </Row>

          <PersonaAdicionalForm
            visible={visibleModal}
            onClose={this.handleOnCloseModal}
            onFinish={this.handleOnFinishModal}
            modalTitle={"Junta Directiva"}
            formName={"juntaDirectiva_data"}
            personType={'JD'}
          />

          
        </div>
      </div>
    );
  }
}

export default DirectorsBoard;
