import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  DatePicker,
  Spin,
  Modal,
} from 'antd';
import { listarCompañias } from '../../../../../../services/admin/reports/sagrilaft';
import React, { useEffect, useState } from 'react';
import { DATE_FORMAT } from '../../../../../../utilities/messages';
import moment from 'moment';

const rules = {
  scPersona: [{ required: true, message: 'Campo requerido' }],
  feAlerta: [{ required: true, message: 'Campo requerido' }],
  cdMotivo: [{ required: true, message: 'Campo requerido' }],
  dsDetalle: [{ required: true, message: 'Campo requerido' }],
  cdEstado: [{ required: true, message: 'Campo requerido' }],
};

const initialState = {
  personaSelect: {
    fetching: false,
    options: [],
  },
};

const motivos = [
  { key: 'MA', description: 'Monto de factura atípico' },
  { key: 'CA', description: 'Crecimiento atípico de facturas' },
  { key: 'UI', description: 'Ubicación geográfica inusual' },
  {
    key: 'VI',
    description:
      'Volumen de facturas cargadas supera el volumen de ingresos mensuales',
  },
  { key: 'CU', description: 'Creación atípica de usuarios' },
  { key: 'CB', description: 'Cambios frecuentes en cuentas bancarias' },
  { key: 'EB', description: 'Eliminación atípica de cuenta bancarias' },
  { key: 'NB', description: 'Cantidad atípica de cuentas' },
  {
    key: 'CF',
    description: 'Cambios frecuentes en información de la compañía',
  },
  { key: 'CR', description: 'Cambio frecuente de representante legal' },
  { key: 'CN', description: 'Cambio frecuente de razón social' },
  { key: 'AF', description: 'Cambios frecuentes en Affitoken' },
  { key: 'RC', description: 'Recuperación frecuente de contraseña' },
];

const estadosOperInusuales = [
  { key: 'E', description: 'En estudio' },
  { key: 'I', description: 'Inusual' },
  { key: 'S', description: 'Sospechosa' },
];

export const ModalInsert = (props) => {
  const { authToken, onFinish, visible, onCancel } = props;
  const [form] = Form.useForm();
  const [personaSelect, setPersonaSelect] = useState(
    initialState.personaSelect
  );
  const [estado, setEstado] = useState('');

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setEstado('');
    }
  }, [form, visible]);

  const handleOnSearchPersona = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      setPersonaSelect({ ...personaSelect, fetching: true });
      try {
        const response = await listarCompañias(authToken, { nombre });
        setPersonaSelect({
          ...personaSelect,
          options: response.data,
          fetching: false,
        });
      } catch {
        setPersonaSelect({ ...personaSelect, options: [], fetching: false });
      }
    }
  };

  const handleSelectPersona = (persona) => {
    form.setFieldsValue({ scPersona: persona });
  };

  const handleFinish = (values) => {
    const formattedValues = {
      ...values,
      feAlerta: values.feAlerta
        ? moment(values.feAlerta).format(DATE_FORMAT)
        : null,
    };
    onFinish(formattedValues);
    form.resetFields();
    setEstado('');
  };

  return (
    <div className='header-container'>
      <Modal
        title='Insertar Operación Inusual'
        visible={visible}
        onCancel={() => {
          onCancel();
          form.resetFields();
          setEstado('');
        }}
        footer={null}
        height={500}
        width={800}
      >
        <Form layout='vertical' form={form} onFinish={handleFinish}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='scPersona'
                label='Compañia'
                rules={rules['scPersona'] || ''}
              >
                <Select
                  placeholder='Seleccione una persona'
                  showSearch
                  allowClear
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleOnSearchPersona}
                  onChange={handleSelectPersona}
                  notFoundContent={
                    personaSelect.fetching ? <Spin size='small' /> : null
                  }
                >
                  {personaSelect.options.map((d) => (
                    <Select.Option key={d.codigo} value={d.codigo}>
                      {d.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='feAlerta'
                label='Fecha de Alerta'
                rules={rules['feAlerta'] || ''}
              >
                <DatePicker style={{ width: '100%' }} format={DATE_FORMAT} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdMotivo'
                label='Motivo'
                rules={rules['cdMotivo'] || ''}
              >
                <Select placeholder='Seleccione un motivo'>
                  {motivos.map((motivo) => (
                    <Select.Option key={motivo.key} value={motivo.key}>
                      {motivo.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='cdEstado'
                label='Estado'
                rules={rules['cdEstado'] || ''}
              >
                <Select
                  placeholder='Seleccione un estado'
                  onChange={(value) => setEstado(value)}
                >
                  {estadosOperInusuales.map((estado) => (
                    <Select.Option key={estado.key} value={estado.key}>
                      {estado.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name='dsDetalle'
                label='Detalle'
                rules={rules['dsDetalle'] || ''}
              >
                <Input.TextArea
                  style={{
                    resize: 'none',
                    width: '100%',
                    height: '5rem',
                    paddingTop: '0.25rem',
                    overflow: 'hidden',
                    marginTop: '0.0625rem',
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          {estado === 'I' && (
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name='dsObservacion'
                  label='Observación'
                >
                  <Input.TextArea
                    style={{
                      resize: 'none',
                      width: '100%',
                      height: '5rem',
                      paddingTop: '0.25rem',
                      overflow: 'hidden',
                      marginTop: '0.0625rem',
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
                style={{
                  width: '15%',
                  backgroundColor: '#3399ff',
                  marginTop: '1rem',
                }}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
