import React, { useEffect, useState } from "react";
import { Modal, Button, Rate, Input, Col, Row, Form, notification } from "antd";
import {
  TipoPreguntaAbierta,
  TipoPreguntaRango
} from "../npsPreguntas/npsTipoPregunta";
import NpsMensajeExito from "../npsMensajes/npsMensajeExito";
import { grabarRespuesta } from "../../../services/nps/npsService";

const NpsModal = ({
  visible,
  onClose,
  preguntas,
  scCompania,
  authToken,
  idSolicitud
}) => {
  const [form] = Form.useForm();
  const [mensajeExito, setMensajeExito] = useState(null);
  const [preguntasProcesadas, setPreguntasProcesadas] = useState([]);
  const [intentosFallidos, setIntentosFallidos] = useState(0);
  const [mensajeObligatorio, setMensajeObligatorio] = useState(null);
  const isObligatorio = preguntas.length > 0 ? preguntas[0].obligatorio : false;

  useEffect(() => {
    const nuevasPreguntas = preguntas.map((preguntaObj) => ({
      tipoPregunta: preguntaObj.tipoPregunta,
      parametrosDiseno: preguntaObj.parametrosDiseno
    }));

    setPreguntasProcesadas(nuevasPreguntas);
    setMensajeObligatorio(preguntas[0].mensajeExito.obligatorio);
  }, [preguntas]);

  const handleOnFinish = async () => {
    try {
      const valoresFormulario = await form.validateFields();
      const respuestas = preguntas.map((pregunta, index) => {
        if (pregunta.tipoPregunta === "idSolicitud") {
          return {
            tipoPregunta: pregunta.tipoPregunta,
            respuesta: idSolicitud,
            titulo: pregunta.titulo,
            scCompania,
            scPregunta: pregunta.scPregunta
          };
        }

        return {
          tipoPregunta: pregunta.tipoPregunta,
          respuesta: valoresFormulario[`pregunta_${index}`] || null,
          titulo: pregunta.titulo,
          scCompania,
          scPregunta: pregunta.scPregunta
        };
      });

      const respuestasFiltradas = respuestas.filter(
        (r) => r.tipoPregunta !== "idSolicitud"
      );

      const hayRespuestas = respuestasFiltradas.some((r) => !!r.respuesta);

      if (!hayRespuestas) {
        notification.warning({
          message: "Advertencia",
          description: "Por favor responde al menos una pregunta.",
          duration: 5
        });
        return;
      }
      const response = await grabarRespuesta(authToken, respuestas);
      const mensajeParseado = JSON.parse(response.message);
      setMensajeExito(mensajeParseado);
    } catch (error) {
      notification.error({
        message: "Error",
        description:
          error.message || "Hubo un problema al enviar la respuesta.",
        duration: 5
      });
      handleIntentosFallidos();
    }
  };

  const handleIntentosFallidos = () => {
    setIntentosFallidos((prevIntentos) => {
      const nuevosIntentos = prevIntentos + 1;
      if (nuevosIntentos >= 2) {
        onClose();
      }
      return nuevosIntentos;
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={!isObligatorio ? onClose : null}
      footer={null}
      closable={!isObligatorio}
      centered
      width={600}
    >
      {isObligatorio && mensajeObligatorio && !mensajeExito && (
        <p
          style={{
            textAlign: "center",
            color: "green",
            fontWeight: "bold",
            fontSize: "16px"
          }}
        >
          {mensajeObligatorio}
        </p>
      )}
      {mensajeExito ? (
        <NpsMensajeExito mensaje={mensajeExito} onClose={onClose} />
      ) : (
        <Form form={form} layout='vertical' onFinish={handleOnFinish}>
          {preguntasProcesadas.map(
            ({ tipoPregunta, parametrosDiseno }, index) => (
              <div key={index}>
                {tipoPregunta === "rango" ? (
                  <TipoPreguntaRango
                    form={form}
                    pregunta={parametrosDiseno.pregunta}
                    subtitulo={parametrosDiseno.subtitulo}
                    rangoNps={parametrosDiseno.rango}
                    name={`pregunta_${index}`}
                  />
                ) : tipoPregunta === "abierta" ? (
                  <TipoPreguntaAbierta
                    pregunta={parametrosDiseno.pregunta}
                    name={`pregunta_${index}`}
                  />
                ) : null}
              </div>
            )
          )}
          <Row justify='center'>
            <Col>
              <Button
                type='primary'
                className='btn btn-green'
                htmlType='submit'
              >
                Enviar
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default NpsModal;
