import { Card, Divider, Spin, notification } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableReport from '../../../components/tableReport/tableReport';
import { listarReintegros } from '../../../services/proveedor/accountStatusService';
import {
  currencyFormat,
  sorterDate,
  sorterText,
} from '../../../utilities/textTools';
import './refunds.scss';
import RefundsFilterForm from './refundsFilterForm/refundsFilterForm';

const initialState = {
  form: {
    feDesdeRecaudo: '',
    feHastaRecaudo: '',
    estadoReintegro: '',
  },
  loading: false,
  resultado: [],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
};

class Refunds extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
    this.columnas = [
      {
        title: 'NÚMERO FACTURA',
        dataIndex: 'cdFactura',
        sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
        showSorterTooltip: false,
      },
      {
        title: 'NIT PROVEEDOR',
        dataIndex: 'proveedorId',
        sorter: (a, b) => sorterText(a.proveedorId, b.proveedorId),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR REINTEGRO',
        dataIndex: 'vrReintegro',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.vrReintegro - b.vrReintegro,
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE RECAUDO',
        dataIndex: 'feRecaudo',
        sorter: (a, b) => sorterDate(a.feRecaudo, b.feRecaudo),
        showSorterTooltip: false,
      },
      {
        title: 'OBSERVACIÓN RECAUDO',
        dataIndex: 'observacion',
        sorter: (a, b) => sorterText(a.observacion, b.observacion),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        dataIndex: 'estadoReintegro',
        sorter: (a, b) => sorterText(a.estadoReintegro, b.estadoReintegro),
        showSorterTooltip: false,
      },
    ];
  }

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        feDesdeRecaudo: value.fechaRango[0],
        feHastaRecaudo: value.fechaRango[1],
        estadoReintegro: value.estadoReintegro,
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  searchData = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        resultado: [],
        loading: true,
      });
      try {
        const response = await listarReintegros(
          this.props.authToken,
          this.props.company,
          this.getFiltro()
        );
        this.setState({
          ...this.state,
          resultado: response.data,
          total: response.metadata.count,
          loading: false,
        });
        if (response.data.length === 0) {
          notification.success({
            message: 'Sin resultados',
            description: 'No se encontraron reintegros para los filtros descritos',
            duration: 4,
          });
        }
      } catch {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  validateForm = () => {
    const fechaDesde = this.state.form.feDesdeRecaudo;
    const fechaHasta = this.state.form.feHastaRecaudo;

    if (
      fechaDesde === '' ||
      fechaDesde === null ||
      fechaHasta === '' ||
      fechaHasta === null
    ) {
      notification.error({
        message: 'Validacion de Campos',
        description: 'los campos fecha inicial y fecha final es obligatorio',
        duration: 4,
      });
      return false;
    }

    return true;
  };

  componentDidMount() {
    this.props.menuHandler('17');
  }

  componentDidUpdate(props, state) {
    if (this.props.company.company !== props.company.company) {
      this.setState({
        ...initialState,
      });
    }
  }

  render() {
    const { loading, currentPage, limit, total, resultado } = this.state;

    return (
      <div className='support-style'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <div className='align-center'>
            <div className='reports-container'>
              <Spin tip='Cargando...' spinning={loading}>
                <RefundsFilterForm
                  companyId={this.props.company}
                  handleSubmit={this.handleSubmit}
                />
                <div className='table-container'>
                  <Divider />
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    columns={this.columnas}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </div>
              </Spin>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.billing.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Refunds);
