import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Button, Select, Input, Modal } from 'antd';
import { requiredValueMessage } from '../../../../../../utilities/admin_validation_messages';

const rules = {
  cdEstado: [{ required: true, message: requiredValueMessage() }],
};

export const ModalEditarEstado = ({ onFinish, record, visible, onCancel }) => {
  const [cdEstado, setcdEstado] = useState('');
  const [form] = Form.useForm();
  const [dsObservacion, setdsObservacion] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (record) {
      setIsDisabled(record.cdEstado !== 'E');
      if (record.cdEstado !== 'E') {
        setcdEstado(record.cdEstado);
        form.setFieldsValue({
          cdEstado: record.cdEstado != null ? record.cdEstado : null,
          dsObservacion: record.dsObservacion != null ? record.dsObservacion : null,
        });
      } else {
        setcdEstado('');
        form.setFieldsValue({
          cdEstado: '',
          dsObservacion: '',
        });
      }
    }
  }, [form, record, visible]);

  const handleFinish = () => {
    const formData = { cdEstado, record };
    if (cdEstado === 'I') {
      formData.dsObservacion = dsObservacion;
    }
    onFinish(formData);
  };

  return (
    <div>
      <Modal
        title='Cambiar estado'
        visible={visible}
        onCancel={onCancel}
        footer={null}
        width={600}
      >
        <Form
          name='content_form'
          layout='vertical'
          onFinish={handleFinish}
          form={form}
        >
          <Row gutter={[16, 32]}>
            <Col span={12}>
              <Form.Item
                name='cdEstado'
                label='Seleccione el estado'
                rules={rules['cdEstado']}
              >
                <Select
                  placeholder='Seleccione'
                  allowClear
                  onChange={(value) => setcdEstado(value)}
                  disabled={isDisabled}
                >
                  <Select.Option value='S'>Sospechosa</Select.Option>
                  <Select.Option value='I'>Inusual</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {cdEstado === 'I' && (
            <Row gutter={16}>
              <Col flex='auto'>
                <Form.Item
                  name='dsObservacion'
                  label='Descripción: '
                  rules={[{ required: true }]}
                >
                  <Input.TextArea
                    value={dsObservacion}
                    onChange={(e) => setdsObservacion(e.target.value)}
                    resiz
                    disabled={isDisabled}
                    style={{resize: 'none'}}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={16} justify='center'>
            <Col span={6}>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
                disabled={isDisabled}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
